import { EventEmitter, Injectable } from '@angular/core';


@Injectable()
export class SvrEventService {

  // public onNavClickEvent: EventEmitter<number> = new EventEmitter<number>();
  // public onRefreshData: EventEmitter<any> = new EventEmitter<any>();
  // public onRefreshDataCompleted: EventEmitter<any> = new EventEmitter<any>();
  // public onRegisterFinished: EventEmitter<any> = new EventEmitter<any>();
  // public onChooseProduct: EventEmitter<any[]> = new EventEmitter<any[]>();
  // public onChooseSupplier: EventEmitter<any> = new EventEmitter<any>();
  // public onRefreshBaseCur: EventEmitter<any> = new EventEmitter<any>();
  // /*public onRefreshFTPPath: EventEmitter<any> = new EventEmitter<any>();*/
  // /*public onRefreshInitData: EventEmitter<any> = new EventEmitter<any>();*/
  // public onRefreshUserImage: EventEmitter<any> = new EventEmitter<any>();
   public onRefreshDataRouteReuse: EventEmitter<any> = new EventEmitter<any>();


  public onCurrentPageClose:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }


}
