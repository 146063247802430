import { Injectable } from '@angular/core';

@Injectable()
export class InterfaceService {
  toRotate: any;
  el: any;
  loopNum: number;
  period: number;
  txt: string = '';
  isDeleting: boolean;
  constructor() {}

  public typeWriting(el: any, toRotate: any, period: any) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.typeWritingTick();
    this.isDeleting = false;
  }
  public typeWritingTick() {
    const i = this.loopNum % this.toRotate.length;
    const fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    const that = this;
    let delta = 120 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 220;
    }
    setTimeout(() => {
      that.typeWritingTick();
    }, delta);
  }
}
