import { Component, Input, Output, EventEmitter } from '@angular/core';




@Component({
  selector: 'SplitButtonEdit',
  templateUrl: './ctl-import-export-button.component.html',
  styleUrls: ['./ctl-import-export-button.component.scss']
})

export class CtlImportExportButtonComponent {

  @Output() public onLeftButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRightButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() public buttonRightIcon = 'assets/icons/export.svg';
  @Input() public buttonLeftIcon = 'assets/icons/import.svg';

  constructor() {


  }

}
