export class LCPartnerWrap{
  lastDate: Date;
  data: LCPartner[] = [];
}
export class LCPartner {
  clientuserid: number;
  clientid: string;
  clientusercode: string;
  compname: string;
  fname: string;
  lname: string;
  email: string;
  gender: string;
  country: string;
  contact: string;
  address: string;
  phone: string;
  active: boolean;
  addtype: string;
  password: string;
  subdomain: string;
  username: string;
  status: number;
  productid: number;
  businessCategory: number;
  businessOutlet: number;
  verifycode: string;
  hostdomain:string;
  constructor(active?: boolean) {
    this.active = active ?? true;
    this.status = 0
    this.clientusercode = ""
    this.compname = ""
    this.subdomain = ""
    this.fname = ""
    this.lname = ""
    this.phone = ""
    this.address = ""
    this.email = ""
    this.verifycode = "";
    this.hostdomain = "";
  }
}

export class LCRegisterAccount {
  clientuserid: number;
  username: string
  password: string
  cfpassword: string
  subdomain: string
  verifycode: string

  constructor() {
    this.username = '';
    this.password = '';
    this.cfpassword = '';
  }
}

export class LCParamStatus {
  code: string
  productid: number
  domain: string
  compName: string
  email: string
  isCreateNew: boolean
  isVerifyAcc: boolean
  domainExist: boolean
  companyExist: boolean
  emailExist: boolean
  isExpirelink: boolean
  isactivated :boolean
  hostdomain:string
  constructor() {
    this.hostdomain = null
    this.code = null;
    this.domain = null
    this.compName = null
    this.email = null
    this.isCreateNew = false
    this.isVerifyAcc = false
    this.domainExist = false
    this.companyExist = false
    this.emailExist = false
    this.isExpirelink = false;
  }
}
export class Email {
  custid:number;
  ToMail: string
  username:string
  password:string
  subdomain: string
  verifycode: string

  constructor() {
    this.ToMail = "";
    this.subdomain = "HE";
    this.verifycode = "HE";

    this.username = "HE";
    this.password = "HE";


  }

}
