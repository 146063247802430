import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialExtensionsModule} from '@ng-matero/extensions';
import {MaterialModule} from '../material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgProgressModule} from 'ngx-progressbar';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {ToastrModule} from 'ngx-toastr';
import {NgxPopperModule} from 'ngx-popper';

import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {ErrorCodeComponent} from './components/error-code/error-code.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {IconPickerModule} from 'ngx-icon-picker';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ButtonsModule, ModalModule} from 'angular-bootstrap-md';
import {MegaMenuModule} from 'primeng/megamenu';
import {CtlMenuBarComponent} from '../theme/front-layout/menubar/menubar.component';

import {AngularSvgIconModule} from 'angular-svg-icon';
import {CtlFeedItemsComponent} from '../projects/website-app/component/feeditem/feeditems.component';
import {CtlFeedSliderComponent} from '../projects/website-app/component/feedslider/feedslider.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {CtlFeedBlogComponent} from '../projects/website-app/component/feedblog/feedblog.component';
import {CtlCollectionComponent} from '../projects/website-app/component/collection/collection.component';
import {NgxImageGalleryModule} from 'ngx-image-gallery';
import {CtlNavBarComponent} from '../theme/front-layout/navbar/navbar.component';
import {FooterComponent} from '../theme/front-layout/footer/footer.component';
import {CtlTextEditComponent} from '../basecontrol/ctl-text-edit/ctl-text-edit.component';
import {QuillModule} from 'ngx-quill';

import {CtlDropDownComponent} from '../basecontrol/ctl-dropdown-edit/ctl-dropdown-edit.component';
import {LimitToPipe} from '../pipes/limit_to_pipe';
import {ArrayFilterPipe} from '../pipes/fillter_by_pipe';
import {CtlButtonComponent} from '../basecontrol/ctl-button/ctl-button.component';
import {CtlFeedContentComponent} from '../projects/website-app/component/feed-content/feed-content.component';
import {PpNavigationPathPipe} from '../pipes/Navigation/pp-navigation-path.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {CtlDialogComponent} from '../basecontrol/ctl-dialog/ctl-dialog.component';
import {FrmMessageBoxComponent} from '../projects/website-app/console/frm-message-box/frm-message-box.component';
import {ImageDirective} from '@core/services/global.service';
import {SvrMessageBoxService} from '@core/services/svr-message-box.service';
import {CtlAddNewButtonComponent} from '../basecontrol/ctl-add-new-button/ctl-add-new-button.component';
import {CtlButtonPopupComponent} from '../basecontrol/ctl-buttonpopup-edit/ctl-buttonpopup-edit.component';
import {CtlCheckBoxEditComponent} from '../basecontrol/ctl-checkbox-edit/ctl-checkbox-edit.component';

import {CtlDropPopupComponent} from '../basecontrol/ctl-droppopup-edit/ctl-droppopup-edit.component';
import {CtlExportButtonEditComponent} from '../basecontrol/ctl-export-button/ctl-export-button.component';
import {CtlFindBaseComponent} from '../basecontrol/ctl-find-base/ctl-find-base.component';
import {CtlImportButtonEditComponent} from '../basecontrol/ctl-import-button/ctl-import-button.component';
import {
  CtlImportExportButtonComponent
} from '../basecontrol/ctl-import-export-button/ctl-import-export-button.component';
import {CtlMenuButtonComponent} from '../basecontrol/ctl-menu-button/ctl-menu-button.component';
import {CtlToggleEditComponent} from '../basecontrol/ctl-toogle-edit/ctl-toggle-edit.component';
import {CtlPictureBoxComponent} from '../basecontrol/ctl-picture-box/ctl-picture-box.component';
import {CtlPreviewButtonEditComponent} from '../basecontrol/ctl-preview-button/ctl-preview-button.component';
import {CtlRadioEditComponent} from '../basecontrol/ctl-radio-edit/ctl-radio-edit.component';
import {CtlSelectChipsComponent} from '../basecontrol/ctl-select-chipe-edit/ctl-select-chips-edit.component';
import {CtlTableSearchComponent} from '../basecontrol/ctl-table-search-edit/ctl-table-search.component';
import {CtlDataNotFoundComponent} from '../basecontrol/ctl_data-not-found/ctl_data-not-found.component';
import {CtlDataTableComponent} from '../basecontrol/ctl_datatable/ctl_data-table.component';
import {CtlExpansionPanelComponent} from '../basecontrol/ctl_expansionpanel/ctl_expansionpanel.component';
import {CtlFormBaseComponent} from '../basecontrol/ctl_form-base/ctl_form-base.component';
import {CtlFormPreviewComponent} from '../basecontrol/ctl_form-preview/ctl_form-preview.component';
import {CtlFormTabComponent} from '../basecontrol/ctl_form-tab/ctl_form-tab.component';
import {CalendarModule} from 'primeng/calendar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {
  CtlCreateNewXSButtonComponent
} from '../basecontrol/ctl-create-new-xs-button/ctl-create-new-xs-button.component';
import {
  ApprDownloadPopupComponent
} from '../projects/license-app/form/OL/Approval/appr-download-popup/appr-download-popup.component';
import {SearchingPagComponent} from './components/searching-pag/searching-pag.component';
import {ToCheckBoxComponent} from './components/to-check-box/to-check-box.component';
import {
  CtlFeedItemProductComponent
} from '../projects/website-app/component/feed-item-product/feed-item-product.component';
import {AutoCompleteModule, CheckboxModule} from 'primeng';
import {CtlInputReferenceComponent} from '../basecontrol/ctl-input-reference/ctl-input-reference.component';
import {
  FrmDialogLicenseComponent
} from "../projects/license-app/form/CM/Customer/frm-dialog-license/frm-dialog-license.component";
import {
  FrmExtendLiceseComponent
} from "../projects/license-app/form/CM/Customer/frm-extend-licese/frm-extend-licese.component";
import {
  FrmUpgradePackageComponent
} from "../projects/license-app/form/CM/Customer/frm-upgrade-package/frm-upgrade-package.component";
import {
  CtlDateTimePickerEditComponent
} from "../basecontrol/ctl-datetime-picker-edit/ctl-datetime-picker-edit.component";
import {PpDatetimeFormatPipe} from "../pipes/pp-datetime-format.pipe";


const THIRD_MODULES = [
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,
  NgSelectModule,
  FormlyMaterialModule,
  NgxPopperModule,
  NgxImageGalleryModule,
  TableModule,
  AngularSvgIconModule


];
const COMPONENTS = [
    BreadcrumbComponent,
    PageHeaderComponent,
    ErrorCodeComponent,
    CtlMenuBarComponent,
    CtlAddNewButtonComponent,
    CtlButtonPopupComponent,
    CtlCheckBoxEditComponent,
    CtlDropPopupComponent,
    CtlExportButtonEditComponent,
    CtlFindBaseComponent,
    CtlImportButtonEditComponent,
    CtlImportExportButtonComponent,
    CtlMenuButtonComponent,
    CtlPictureBoxComponent,
    CtlPreviewButtonEditComponent,
    CtlRadioEditComponent,
    CtlSelectChipsComponent,
    CtlTableSearchComponent,
    CtlToggleEditComponent,
    CtlDataNotFoundComponent,
    CtlDataTableComponent,
    CtlExpansionPanelComponent,
    CtlFormBaseComponent,
    CtlFormPreviewComponent,
    CtlFormTabComponent,
    CtlCreateNewXSButtonComponent,
    CtlInputReferenceComponent,
    CtlDateTimePickerEditComponent,


  ]
;
const COMPONENTS_DYNAMIC = [
  CtlDialogComponent,
  FrmMessageBoxComponent,
  ApprDownloadPopupComponent,
  CtlInputReferenceComponent,
  FrmExtendLiceseComponent,
  FrmDialogLicenseComponent,
  FrmUpgradePackageComponent
];
const DIRECTIVES = [ImageDirective];
const PIPES = [
  ArrayFilterPipe,
  LimitToPipe,
  PpDatetimeFormatPipe

];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES, CtlFeedItemsComponent, CtlFeedSliderComponent, CtlFeedBlogComponent, CtlCollectionComponent, CtlNavBarComponent, FooterComponent, CtlTextEditComponent, CtlDropDownComponent, CtlButtonComponent, CtlFeedContentComponent, PpNavigationPathPipe, SearchingPagComponent, ToCheckBoxComponent, CtlFeedItemProductComponent],
  imports: [
    // CtlMapComponent,
    MegaMenuModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ImageCropperModule,
    IconPickerModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    // MDBBootstrapModule.forRoot(),
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),


    ...THIRD_MODULES,
    AngularSvgIconModule,
    SwiperModule,
    CarouselModule,
    NgxImageGalleryModule,
    QuillModule,
    TranslateModule,
    CalendarModule,
    SplitButtonModule,
    TabViewModule,
    AutoCompleteModule,
    CheckboxModule


  ],
  exports: [
    // CtlMapComponent,
    IconPickerModule,
    ImageCropperModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FormlyModule,
    ToastrModule,
    NgxDatatableModule,
    MegaMenuModule,
    // MDBBootstrapModule,
    ModalModule,
    ButtonsModule,


    ...THIRD_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,

    CtlFeedItemsComponent,
    CtlFeedSliderComponent,
    CtlFeedBlogComponent,
    CtlCollectionComponent,
    CtlFeedContentComponent,
    CtlNavBarComponent,
    FooterComponent,
    CtlTextEditComponent,
    CtlDropDownComponent,
    CtlButtonComponent,
    PpNavigationPathPipe,
    CtlDialogComponent,
    CtlFeedItemProductComponent,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
  providers: [SvrMessageBoxService]
})
export class SharedModule {

}
