import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as TOSoftwareStore from './Store/reducer';
import { SvrEventService } from "./core/services/svr-events.service";
import {svrSMcompany} from "./projects/license-app/Controller/svrSMcompany";
import {SvrDataServiceRegister} from "./projects/register-app/Class/svr-data.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'togroup';
  constructor(public store: Store<TOSoftwareStore.TOSoftwareState>,
              private svrCompany:svrSMcompany,
              public svrDataReg: SvrDataServiceRegister,
              public svrEvent: SvrEventService) {

    // this.store.dispatch(new LoadInitial());
  }
  // private initFacebookService(): void {
  //   const initParams: InitParams = { xfbml: true, version: 'v6.0' };
  //   this.fb.init(initParams);
  // }
  async ngOnInit() {
    window.addEventListener('keyup', disableF5);
    window.addEventListener('keydown', disableF5);

    function disableF5(e) {
      if ((e.which || e.keyCode) == 116) {
        e.preventDefault();
      } else if (e.ctrlKey && (e.keyCode == 80 || e.keyCode == 16 || e.keyCode == 112)) {
        e.preventDefault();
      }
    }
   // this.initFacebookService();
  }

}
