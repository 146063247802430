import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SvrMessageBoxService} from "@core/services/svr-message-box.service";
import {FrmSoftwareListComponent} from "../../../IC/Software/frm-software-list/frm-software-list.component";
import {FrmExtendLiceseComponent} from "../frm-extend-licese/frm-extend-licese.component";
import {clsNan} from "../../../../Class/svr-operation.service";
import {FrmUpgradePackageComponent} from "../frm-upgrade-package/frm-upgrade-package.component";

@Component({
  selector: 'app-frm-dialog-extend-lience',
  templateUrl: './frm-dialog-license.component.html',
  styleUrls: ['./frm-dialog-license.component.css']
})
export class FrmDialogLicenseComponent implements OnInit {
  @ViewChild(FrmExtendLiceseComponent) extenlicese;
  @ViewChild(FrmUpgradePackageComponent) upgradelicese;
  optiontype: string = '';
  static width = '80%';
  static height = '70%';

  constructor(
    public diagRef: MatDialogRef<FrmDialogLicenseComponent>,
    @Inject(MAT_DIALOG_DATA) public diagData: any,
    private svrMessage: SvrMessageBoxService,) {
  }

  ngOnInit(): void {

    this.optiontype = this.diagData.type;

  }

  onPositiveClick() {
    let result = null;
    if (!clsNan.isNullOrUndefined(this.extenlicese)) {
      result = this.extenlicese.objFormData.objLicense;
      if (clsNan.isNullOrUndefined(result.month)) {
        this.svrMessage.ShowAlertMessage('Please check your data');
        return;
      } else if (!clsNan.isNullOrUndefined(result.month) && result.month == 0) {
        this.svrMessage.ShowAlertMessage('Please input months first');
        return;
      }
    } else {
      result = this.upgradelicese.objFormData.objLicense;
    }
    this.diagRef.close(result);
  }

  onAddClick() {
  }

  onNegativeClick() {
    this.diagRef.close();
  }

}
