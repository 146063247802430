import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, group, animate } from '@angular/animations';




@Component({
  selector: 'ExpansionPanel',
  templateUrl: './ctl_expansionpanel.component.html',
  styleUrls: ['./ctl_expansionpanel.component.scss'],
  animations:[
    trigger('slideInOut', [
      state('in', style({
          'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
      })),
      state('out', style({
          'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('in => out', [group([
          animate('400ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '0px'
          })),
          animate('700ms ease-in-out', style({
              'visibility': 'hidden'
          }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '500px'
          })),
          animate('800ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),
  ]
})

export class CtlExpansionPanelComponent {
  @Input() exptitle: string;
  animationState = 'out';
  _isexpansion: boolean;
  _icon: string = 'keyboard_arrow_down';
  constructor() {}
  onShowPanel() {

    this.animationState = this.animationState === 'out' ? 'in' : 'out';
    if (this._isexpansion == false || this._isexpansion == undefined) {
      this._isexpansion = true;
      this._icon = "keyboard_arrow_up";
    } else {
      this._isexpansion = false;
      this._icon = "keyboard_arrow_down";
    }
  }
}
