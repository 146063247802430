import {Injectable} from '@angular/core';
import * as _ from 'lodash';

export interface Tag {
  color: string; // Background Color
  value: string;
}

export interface ChildrenItem {
  state: string;
  name: string;
  type: 'link' | 'sub' | 'extLink' | 'extTabLink';
  children?: ChildrenItem[];
  icon?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: 'link' | 'sub' | 'extLink' | 'extTabLink';
  icon: string;
  label?: Tag;
  badge?: Tag;
  children?: ChildrenItem[];
}

export interface INav {
  name: string;
  state: string;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  public menu: Menu[] = [];

  getAll(): Menu[] {
    return this.menu;
  }

  set(menu: Menu[]): Menu[] {
    this.menu = [...menu];
    return this.menu;
  }

  add(menu: Menu) {
    this.menu.push(menu);
  }

  getMenuItemName(stateArr: string[]): INav {
    return this.getMenuLevel(stateArr)[stateArr.length - 1];
  }

  // TODO:
  getMenuLevel(stateArr: string[]): INav[] {
    const tmpArr: INav[] = [];
    this.menu.map(item => {

      if (item.state === stateArr[0]) {
        tmpArr.push({name: item.name, state: item.state});
        // Level1
        if (item.children && item.children.length) {

          item.children.map((itemlvl1) => {

            if (_.isArray(itemlvl1)) {

              _.map(itemlvl1, (itemlv) => {

                if (stateArr[1] && itemlv.state === stateArr[1]) {
                  tmpArr.push({name: itemlv.name, state: itemlv.state});
                }
              });
            } else {
              if (stateArr[1] && itemlvl1.state === stateArr[1]) {
                tmpArr.push({name: itemlvl1.name, state: itemlvl1.state});
                // Level2
                if (itemlvl1.children && itemlvl1.children.length) {
                  itemlvl1.children.forEach(itemlvl2 => {
                    if (stateArr[2] && itemlvl2.state === stateArr[2]) {
                      tmpArr.push({name: itemlvl2.name, state: itemlvl2.state});
                    }
                  });
                }
              } else if (stateArr[1]) {
                // Level2
                if (itemlvl1.children && itemlvl1.children.length) {
                  itemlvl1.children.map(itemlvl2 => {
                    if (itemlvl2.state === stateArr[1]) {
                      //tmpArr.push(itemlvl1.name, itemlvl2.name);
                    }
                  });
                }
              }
            }
          });
        }
      }
    });

    return tmpArr;
  }
}
