import { Menu } from "../models/clsModels";
import { ActionsNavMenu, NavMenu_Action_Type } from "../action/index";
export interface NavMenu extends Menu {}



export function navMenuReducer(
  state:Menu []= [],
  action: ActionsNavMenu
): NavMenu[] {
  switch (action.type) {
    case NavMenu_Action_Type.NavMenuInitial:
      return { ...state };
    case NavMenu_Action_Type.NavMenuAdd: {
      var objState = Object.values(state);
      const objAdd = action.payload;
      objState.push(objAdd);
      return { ...objState };
    }
    case NavMenu_Action_Type.NavMenuRemove: {
      const objRemove = action.payload;
      const objState = Object.values(state);
      state = objState.filter(n => n.id !== objRemove.id);
      return { ...state };
    }
    case NavMenu_Action_Type.NavMenuUpdate: {
      const objState = Object.values(state);
      const objUpdate = objState.filter(n => n.id !== action.payload.id)[0];
      if (objUpdate !== undefined && objUpdate !== null) {
        const index = objState.indexOf(objUpdate);
        objState[index] = action.payload;
      }
      return { ...objState };
    }
    case NavMenu_Action_Type.NavMenuInitSuccess:
      const obj = action.payload;
      state = obj;
      return { ...state };

    default:
      return state;
  }
}

export const getNavData = (state: Menu[]) => state;
