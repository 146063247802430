import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OperationService} from "@core";
import {LCRecord} from "../Model/clsGlobalData";
import {clsitemmaster} from "../Model/clsICModule";


@Injectable({
  providedIn: 'root',
})
export class svrProduct {

  public routeprefix = "license/ic_product";

  constructor(
    public http: HttpClient,
    public operation: OperationService,
  ) {

  }

  public async onAddUpateProdcut(objsoftware: clsitemmaster): Promise<number> {
    debugger
    const res = await this.http.post<number>(this.operation.accessPointUrl + this.routeprefix + "/addUpdate",
      objsoftware, {headers: this.operation.headers}
    ).toPromise();
    return res;
  }

  public async onGetTotalItem(total: LCRecord): Promise<number> {
    const res = await this.http.post<number>(this.operation.accessPointUrl + this.routeprefix + '/total', total, {headers: this.operation.headers}
    ).toPromise();
    return res ?? 0;
  }

  public async onGetTotalPro(ispack: boolean): Promise<clsitemmaster[]> {
    const res = await this.http.get<clsitemmaster[]>(this.operation.accessPointUrl + this.routeprefix + '/totalPro', {
      params: {
        "ispack": ispack.toString()
      }
    }).toPromise()
    return res;
  /*  let url = this.operation.accessPointUrl + this.routeprefix + '/totalPro';
    const res = await this.http.get<clsitemmaster[]>(url,
      { params:
          {
            "ispack": ispack
      }
      }
      ).toPromise();
    return res;*/
  }

  public async onGetRecordItem(record: LCRecord): Promise<clsitemmaster[]> {
    const res = await this.http.post<clsitemmaster[]>(this.operation.accessPointUrl + this.routeprefix + '/record',
      record, {headers: this.operation.headers}
    ).toPromise();

    return res;
  }

  public async onGetItemEdit(id: number): Promise<any> {
    const res = await this.http.get(this.operation.accessPointUrl + this.routeprefix + '/edit', {
      params: {
        "itemid": id.toString()
      }
    }).toPromise()
    return res;
  }

  public async onDeleteItem(id: number): Promise<number> {
    const res = await this.http.delete<number>(this.operation.accessPointUrl + this.routeprefix + '/delete', {
      params: {
        "id": id.toString()
      }
    }).toPromise();
    return res;
  }

  public async onDataDummy(): Promise<clsitemmaster> {
    return await this.http.get<clsitemmaster>("assets/data/LCsoftware.json").toPromise();
  }
}

