import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OperationService} from "@core";


@Injectable({
  providedIn: 'root'
})
export class SvrSMBustypeService {
  public routeprefix = "license/sm_business"
  constructor(private http: HttpClient,
              private operation: OperationService) {

  }
  async onGetBusinesstype(): Promise<any> {
    const url = await this.http
      .get(this.operation.accessPointUrl + this.routeprefix + "/GetBusinesstype")
      .toPromise()
    return url;
  }
  async onGetSystemType(): Promise<any> {
    const url = await this.http
      .get(this.operation.accessPointUrl + this.routeprefix + "/GetSystemType")
      .toPromise()
    return url;
  }

}



