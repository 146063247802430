import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Config} from '../../types';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NavService} from "../../../shared/services/nav.service";
import * as _ from "lodash";
import {InitializeService, OperationData, OperationService} from "@core";
import {IMenus} from "../../../Store/models/clsModels";

export interface NavItem {
  name: string;
  disabled?: boolean;
  icon: string;
  svg?: boolean;
  route?: string;
  children?: NavItem[];
}

@Component({
  selector: 'app-ctl-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})

export class CtlNavBarComponent implements OnInit {
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: IMenus;
  @Input() depth: number;

  // nave
  config: Config;
  // signle open mode
  options: Config = {multi: false};

  Menus: any[] = [];
  menuId: string = '';

  constructor(public svrRouter: Router,
              public svrData: OperationData,
              public svrOpt: OperationService,
              public svrInit: InitializeService,
              public navService: NavService) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
    // this.svrOpt.GetHeaderMenu().subscribe(mainheader => {
    //   this.Menus = mainheader;
    //
    // });
  }

  ngOnInit() {

    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.menuroute && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.menuroute}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
    // this.config = this.mergeConfig(this.options);
  }

  onDropDownSelected(item: IMenus) {
    if (!item.children || !item.children.length) {
      this.svrRouter.navigate([item.menuroute]);
      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  onItemSelected(item: IMenus) {
    if (item.menuroute) {
      this.svrRouter.navigate([item.menuroute]);
      this.navService.closeNav();
    } else {
      this.expanded = !this.expanded;
    }
  }

  onRouteCheck(id) {

    this.menuId = id;

  }

  mergeConfig(options: Config) {
    // 기본 옵션
    const config = {
      // selector: '#accordion',
      multi: true
    };

    return {...config, ...options};
  }

  toggle(index: number) {
    // if (!this.config.multi) {
    //   this.MainHeader.filter(
    //     (menu, i) => i !== index && menu.active
    //   ).forEach(menu => menu.active = !menu.active);
    // }
    // this.MainHeader[index].active = !this.MainHeader[index].active;
  }

  togglesub(index: number) {

    // if (!this.config.multi) {
    //
    //   this.MainHeader[index].submenu.filter(
    //     (menu, i) => i !== index && !menu.active
    //   ).forEach(menu =>
    //
    //     menu.active = menu.active);
    // }
    // this.MainHeader[index].submenu[index].active = !this.MainHeader[index].submenu[index].active;
  }

  onRouter(e: any) {
    this.svrInit.getHeaders(_.isString(e) ? e : e.menuroute, 0);
    this.svrInit.getFeedByRoute(_.isString(e) ? e : e.menuroute, 0);
    this.svrRouter.navigate([e.menuroute]);
  }


}

