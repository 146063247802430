import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
/*import {forEach, multiply} from "lodash";
import {clsGetmodule, clsGetmodulePackage, clspricing} from "../../Model/clsPricing";
import {clsbuynow, clsBuynowGetmodule, clsBuynowGetmodulePackage} from "../../Model/clsBuynow";
import {svrPricing} from "../../Controller/svrPricing";*/
import { TooltipModule } from "primeng/tooltip";
import {clsMainMoudle, localSTKey, saveAddon} from "../pricing-layout/pricing.component";
import {HttpClient} from "@angular/common/http";
import {clsNan, OperationService} from "@core";
import {svrPricing} from "../../Controller/svrPricing";
import {clsGetmodule, clsGetmodulePackage, clspricing} from "../../Model/clsPricing";
import {
  clsAddModuleAddon,
  clsaddonmaster,
  clsAddonModuleGroup,
  clsaddontran
} from "../../Model/clsBuynow";
import {svrStoredataService} from "@core/services/svr-storedata.service";
import {clsBaseSetupComponent} from "@core/services/clsBaseSetupComponent";
import {SvrEventService} from "@core/services/svr-events.service";
import {strictEqual} from "assert";
import {svrDataServiceWebsite} from "../../Class/svr-Data-ServiceWebsite.service";

@Component({
  selector: 'app-frm-buy-now',
  templateUrl: './frm-buy-now.component.html',
  styleUrls: ['./frm-buy-now.component.scss']
})
export class FrmBuyNowComponent extends clsBaseSetupComponent implements OnInit {

  lstModule: clsMainMoudle[] = [];
  unfold = false;
  tmpTitle: clspricing = new clspricing();
  getAddOnItemName = [];
  getAddOnItemPrice = [];
  packageid: number;
  title: string = '';
  monthly = 'TabKidMonthClick';
  annually = 'TabKidAnnuall';
  objFormData: {
    objAddonModulegrp: clsAddonModuleGroup,
    lstAddModuleAddon: clsAddModuleAddon[],
  } = {
    objAddonModulegrp: new clsAddonModuleGroup(),
    lstAddModuleAddon: [],
  };
  duration: string = "Monthly";

  constructor(
    private svrRoute: Router,
    public route: ActivatedRoute,
    public httpclient?: HttpClient,
    public svrOpr?: OperationService,
    public svrPrice?: svrPricing,
    public svrEvent?: SvrEventService,
    public svrStoreData?: svrStoredataService,
    public svrData?: svrDataServiceWebsite
  ) {
    super(svrStoreData, svrEvent, "FrmBuyNowComponent", svrRoute, route);
    const _packageid = this.route.snapshot.paramMap.get('packageid');
    this.packageid = isNaN(parseInt(_packageid)) ? 0 : parseInt(_packageid);
    this.onGetJson();

  }

  async onGetJson() {
    let res2 = (await this.httpclient.get('assets/data/package_mudule.json?_t=' + Date.now()).toPromise());
    let lstMainsub = res2["moduleList"];
    this.lstModule = lstMainsub;
  }

  package_price: number;

  pricinglist: IPricingList = {
    tables: [],
    modulelist: [],
    modulePackage: [],
    classNames: ['pricing-card basic', 'pricing-card echo', 'pricing-card pro'],
  };
  packageNameIndex: number;
  localprice: number;
  Annual6 = 0;
  multiplyPrice = 1;
  addonlist: getAddonPackage[] = [];
  expan_style: string;
  add = '';
  GrandTotal: number = 0;
  Discount: number = 0;
  TotalPrice: number;
  group_by_sort = [];
  key = new localSTKey();
  saveAddOn = [];
  SwitchStyle: any;
  pckSubModule: any[] = [];
  isGetaddon: number

  async ngOnInit() {
    let key = new localSTKey();
    let isClear = localStorage.getItem(this.key.isClearAddon);
    this.isGetaddon = Number(localStorage.getItem(key.seletedPackage));
    let localpr = localStorage.getItem(key.SalePrice);
    let disc = localStorage.getItem(key.pricing_discount);
    let submodule = JSON.parse(localStorage.getItem(key.getSubModule));
    this.pckSubModule = submodule;
    this.Discount = Number(disc);
    this.localprice = Number(localpr);
    this.multiplyPrice = 1;
    let getPackage = await this.svrPrice.GetPricingPackage();
    this.objFormData.objAddonModulegrp = await this.svrPrice.onGetModuleGrp((this.isGetaddon + 1));
    this.pricinglist.tables = [...this.pricinglist.tables, ...getPackage];
    this.onSetDedaultDuration()
    this.onGetSelectPackage(this.isGetaddon);
    this.doSaveSnapshot();
    this.onRestoreTotal();
    this.onClearAddon(isClear)
    this.onProtectClearData();

  }

  onDeleteInvisible(getter) {
    getter = [];
  }

  loading: boolean = true

  onLoad() {
    this.loading = false;
  }

  clrAddon = new clsaddontran();

  async onClearAddon(isclr) {
    if (isclr == "1") {
      this.objFormData.objAddonModulegrp.lsttran.forEach((a) => a.isstyleOutLine = false);
      this.objFormData.lstAddModuleAddon = [];
      this.onSetDedaultDuration(1);
      localStorage.removeItem(this.key.isClearAddon);
    }
  }

  async onProtectClearData() {
    let resFilter = await this.svrStoredata.getKey(this.classkey);

    if (resFilter != null) {
      this.onRestoreSaveData(resFilter);
    } else {
      this.svrStoredata.setKey(this.classkey, this.objFormData);
    }
  }

  onGetSelectPackage(id) {
    if (id == 0) {
      this.onDisplayPckName(1);

    } else if (id == 1) {
      this.onDisplayPckName(2);
    } else if (id == 2) {
      this.onDisplayPckName(3);

    }
    this.onSetDedaultDuration();
  }

  onDisplayPckName(id) {
    this.pricinglist.tables.forEach(el => {
      if (el.packageid == id) {
        this.tmpTitle = el;
      }
    });
  }

  on_controll_collape(da) {
    if (da == false) {
      this.unfold = true;
      this.expan_style = 'style-buy-now';
    } else {
      this.unfold = false;
      this.expan_style = '';
    }
  }

  onHome() {
    this.svrRoute.navigate(['home']);
  }

  // public onTotalPrice(data: clsaddontran, modulegpdesc?: string, modulegrpid?: number) {
  //   //this.hind_message = true;
  //   let _trandInex = this.objFormData.objAddonModulegrp.lsttran.indexOf(data);
  //   this.onClearRestoreTotal();
  //   let objtran = new clsaddontran();
  //   objtran.addondesc = data.addondesc;
  //   objtran.addonmasid = modulegrpid;
  //   objtran.price = data.price
  //   objtran.addonmasdesc = modulegpdesc;
  //   objtran.tranid = objtran.tranid
  //   if (clsNan.isNullOrUndefined(data.isstyleOutLine) || data.isstyleOutLine == false) {
  //     this.objFormData.objAddonModulegrp.lsttran[_trandInex].isstyleOutLine = true;
  //     objtran.isstyleOutLine = true;
  //     this.onAddOnPackage(objtran);
  //     this.onSaveAddOn(objtran);
  //     this.group_by_sort.push(0);
  //   } else {
  //     data.isstyleOutLine = this.objFormData.objAddonModulegrp.lsttran[_trandInex].isstyleOutLine;
  //     this.objFormData.lstAddModuleAddon.forEach((e, i) => {
  //       return e.lstaddontran.filter((el, ind) => {
  //         if (el.tranid == objtran.tranid) {
  //           let arr = e.lstaddontran.splice(ind, 1);
  //           if (e.lstaddontran.length == 0)
  //             e.objmaster.isremove = true;
  //           return arr;
  //         }
  //       })
  //     });
  //     this.objFormData.lstAddModuleAddon = this.objFormData.lstAddModuleAddon.filter(el => el.objmaster.isremove != true);
  //     this.objFormData.objAddonModulegrp.lsttran[_trandInex].isstyleOutLine = false;
  //
  //   }
  //
  //   this.onSetDedaultDuration();
  //   if (this.SwitchStyle == 'annually') {
  //     this.onSetDedaultDuration(12);
  //
  //   }
  // }


  // public onTotalPrice(modulegpdesc?: string, name?: any, price?: any, isstyle?: boolean, ind?: any, modulegrpid?: number) {
  //   //this.hind_message = true;
  //   this.onClearRestoreTotal();
  //   let objtran = new clsaddontran();
  //   objtran.addondesc = this.objFormData.objAddonModulegrp.lsttran[ind].addondesc;
  //   objtran.addonmasid = modulegrpid;
  //   objtran.price = this.objFormData.objAddonModulegrp.lsttran[ind].price
  //   objtran.addonmasdesc = modulegpdesc;
  //   objtran.tranid = this.objFormData.objAddonModulegrp.lsttran[ind].tranid
  //   if (clsNan.isNullOrUndefined(isstyle) || this.objFormData.objAddonModulegrp.lsttran[ind].isstyleOutLine == false) {
  //     this.objFormData.objAddonModulegrp.lsttran[ind].isstyleOutLine = true;
  //     objtran.isstyleOutLine = true;
  //     this.onAddOnPackage(objtran);
  //     this.onSaveAddOn(objtran);
  //     this.group_by_sort.push(0);
  //   } else {
  //     objtran.isstyleOutLine = this.objFormData.objAddonModulegrp.lsttran[ind].isstyleOutLine;
  //     this.objFormData.lstAddModuleAddon.forEach((e, i) => {
  //       return e.lstaddontran.filter((el, ind) => {
  //         if (el.tranid == objtran.tranid) {
  //           let arr = e.lstaddontran.splice(ind, 1);
  //           if (e.lstaddontran.length == 0)
  //             e.objmaster.isremove = true;
  //           return arr;
  //         }
  //       })
  //     });
  //     this.objFormData.lstAddModuleAddon = this.objFormData.lstAddModuleAddon.filter(el => el.objmaster.isremove != true);
  //     this.objFormData.objAddonModulegrp.lsttran[ind].isstyleOutLine = false;
  //   }
  //   this.onSetDedaultDuration();
  //   if (this.SwitchStyle == 'annually') {
  //     this.onSetDedaultDuration(12);
  //   }
  // }

  public onTotalPrice(addonmasdesc?: any, addonname?: any, modulegrpid?: number, itemindex?: number, tranid?: any) {
    this.onClearRestoreTotal();
    let objtran = new clsaddontran();
    objtran.addonmasdesc = addonmasdesc;

    let nameaddon = this.objFormData.objAddonModulegrp.lsttran.filter(style => style.addondesc == addonname);

    nameaddon.forEach(markstyle => {
      if (markstyle.isstyleOutLine == true) {
        markstyle.isstyleOutLine = false;
        this.objFormData.lstAddModuleAddon.forEach((e, i) => {
          return e.lstaddontran.filter((el, ind) => {
            if (el.tranid == tranid) {
              let arr = e.lstaddontran.splice(ind, 1);
              if (e.lstaddontran.length == 0)
                e.objmaster.isremove = true;
              return arr;
            }
          })
        });
      } else {
        markstyle.isstyleOutLine = true;
        objtran.addonmasid = markstyle.addonmasid;
        objtran.price = markstyle.price
        objtran.addondesc = markstyle.addondesc;
        objtran.tranid = markstyle.tranid
        this.onAddOnPackage(objtran);
        this.onSaveAddOn(objtran);
      }
    });
    this.objFormData.lstAddModuleAddon = this.objFormData.lstAddModuleAddon.filter(el => el.objmaster.isremove != true);

    this.onSetDedaultDuration();
    if (this.SwitchStyle == 'annually') {
      this.onSetDedaultDuration(12);
    }

  }


  onObjtranFilter(objMaster?: clsaddonmaster) {
    return this.objFormData.objAddonModulegrp.lsttran.filter(n => n.addonmasid == objMaster.addonmasid)
  }

  onAddOnPackage(objtran: clsaddontran) {
    let _addonModule: clsAddModuleAddon = new clsAddModuleAddon();
    _addonModule.objmaster.addonmasid = objtran.addonmasid;
    _addonModule.objmaster.addonmasdesc = objtran.addonmasdesc;
    _addonModule.lstaddontran.push(objtran);
    if (this.objFormData.lstAddModuleAddon.find(e => e.objmaster.addonmasid == objtran.addonmasid)) {
      this.objFormData.lstAddModuleAddon.forEach(e => {
        if (e.objmaster.addonmasid == objtran.addonmasid) {
          return e.lstaddontran.push(objtran);
        }
      })
    } else {
      this.objFormData.lstAddModuleAddon.push(_addonModule);
    }

  }

  onSaveAddOn(obj, remove?) {
    this.saveAddOn.push(obj);
  }

  CalPercentage(getStyle) {
    this.SwitchStyle = getStyle;
    let isMonthly = false;
    if (getStyle == 'monthly') {
      isMonthly = true;
      this.multiplyPrice = 1
    } else {
      this.multiplyPrice = 12;
    }
    this.onSetDedaultDuration(this.multiplyPrice);
    this.monthly = isMonthly ? 'TabKidMonthClick' : 'TabKidMonth';
    this.annually = isMonthly ? 'TabKidAnnuall' : 'TabKidAnnuallClick';
  }

  onSetDedaultDuration(monthly: number = 1) {
    if (monthly == 1) {
      this.duration = "Monthly"
    } else {
      this.duration = "Annually"
    }
    let _pricePerYear = this.getPrice(monthly);
    this.TotalPrice = (_pricePerYear + (this.localprice * monthly));
    this.GrandTotal = Number(this.TotalPrice) - ((Number(this.TotalPrice) * Number(this.Discount)) / 100);
  }

  // App Integration


  // getPrice(monthly: number = 1): number {
  //   let lstPrice: number[] = [];
  //   this.objFormData.lstAddModuleAddon.forEach(el => {
  //     el.lstaddontran.forEach(val => lstPrice.push(val.price * monthly))
  //   });
  //   let pricePerMonth = lstPrice.reduce((accumulator, current) => {
  //     return (accumulator + current);
  //   }, 0);
  //   return pricePerMonth;
  // }

  getPrice(monthly: number = 1): number {
    let lstPrice: number[] = [];
    this.objFormData.lstAddModuleAddon.forEach(el => {
      let tmp = 0;
      el.lstaddontran.forEach(val => {
          if (val.addonmasid != 3) {
            tmp += val.price * monthly
          } else {
            tmp += val.price;
          }
        }
      )
      lstPrice.push(tmp);
    });
    let pricePerMonth = lstPrice.reduce((accumulator, current) => {
      return (accumulator + current);
    }, 0);
    return pricePerMonth;
  }

  onRestoreTotal() {
    this.TotalPrice = Number(localStorage.getItem(this.key.buy_now_total))
    this.GrandTotal = Number(localStorage.getItem(this.key.buy_now_grandtotal))
    //this.onSetDedaultDuration()

  }

  onClearRestoreTotal() {
    localStorage.removeItem(this.key.buy_now_total)
    localStorage.removeItem(this.key.buy_now_grandtotal)
  }

  async onSubmit() {
    this.svrStoreData.setKey(this.svrData._Duration, this.duration);
    const lstaddon = this.objFormData.lstAddModuleAddon.map(e => e.lstaddontran);
    //this.svrStoredata.setKey(this.svrData._ADDON, lstaddon);
    this.svrStoredata.setKey(this.svrData._KEYADDON, lstaddon);
    this.svrStoredata.setKey(this.classkey, this.objFormData);
    this.svrOpr.NavigateRoute(this.route, 'request', this.packageid);

    if (this.SwitchStyle == 'annually') {
      await localStorage.setItem(this.key.buy_now_total, (this.TotalPrice / 12).toString())
      await localStorage.setItem(this.key.buy_now_grandtotal, (this.GrandTotal / 12).toString())
    } else {
      await localStorage.setItem(this.key.buy_now_total, (this.TotalPrice).toString())
      await localStorage.setItem(this.key.buy_now_grandtotal, (this.GrandTotal).toString())
    }


  }

  onCheckSnapshot(route: string): boolean {
    let objSnapshot = this.doGetSnapshot();
    let objCurrent = this.objFormData;
    try {
      strictEqual(objSnapshot.objMaster as clsAddonModuleGroup, objCurrent.objAddonModulegrp);
      return true;
    } catch (e) {
      return false;
    }
  }

  onRestoreSaveData(data: any) {
    if (!clsNan.isNullOrUndefined(data)) {
      this.objFormData = data;
    }
  }
}

export class getAddonPackage {
  addonmasid: number;
}

interface IPricingList {
  tables?: clspricing[];
  modulelist?: clsGetmodule[];
  modulePackage?: clsGetmodulePackage[];
  lstick?: {}[];
  classNames?: string[];
  stan?: string[];
  contlist?: {}[];
  onClick?: (type: string) => void;
}
