import { Component, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioEditItem {
  label?: string;
  value: any;
}

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtlRadioEditComponent),
  multi: true,
};

@Component({
  selector: 'RadioEdit',
  providers: [RADIO_VALUE_ACCESSOR],
  templateUrl: './ctl-radio-edit.component.html',
  styleUrls: ['./ctl-radio-edit.component.scss']
})

export class CtlRadioEditComponent implements ControlValueAccessor {
  @Input() items: Array<RadioEditItem>;
  @Input() radioGroup: boolean = true;
  @Input() hidden: boolean = false;
  @Input() disable: boolean = false;
  @Input() selectedValue: number = 1;
  @Input() name: any;
  @Output() onChecked: EventEmitter<any> = new EventEmitter<any>()


  private innerValue: string | number | boolean;
  get value(): string | number | boolean {
    return this.innerValue;
  }

  set value(v: string | number | boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.change(v);
    }
  }

  onChange: any;
  onTouched: any;

  writeValue(value: string | number | boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value: string | number | boolean) {
    this.innerValue = value;
    this.onChange(value);
    this.onTouched(value);
    this.onChecked.emit();
  }

}
