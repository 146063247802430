import { Component, Input, Output, EventEmitter } from '@angular/core';




@Component({
  selector: 'PreviewButtonEdit',
  templateUrl: './ctl-preview-button.component.html',
  styleUrls: ['./ctl-preview-button.component.scss']
})

export class CtlPreviewButtonEditComponent {

  @Input() label: string;
  @Input() class: any;
  @Input() style: any;
  @Output() public previewClick: EventEmitter<any> = new EventEmitter();

  onPreviewClick($e){
    this.previewClick.emit($e);

  }

}
