export class clsCustomerInfo {
  compname: string;
  fname: string;
  lname: string;
  phone: string;
  email: string;
  bustypeid:number;
  packageid:number;
  fullname: string;
  bussdes : string;
  pkdesc : string;
  pkprice : number;
  subtotal : number;
  totalprice : number;
  disc : number;
  gtotalprice : number;
  duration: string = "Monthly";
  lstAdd: clsAddon[] = [];
constructor() {
  this.compname = "";
  this.fname = "";
  this.lname = "";
  this.phone = "";
  this.email = "";
}
}
export class  clsAddon {
  compid: number;
  tranid: number;
  addonmid: number;
  addonid: number;
  addonmasdesc : string;
  addondesc : string;
  price : number;
}

export class clsSelectBussType{
  bussid: number;
  bussdesc : string;
  //description2 : string;
  //status: boolean;
}
export class clsSystemType{
  productid: number;
  productdesc : string;
}
