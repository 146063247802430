
import { Action } from '@ngrx/store';
import * as TOSoftwareStore from '../models/clsModels';
export enum  Header_Action_Type {
    HeaderInitial = 'headerInitial',
    HeaderSuccess= 'headerSuccess'
}
export class HeaderInitial implements Action {
    readonly type = Header_Action_Type.HeaderInitial;
    constructor() {
    }
}
export class HeaderSuccess implements Action {
    readonly type = Header_Action_Type.HeaderSuccess;
    constructor(public payload: TOSoftwareStore.ClsHeader) {
    }
}

export type ActionsHeader = HeaderInitial | HeaderSuccess ;
