export class clsbuynow {
  active: boolean;
  /*packageid: string;
  itempackageid: string;*/
  packageName: string;
  price: string;
  duration: string;
  lstick: [];

}

export class clsBuynowGetmodule {
  moduledesc: string;
}

export class clsBuynowGetmodulePackage {
  modulid: number;
}

export class clsaddonmaster {
  packageid: number;
  addonmasid: number;
  addonmasdesc: string
  active: boolean
  isremove:boolean=false;
}

export class clsaddontran {
  addonmasid: number;
  addondesc: string;
  addonmasdesc: string;
  active: boolean
  iconpath: string;
  price: number;
  priceremark : string;
  isstyleOutLine :boolean =false;
  tranid:number
}
export class clsAddModuleAddon {
  objmaster:clsaddonmaster = new clsaddonmaster();
  lstaddontran:clsaddontran[]=[]
}

export class clsAddonModuleGroup {
  lstmaster: clsaddonmaster[] = []
  lsttran: clsaddontran[] = []
}
