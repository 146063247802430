import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OperationService} from "@core";
import {LCSetting} from "../Model/clsSMModel";


@Injectable({
  providedIn: 'root'
})
export class SvrSMSettingService {
  public routeprefix = "license/sm_company"
  constructor(private http: HttpClient,
              private operation: OperationService) {

  }
  async onGetSmSetting(): Promise<LCSetting> {
    const url = await this.http
      .get<LCSetting>(this.operation.accessPointUrl + this.routeprefix + "/onGetSmSetting")
      .toPromise()
    return url;
  }
}



