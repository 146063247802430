import { Component, OnInit } from '@angular/core';
import { InitializeService } from "../../../core";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public initialize: InitializeService) {

  }

  ngOnInit(): void {
  }
  // onNewTab(URL) {
  //   let win = window;
  //   win.open(URL, "_blank").focus();

  // }
}
