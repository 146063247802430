import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OperationService} from '@core';
import {SvrDataService} from '../Class/svr-data.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {clsInit} from "../Model/clsGlobalData";
import {clsNan} from "../Class/svr-operation.service";
import {SvrDataServiceRegister} from "../../register-app/Class/svr-data.service";
import {svrSMUser} from "./svrSMUser";


@Injectable({
  providedIn: 'root',
})
export class svrSMcompany {

  constructor(
    public http: HttpClient,
    public operation: OperationService,
    public svrData: SvrDataService
  ) {
  }

  public routeprefix = 'license/sm_company';

  public async onGetInit(): Promise<any> {
    let res = await
      this.http
        .get(this.operation.accessPointUrl + this.routeprefix + "/onGetInit").toPromise();
    return res;
  }
}
@Injectable({
  providedIn: 'root',
})
export class resolve implements Resolve<clsInit> {
  constructor(private svrSMcompany:svrSMcompany,
              public svrdata:SvrDataService,
              public svrDataServiceRegister:SvrDataServiceRegister) {
  }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try{
      this.svrdata.objInit.objUser.userid = parseInt(localStorage.getItem("userid").toString());
      const _init = await this.svrSMcompany.onGetInit()
      if(!clsNan.isNullOrUndefined(_init)){
        this.svrDataServiceRegister.domainERP = _init.domain
      }
    }catch (e) {

    }

    return null;
  }
}
