import {clsitemmaster} from './clsICModule';
import {svrOLPending} from '../Controller/svrOLPending';


export class clsRejected {
  code: string;
  cusdesc: string;
  resdesc: string;
  userdesc: string;

  constructor(code?: string, cusdesc?: string, resdesc?: string, userdesc?: string) {
    this.code = code ?? '';
    this.cusdesc = cusdesc ?? '';
    this.resdesc = resdesc ?? '';
    this.userdesc = userdesc ?? '';
  }
}

export class objReject {
  clsReject : clsRejected[];
  clsUser : clsUserSetup[]
}

export class LCParamReqKey {
  objReq: LCRequestKey;
  objPro: any[];

  constructor(objReq?: LCRequestKey, objPro?: LCReqtran[]) {
    this.objReq = objReq ;
    this.objPro = objPro;
  }

}

export class  LCApproveParam {
  email : string;
  reqid : number;
  userid : number;
  constructor() {
    this.email = "";
    this.reqid = 0;
    this.userid = 0
  }
}

export class clsFilteritm {
  itemid: number[];
  isall: boolean = false;
}

export class LCRequestKey {
  custname: string;
  reqid: number;
  reqcode: string;
  email: string;
  contact: string;
  address: string;
  approved: boolean;
  active: boolean;
  clientid: string;
  clientuserid: number;
  userid: number
  apprid:number;

  constructor(
    custname?: string, clientid?: string, userid?: number, reqid?: number, reqcode?: string,
    email?: string, contact?: string, address?: string, approved?: boolean, active?: boolean,apprid?:number
  ) {
    this.custname = custname ?? '';
    this.reqid = reqid ?? 0;
    this.reqcode = reqcode??'';
    this.userid = userid ?? 0;
    this.email = email ?? '';
    this.contact = contact ?? '';
    this.address = address ?? '';
    this.approved = approved ?? true;
    this.active = true;
    this.apprid = apprid
    this.clientid = clientid ??""

  }
}

export class LCReqtran
{
  reqid : number
  proid : number
  qty:number
  amount:number
  expiretype:number
}
export class clsExpired {
  code: string;
  cusname: string;
  itemdesc:string;
  contact: string;
  exdate: string;
  overday: string;
  expire_date:string;
  clientuserid:number;
  constructor(code?: string, cusname?: string, contact?: string, exdate?: string, overday?: string) {
    this.code = code ?? '';
    this.cusname = cusname ?? '';
    this.contact = contact ?? '';
    this.exdate = exdate ?? '';
    this.overday = overday ?? '';
  }
}





export class clsUserSetup  {
  phone:string;
  contact:string;
  cusname:string
  clientuserid:number;
}

export class objExpired {
  clsexpire : clsExpired[];
  clsUser : clsUserSetup[];
}

export class LCApproved {
  apprid: number;
  apprcode: string;
  reqid: number;
  clientid: string;
  approveuser: number;
  approvedate: any;
  approvemachine: string;
  expire_date: any;
  proid: number;
  keylicense: string;
  approved: boolean;
  trial_expire: string;
  password: string;
  email: string;
  code: string;
  dbinfo: string;
}

export class LCPending {
  reqid: number;
  code: string;
  clientid: number;
  email: string;
  date: string;
  status: boolean;


  constructor(reqid?: number, code?: string, clientid?: number, email?: string, date?: string, status?: boolean) {
    this.reqid = reqid ?? 0;
    this.code = code ?? '';
    this.clientid = clientid ?? 0;
    this.email = email ?? '';
    this.date = date ?? '';
    this.status = status ?? true;

  }
}

export class clsRequestmaster {
  objPending: clsPending[];
  objCustomer: clsCustomer[];
}


export class clsPending {
  reqid: number;
  userid: number;
  clientuserid: number;
  code: string;
  adddate: any;
  status: string;
  void_ref: string;
  voiduser : number;
  fullname :string;
  phone : string;
  email:string

  constructor(reqid?: number, userid?: number, clientuserid?: number, code?: string, date?: any, status?: string, void_ref?: string, voiduser?: number) {
    this.reqid = reqid ?? 0;
    this.userid = userid ?? 0;
    this.clientuserid = clientuserid ?? 0;
    this.code = code ?? '';
    this.status = status ?? '';
    this.void_ref = void_ref ?? '';
    this.voiduser = voiduser ?? 0;

  }
}

export class clsCustomer {
  clientusercode: string;
  fullname: string;
  email: string;
  clientuserid: number;
  phone: string;

  constructor(clientusercode?: string, fullname?: string, lname?: string, email?: string, phone?: string) {
    this.clientusercode = clientusercode ?? '';
    this.fullname = fullname ?? '';
    this.email = email ?? '';
    this.phone = phone ?? '';
  }

}

export class clsApprovalMaster {
  objApproval: clsApproval[];
  objCustomerApproval: clsCustomerAppro[];

}

export class clsApproval {
  reqid: number;
  userid: number;
  clientuserid: number;
  code: string;
  approvedate: string;

  constructor(reqid?: number, userid?: number, clientuserid?: number, code?: string, approvedate?: string) {
    this.reqid = reqid ?? 0;
    this.userid = userid ?? 0;
    this.clientuserid = clientuserid ?? 0;
    this.code = code ?? '';
    this.approvedate = approvedate ?? '';

  }
}

export class clsCustomerAppro {
  clientusercode: string;
  clientuserid: number;
  fullname: string;
  email: string;
  phone: string;

  constructor(clientusercode?: string, fullname?: string, email?: string, clientuserid?: number, phone?: string) {
    this.clientusercode = clientusercode ?? '';
    this.clientuserid = clientuserid ?? 0;
    this.fullname = fullname ?? '';
    this.email = email ?? '';
    this.phone = phone ?? '';
  }
}
