import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientUserProfileService} from "../../Class/clientUserProfile.service";
import {enumRegisterStatus, SvrDataServiceRegister} from "../../Class/svr-data.service";
import {LCRegisterAccount} from "../../Model/clsCMModel";
import {OperationService} from "@core";
import {NgxSpinnerService} from "ngx-spinner";
import {svrSMcompany} from "../../../license-app/Controller/svrSMcompany";


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class CtlVerificationComponent implements OnInit {

  objRegAcc: LCRegisterAccount = new LCRegisterAccount()
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isshow:boolean;
  showpwd: boolean = false;
  showconfirm: boolean = false;
  constructor(private _formBuilder: FormBuilder,
              public route: ActivatedRoute,
              public svrRoute: Router,
              private svrSpinner: NgxSpinnerService,
              private svrOpt: OperationService,
              private svrCompany:svrSMcompany,
              public svrDataReg: SvrDataServiceRegister,
              public svrCientUserProfile: ClientUserProfileService
  ) {

    // this.svropr.NavigateRoute(this.route, 'verification', ["13bfc689-d08f-48a0-ab35-209f3beab3ce"])
    /*    this.svropr.NavigateRoute(this.route, 'verification', "13bfc689-d08f-48a0-ab35-209f3beab3ce");
    debugger
        const _usercode = parseInt(this.route.snapshot.paramMap.get('id'));
        let x  = (isNaN(_usercode) ? "" : _usercode);*/
  }

  async ngOnInit() {

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }
  async onActivateAccount() {
    if(this.objRegAcc.username && this.objRegAcc.username!=""){
      if (this.objRegAcc.password !== this.objRegAcc.cfpassword){
        return this.svrOpt.onToastWarning("Password Mismatch")
      }

      this.svrDataReg.initalizestatus = enumRegisterStatus.isinitialized
      this.objRegAcc.subdomain = this.svrDataReg.onCustomerData.subdomain
      this.objRegAcc.verifycode = this.svrDataReg.onCustomerData.verifycode
      this.objRegAcc.clientuserid = this.svrDataReg.onCustomerData.clientuserid
      const issuccess = await this.svrCientUserProfile.onCreateAccount(this.objRegAcc)
      if (issuccess.result == enumRegisterStatus.Complete) {
        this.svrDataReg.initalizestatus = enumRegisterStatus.Complete

        setTimeout(()=>{

          const product_path = this.svrCientUserProfile.onGetProductRoute(issuccess.productid);
          const redirectUrl = `${"https"}://${issuccess.domain}${issuccess.hostdomain}/${product_path}`;
          window.location.replace(redirectUrl);

         // window.location.replace("https://" + this.objRegAcc.subdomain + this.svrDataReg.onCustomerData.hostdomain );
        },3000);

      } else{

        this.svrDataReg.initalizestatus = enumRegisterStatus.InComplete
      }


    }else
      return this.svrOpt.onToastWarning("Please Fill Username")
  }

  oncheckConfirmpw(e:number=0) {
    if (this.objRegAcc.password !== this.objRegAcc.cfpassword)
      return 'Password Mismatch ';
  }


}

