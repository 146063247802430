import {HostListener, OnDestroy} from '@angular/core';
import {svrStoredataService} from './svr-storedata.service';
import {SvrEventService} from './svr-events.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

export abstract class clsBaseSetupComponent implements OnDestroy {

  public abstract objFormData: any;
  public abstract onRestoreSaveData(data: any);
  ngUnsubscribe: Subject<void> = new Subject<void>();
  public abstract onCheckSnapshot(route: string): boolean;
  public IsAllowToLeave: boolean = false;
  public objSnapshot: any;
  public constructor(
    public svrStoredata: svrStoredataService,
    public  svrEvent?: SvrEventService,
    public classkey?: string,
    public router?: Router,
    public route?: ActivatedRoute,
  ) {


  }


  public async doCheckSaveData()//Call this method after derive class complete constructor
  {
    await this.checkSaveData();
  }
  onStartLeaving(route: string): boolean {
    if (this.IsAllowToLeave) {
      return true;
    }

    return this.onCheckSnapshot(route);
    /*
      if (this.onCheckSnapshot()) {
        return true;
      } else {
        return false;
      }*/
  }
  public doSaveSnapshot() {
    if (this.objFormData)
      this.objSnapshot = JSON.parse(JSON.stringify(this.objFormData));
  }

  public doGetSnapshot(): any {
    return this.objSnapshot;
  }




  async checkSaveData() {
    let isRefresh = localStorage.getItem("IS_REFRESH") == "1";

    if (isRefresh) {
      let resFilter = await this.svrStoredata.getKey(this.classkey);
      if (resFilter != null)
        this.onRestoreSaveData(resFilter);
      localStorage.setItem("IS_REFRESH", "0");
      this.svrStoredata.clearKey(this.classkey);
    } else {
      localStorage.setItem("IS_REFRESH", "1");
      this.svrStoredata.setKey(this.classkey, this.objFormData);
    }


  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(e: any) {

    localStorage.setItem("IS_REFRESH", "1");
    this.svrStoredata.setKey(this.classkey, this.objFormData);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
