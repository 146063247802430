import {Component, Output, EventEmitter, Input, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() showToggle = true;
  @Input() showUser = true;
  @Input() isshowheader = true;
  @Input() toggleChecked = false;
  @ViewChild('button') button: ElementRef;
  @Output() toggleCollapsed = new EventEmitter<void>();

  constructor() {
  }

  doCollapse() {

    this.toggleCollapsed.emit();
  }
}
