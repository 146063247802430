import { Injectable } from '@angular/core';
import {Map} from 'immutable';
import {clsInit} from '../Model/clsGlobalData';

@Injectable({
  providedIn: 'root'
})
export class SvrDataService {
  public popupParam = {};
  public title: string;
  public lstRole: Map<number, number>;
  public objInit: clsInit = new clsInit();

  constructor() {
    this.lstRole = Map();
    this.popupParam = {
      panelClass: 'full-dialog',
    };
  }


}

export enum enumStatus {
  Complete = 0,
  Update = 1,
  Failed = -1,
  inUsed = -2,
  DuplicateCode = -3,
  DuplicateUserName = -4,

}
