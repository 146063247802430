import { Component, Input, OnInit} from '@angular/core';
import {InitializeService} from "@core";
import {OwlOptions} from "ngx-owl-carousel-o";
import {IHeader} from "../../../../Store/intity/IModels";


@Component({
  selector: 'app-ctl-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class CtlSliderComponent implements OnInit {
  @Input() sliderData: IHeader;

  mainHeaderSliderOption: OwlOptions;

  constructor(public  initialize: InitializeService) {
    // setTimeout(() => {
    //   const elements = document.getElementsByClassName('txt-rotate');
    //   // tslint:disable-next-line:prefer-for-of
    //   for (let i = 0; i < elements.length; i++) {
    //     const toRotate = elements[i].getAttribute('data-rotate');
    //     const period = elements[i].getAttribute('data-period');
    //     if (toRotate) {
    //       interF.typeWriting(elements[i], JSON.parse(toRotate), period);
    //     }
    //   }
    //   // INJECT CSS
    //   const css = document.createElement('style');
    //   css.type = 'text/css';
    //   css.innerHTML = '.txt-rotate > .wrap { border-right: 0.05em solid #fff }';
    //   document.body.appendChild(css);
    // });
  }

  ngOnInit() {
    if (this.sliderData === null || this.sliderData === undefined) {
      this.sliderData = {...this.initialize.initData.headers[this.initialize.headersIndex]};
    }
    setTimeout(() => {
      this.mainHeaderSliderOption = {
        loop: true,
        lazyLoad: true,
        autoplay: true,
        center: true,
        merge: true,
        // slideBy: 'page',
        dots: false,
        // dotsData: true,
        // autoHeight: true,
        // autoWidth: true,
        startPosition: 1,
        // animateOut: 'fadeOut',
        // animateIn: 'fadeIn',
        autoplayTimeout: 5000,
        // fluidSpeed: 4000,
        autoplayHoverPause: true,
        autoplaySpeed: 4000,
        dotsSpeed: 500,
        // nav: true,

        responsive: {
          0: {
            items: 1
          }
        }
      };
    }, 300);
  }

}

export class CarouselData {
  id?: string;
  text: string;
  dataMerge?: number;
  width?: number;
  dotContent?: string;
  src?: string;
  dataHash?: string;
  buttonView?: string;
  subtitle?: string;
}
