
import { Injectable } from '@angular/core';
import {Menu} from "@core/services/menu.service";
@Injectable()
export class OperationData {
    public menu: Menu [];
    public title = [];


   public colorList = [
    { key: 'flame', value: '#e45a33', friendlyName: 'Flame' },
    { key: 'orange', value: '#fa761e', friendlyName: 'Orange' },
    { key: 'infrared', value: '#ef486e', friendlyName: 'Infrared' },
    { key: 'male', value: '#4488ff', friendlyName: 'Male Color' },
    { key: 'female', value: '#ff44aa', friendlyName: 'Female Color' },
    { key: 'paleyellow', value: '#ffd165', friendlyName: 'Pale Yellow' },
    { key: 'gargoylegas', value: '#fde84e', friendlyName: 'Gargoyle Gas' },
    { key: 'androidgreen', value: '#9ac53e', friendlyName: 'Android Green' },
    {
      key: 'carribeangreen',
      value: '#05d59e',
      friendlyName: 'Carribean Green'
    },
    { key: 'bluejeans', value: '#5bbfea', friendlyName: 'Blue Jeans' },
    {
      key: 'cyancornflower',
      value: '#1089b1',
      friendlyName: 'Cyan Cornflower'
    },
    { key: 'warmblack', value: '#06394a', friendlyName: 'Warm Black' }
  ];



}
