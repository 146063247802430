import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, takeUntil } from "rxjs/operators";
import * as TOSoftwareStore from "../../Store/reducer";
import { MenuService } from "./menu.service";
import { Observable, Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import { HeaderService } from "./header.service";

import { CollectionService } from "./collection.service";
import { InitializeService } from "./initialize.service";
import { ToastrService } from "ngx-toastr";
import {clsIniialize, ClsLogin, clsmedia, ClsNewCollection} from "../../Store/models/clsModels";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable()
export class OperationService {
  public headers: HttpHeaders;
  public accessPointUrl: string = "";
  public accessPointUrlJson: string = "";
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(

    private menuService: MenuService,
    private headerService: HeaderService,
    private collectionService: CollectionService,
    private initialize: InitializeService,
    private http: HttpClient,
    public toast: ToastrService,
    public svrRoute: Router,
    public store: Store<TOSoftwareStore.TOSoftwareState>,
    @Inject("BASE_URL") baseUrl: string
  ) {
    // this.store
    //   .pipe(select(TOSoftwareStore.getAllInit))
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe(n => {
    //     debugger
    //     if (n !== undefined) {
    //       // this.menuService.set(n.menugroup);
    //       // this.initialize.setMenuLocalStorage(n.menu);
    //       // this.headerService.set(n.header);
    //       this.initialize.setInitial(n);
    //     }
    //   });
/*    this.store
      .pipe(select(TOSoftwareStore.getNavMenu))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(n => {
        if (n !== undefined) {
          // this.menuService.update(n);
        }
      });*/

    // this.store
    // .pipe(select(TOSoftwareStore.menuEditorItemState))
    // .pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe(n => {
    //   debugger
    //   if (n !== undefined) {
    //    this.initialize.setMenuChildList(n.items);
    //   }
    // });
   /* this.store
      .pipe(select(TOSoftwareStore.getCollection))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(n => {
        if (n) {
          this.collectionService.update(n);
        }
      });*/

    this.accessPointUrl = baseUrl + "api/";
    this.accessPointUrlJson = baseUrl;
    this.headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8"
    });
  }

  getChildMenu() {
    return this.store.select(TOSoftwareStore.getMenuEditor);
  }

  loadHeader(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson + "assets/data/header.json?_t=" + Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  loadInit(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/tosoftware.json?_t=" +
        Date.now(),
        {
          headers: this.headers
        }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }
  async addMultiImg(objMedia: clsmedia): Promise<any> {
    return await this.http
      .post(this.accessPointUrl + "operation/SaveImg", { objFileImg: objMedia.objFileImg }, {
        headers: this.headers
      }).toPromise();
  }
  async onGetImage(): Promise<any> {
    return await this.http
      .get(this.accessPointUrlJson + "assets/data/tomedia.json?_t=" + Date.now(), {
        headers: this.headers
      }).toPromise();
    // return await this.http
    //   .get(this.accessPointUrl + "operation/GetImg", {
    //     headers: this.headers
    //   }).toPromise();
  }
  addCollection(payload: ClsNewCollection): Observable<any> {
    return this.http
      .post(this.accessPointUrl + "Collection", payload, {
        headers: this.headers
      })
      .pipe(n => {
        return n;
      });
  }
  removeCollection(payload: ClsNewCollection): Observable<any> {
    return this.http
      .delete(this.accessPointUrl + "Collection/" + payload.id, {
        headers: this.headers
      })
      .pipe(n => {
        return n;
      });
  }
  // async addMultiImg(objMedia: clsmedia): Promise<any> {
  //   return await this.http
  //     .post(this.accessPointUrl + "operation/SaveImg", { objFileImg: objMedia.objFileImg }, {
  //       headers: this.headers
  //     }).toPromise();
  // }
  public async onDeleteImg(objMedia:any) : Promise<any> {
    return await this.http
      .post(this.accessPointUrl + "operation/DeleteImg", {objMedia:objMedia}, {
        headers: this.headers
      }).toPromise();
  }
  updateCollection(payload: ClsNewCollection): Observable<any> {
    return this.http
      .put(this.accessPointUrl + "Collection/" + payload.id, payload, {
        headers: this.headers
      })
      .pipe(n => {
        return n;
      });
  }

  doLogin(objLogin: ClsLogin): Observable<any> {
    return this.http
      .post(this.accessPointUrl + "auth/login", objLogin, {
        headers: this.headers
      })
      .pipe(n => {
        return n;
      });
  }

  async doPublic(objInit: clsIniialize): Promise<any> {
    return await this.http
      .post(this.accessPointUrl + 'operation/publish', objInit
        , {
          headers: this.headers,

        }).toPromise();

  }

  loadCollection(): Observable<any> {
    return this.http
      .get(this.accessPointUrl + "Collection", { headers: this.headers })
      .pipe(n => {
        return n;
      });
  }

  loadNavMenu(): Observable<any> {
    return this.http
      .get(this.accessPointUrlJson + "assets/data/menu.json?_t=" + Date.now(), {
        headers: this.headers
      })
      .pipe(
        map((n: any) => {
          return n.menu;
        })
      );
    //  return this.http.get(this.accessPointUrl + 'NavMenus', { headers: this.headers }).pipe(n => {
    //    return n;
    //  });
    // return new Promise((resolve, reject) => {
    //   this.http
    //     .get(this.accessPointUrl + 'NavMenus', { headers: this.headers })
    //     .pipe(
    //       catchError(res => {
    //         debugger
    //         resolve();
    //         return res;
    //       })
    //     )
    //     .subscribe(
    //       (res: any) => {

    //         this.menuService.set(res.menu);
    //       },
    //       () => {},
    //       () => {
    //         resolve();
    //       }
    //     );
    // });
  }

  public addNavMenu(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.accessPointUrl + "NavMenus", payload, {
          headers: this.headers
        })
        .pipe(
          catchError(res => {
            resolve(res);
            return res;
          })
        )
        .subscribe(
          (res: any) => {
            this.menuService.add(res.menu);
          },
          () => { },
          () => {
            // resolve(_);
          }
        );
    });
  }

  public putNavMenu(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.accessPointUrl + "NavMenus/" + payload.Id, payload, {
          headers: this.headers
        })
        .pipe(
          catchError(res => {
            // resolve();
            return res;
          })
        )
        .subscribe(
          (res: any) => {
            this.menuService.set(res.menu);
          },
          () => { },
          () => {
            // resolve();
          }
        );
    });
  }


   public  async GetLicenseMenu(): Promise<any> {
    return await this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/menu.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      ).toPromise();
  }

  public GetRestFeature(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/featurelist.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetRetailFeature(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/featurelist.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetBussinessPartner(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/bussinesspartner.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetSolution(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson + "assets/data/solution.json?_t=" + Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetVideoAds(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson + "assets/data/videoads.json?_t=" + Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetEventComing(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/eventcoming.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetCusRef(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/customerRef.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetCusRetail(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/customerRetail.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetCusRest(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/customerRest.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetTributor(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/distribution.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderMain(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/mainheader.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderMenu(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/tosoftware.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderRetailMenu(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/menuretail.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderRestaurantMenu(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/menurestaurant.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderMenuRight(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson + "assets/data/menuright.json?_t=" + Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetAllTitle(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson + "assets/data/alltitle.json?_t=" + Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetMenuService(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/menuservice.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderMenuRetail(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/retailmenu.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetHeaderMenuRetaurant(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson +
        "assets/data/restaurantmenu.json?_t=" +
        Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }

  public GetCountry(): Observable<any> {
    return this.http
      .get(
        this.accessPointUrlJson + "assets/data/country.json?_t=" + Date.now(),
        { headers: this.headers }
      )
      .pipe(
        map(n => {
          return n;
        })
      );
  }
  public onToastSuccess(msg: string = '', title: string = '') {

    setTimeout(() => {
      this.toast.success(msg, title);
    }, 100);
  }

  public onToastFailed(msg: string = '', title: string = '') {
    this.toast.error(msg, title);
  }

  public onToastInfo(msg: string = '', title: string = '') {
    this.toast.info(msg, title);
  }

  public onToastWarning(msg: string = '', title: string = '') {
    this.toast.warning(msg, title);
  }
  public NavigateRoute(activateRoute: ActivatedRoute, route: string, ...params: any[]) {
    const parentRoute = this.onGetParentRoute(activateRoute);
    if (!clsNan.isNullOrUndefined(params) && params.length > 0) {
      this.svrRoute.navigate([`${parentRoute}/${route}`, ...params]).then();
    } else {
      this.svrRoute.navigate([`${parentRoute}/${route}`]).then();
    }
  }
  public onGetParentRoute(actRoute: ActivatedRoute) {
    const activeRoute = actRoute.snapshot['_urlSegment']['segments'][0];
    return activeRoute.path;
  }
}
export class clsNan {
  public static isNullOrUndefined = (obj: any) => (obj === undefined || obj === null);
}
