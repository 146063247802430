import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OperationService} from "@core";
import {clsFreeTrial, clsTrialTitle, clsUserSetup} from "../../../../Store/models/clsModels";
import {ClientUserProfileService} from "../../Class/clientUserProfile.service";
import {LCParamStatus, LCPartner} from "../../Model/clsCMModel";
import {enumRegisterStatus, SvrDataServiceRegister} from "../../Class/svr-data.service";
import {NgxSpinnerService} from "ngx-spinner";
import {SvrSMWhRangeService} from "../../Class/svrSMWhRange.service";
import {SvrSMBustypeService} from "../../Class/svrSMBustype.service";
import {SvrSMSettingService} from "../../Class/svrSMSetting.service";
import {LCSetting} from "../../Model/clsSMModel";
import {clsNan} from "../../../license-app/Class/svr-operation.service";


@Component({
  selector: 'app-ctl-register-testing',
  templateUrl: './register-testing.component.html',
  styleUrls: ['./register-testing.component.scss']
})
export class CtlRegisterTesting implements OnInit {
  isSpecialChar: any = /[\s`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
  /*objSignUp: clsUserSetup = new clsUserSetup();*/
  objSignUp: LCPartner = new LCPartner();
  _title: clsTrialTitle[] = [
    {
      titleInfo: 'Join many businesses working smarter with T.O Software',
      src: 'assets/freetrial/Inventory.png',
      freetrialday: 15
    }
  ];
  arabicRegex = '[\u0600-\u06FF]';
  isShowBtnSign: boolean = false;
  isSignup = true;
  config = {
    displayKey: 'label', // if objects array passed which key to be displayed defaults to description
    search: false,
    height: '260px',
  };
  _inputFreeTrial: clsFreeTrial[] = [
    {
      id: 'Storename', name: 'Storename',
    },
    {
      id: 'compname', name: 'Company Name'
    },
    {
      id: 'Email', name: 'Email'
    },
    {
      id: 'fname', name: 'Full Name'
    },
    // {
    //   id : 'lname', name : 'Last Name'
    // },
    {
      id: 'bus_type', name: 'Business Type'
    },
    {
      id: 'phonenumber', name: 'Phone Number'
    },


  ]
  _selectBustype = [
    /*{
      'id': 0, 'value': 'cloth', 'label': 'Cloth'
    },
    {
      'id': 1, 'value': 'bike', 'label': 'Bike'
    }
    ,
    {
      'id': 2, 'value': 'elec', 'label': 'Electronic'
    }
    ,
    {
      'id': 3, 'value': 'fservice', 'label': 'Full Service'
    },
    {
      'id': 4, 'value': 'nightclub', 'label': 'Bar / Night Club'
    }*/
  ]
  Bussinessvalue: any;
  Gender = [
    {

      'label': 'Male',
      'value': 'M',
    },
    {
      'label': 'Female',
      'value': 'F',
    },
    {
      'label': 'Other',
      'value': 'O',
    }
  ];
  Gendervalue: any;
  WhRange = [
   /* {
      'id': 0,
      'label': 'Outlet 1-> 5',
      'value': 'W15',
    },
    {
      'id': 1,
      'label': 'Outlet 6-> 11',
      'value': 'W611',
    },
    {
      'id': 2,
      'label': 'Outlet 12-> Up',
      'value': 'W12',
    }*/
  ];
  WhRangeValue: any;
  Systemsvalue: any;
  listSystemType: any
  systypeconfig = {
    displayKey: 'productdesc', // if objects array passed which key to be displayed defaults to description
    search: false,
    height: '260px',
  };
  lstbus: any
  busconfig = {
    displayKey: 'bussdesc', // if objects array passed which key to be displayed defaults to description
    search: false,
    height: '260px',
  };
  lstwhrange: any
  whconfig = {
    displayKey: 'whrangedesc', // if objects array passed which key to be displayed defaults to description
    search: false,
    height: '260px',
  };
  _objsetting:LCSetting = new LCSetting()
  constructor(private svrRoute: Router,
              private svrOpt: OperationService,
              public route: ActivatedRoute,
              public svrDataReg: SvrDataServiceRegister,
              private svrSpinner: NgxSpinnerService,
              public svrCientUserProfile: ClientUserProfileService,
              public svrSMWhRangeService: SvrSMWhRangeService,
              public svrBustypeService: SvrSMBustypeService,
              public svrsmsettingService :SvrSMSettingService
  ) {
  }

  async ngOnInit() {
    this.listSystemType = await this.svrBustypeService.onGetSystemType()
    this.lstbus = await this.svrBustypeService.onGetBusinesstype()
    this.lstwhrange = await this.svrSMWhRangeService.onGetWhRange()
    this._objsetting = await this.svrsmsettingService.onGetSmSetting()
    this.Gendervalue = this.Gender[0]
    this.Bussinessvalue = this.lstbus[0]
    this.WhRangeValue = this.lstwhrange[0]
  }


  onHome() {
    this.svrRoute.navigate(['home']);
  }

  onCheckInput(objSignUp: LCPartner) {
    if (!objSignUp.compname)
      return "Company Name";
    else if (!objSignUp.subdomain)
      return "Domain";
    else if (clsNan.isNullOrUndefined(objSignUp.productid) || objSignUp.productid == 0) {
      return "System Type"
    }
    else
      return -1
  }

  //

  async  onverifyDomain(domain:string){

    let accStatus = await this.svrCientUserProfile.onVerifyDomain(domain);
  }

  async onSignup() {
    await this.svrSpinner.show()
    setTimeout(async () => {
        this.objSignUp.subdomain = this.objSignUp.subdomain.replace(this.isSpecialChar, '')
        if (!clsNan.isNullOrUndefined(this.Systemsvalue) && this.Systemsvalue.productid != 0) {
          this.objSignUp.productid = this.Systemsvalue.productid
        }
        let status = this.onCheckInput(this.objSignUp);
        if (status == -1) {
          let objparam = new LCParamStatus()
          objparam.isCreateNew = true
          objparam.compName = this.objSignUp.compname
          objparam.domain = this.objSignUp.subdomain
          objparam.email = this.objSignUp.email;
          objparam.productid = this.objSignUp.productid;
          let accStatus = await this.svrCientUserProfile.onRegisterTesting(objparam);
          this.isSignup = false;
        } else {
          this.svrOpt.onToastWarning("Please Fill " + status)
        }
        await this.svrSpinner.hide()
      }
      ,
      1000
    )

  }

  onInput() {
    let domain = "";
    domain = this.objSignUp.compname
    this.objSignUp.subdomain =
      domain
        .replace(this.isSpecialChar, '');
    // remove all special character

  }

  noSpecialChars(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode;  // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      return;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      return;
    }
    e.preventDefault();
  }

  onKeydown(event) {
    if (event.keyCode === 32) {
      return false;
    }
  }

  checkStatus(objparam
                :
                LCParamStatus
  ) {

    if (objparam.domainExist) {
      return this.svrOpt.onToastWarning('domain already existed');
    }
    if (objparam.emailExist) {
      return this.svrOpt.onToastWarning('email already existed');
    }
    return enumRegisterStatus.Complete
  }
}

