import {Component, OnInit, Input} from '@angular/core';
import {clsFreeTrial, selectBusType, clsTrialTitle} from '../../../../Store/models/clsModels';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {startWith, map} from 'rxjs/operators';
import {clsNan, OperationService} from "@core";
import {MatDialog} from '@angular/material/dialog';
import {CtlFreeTrialComponent} from "../../../register-app/component/free-trial/free-trial.component";
import {ProductComponent} from "./product/product.component";
import {HttpClient} from "@angular/common/http";
import {svrPricing} from "../../Controller/svrPricing";
import {Email, LCParamStatus, LCPartner} from "../../../register-app/Model/clsCMModel";
import {enumRegisterStatus} from "../../../register-app/Class/svr-data.service";
import {NgxSpinnerService} from "ngx-spinner";
import {svrRequestMail} from "../../Controller/svrRequestQuatation";
import {clsAddon, clsCustomerInfo, clsSelectBussType} from "../../Model/clsRequest";
import loader from "@angular-devkit/build-angular/src/angular-cli-files/plugins/single-test-transform";
import {localSTKey, saveAddon} from "../../form/pricing-layout/pricing.component";
import {clsBaseSetupComponent} from "@core/services/clsBaseSetupComponent";
import {svrStoredataService} from "@core/services/svr-storedata.service";
import {SvrEventService} from "@core/services/svr-events.service";
import {svrDataServiceWebsite} from "../../Class/svr-Data-ServiceWebsite.service";
import {clsAddModuleAddon} from "../../Model/clsBuynow";
import {forEach} from "lodash";

@Component({
  selector: 'app-ctl-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss']
})
export class CtlRequestQuoteComponent extends clsBaseSetupComponent implements OnInit {
  objCustInfo = new clsCustomerInfo();
  lstAdd: clsAddon[] = [];
  config = {
    displayKey: 'label', // if objects array passed which key to be displayed defaults to description
    search: false,
    height: '260px',
  };
  _title: clsTrialTitle[] = [
    {
      titleInfo: 'Join many businesses working smarter with T.O Software. Contact Sale : 023 224 035 / 093 999 258. ',
      src: 'assets/freetrial/Inventory.png',
      freetrialday: 15
    }
  ]

  constructor(private svrRoute: Router,
              private svrOpt: OperationService,
              public dialog: MatDialog,
              public route: ActivatedRoute,
              private svrSpinner: NgxSpinnerService,
              public httpclient?: HttpClient,
              public svrPrice?: svrPricing,
              public svrOpr?: OperationService,
              public svrRequestMail ?: svrRequestMail,
              public svrEvent?: SvrEventService,
              public svrStoreData?: svrStoredataService,
              public svrData?: svrDataServiceWebsite
  ) {
    super(svrStoreData, svrEvent, "CtlRequestQuoteComponent", svrRoute, route);
    this.onInput();

  }

  onCheckModule() {
    this.svrOpr.NavigateRoute(this.route, 'buy-now', this.packageid);

  }

  packageid: number;
  testID: number;

  pricingList = {
    tables: [],
    modulelist: [],
    modulePackage: [],

    classNames: ['pricing-card basic', 'pricing-card echo', 'pricing-card pro'],
  };


  async ngOnInit() {
    let key = new localSTKey();
    let packageid = localStorage.getItem(key.seletedPackage);
    localStorage.removeItem(this.key.usersigninemail);
    this.packageid = (Number(packageid) + 1);
    this.objCustInfo.packageid = this.packageid;
    let lstAddon = await this.svrStoreData.getKey(this.svrData._KEYADDON) as any;
    this.objCustInfo.duration = await this.svrStoreData.getKey(this.svrData._Duration) as string;
    this.getLstBussType = await this.svrRequestMail.GetBusineesType()
    this.getBusiness = this.getLstBussType[0]
    let lstaddontran = [...lstAddon]
    lstaddontran.forEach((em, i) => {
      try {
        if (em.length > 1) {
          em.forEach(el => {
            let objAddon: clsAddon = new clsAddon();
            objAddon.addonid = el.tranid
            objAddon.addonmid = el.addonmasid
            this.lstAdd.push(objAddon)
          })
        } else if (em.length == 1) {
          let objAddon: clsAddon = new clsAddon();
          objAddon.addonid = em[0].tranid
          objAddon.addonmid = em[0].addonmasid
          this.lstAdd.push(objAddon)
        }
      } catch (ex) {


      }

    });

  }

  onHome() {
    this.svrRoute.navigate(['home']);
  }

  getCompany: string;
  getFname: string;
  getLname: string;
  getPhone: string;
  getEmail: string;
  ToEmail: string;
  getBusiness: clsSelectBussType;
  getLstBussType: any;
  AddCusInfo: any;
  arabicRegex = '[\u0600-\u06FF]';

  busconfig = {
    displayKey: 'bussdesc', // if objects array passed which key to be displayed defaults to description
    search: false,
    height: '260px',
  };

  stripText(event) {
    const seperator  = '^([0-9])';
    const maskSeperator =  new RegExp(seperator , 'g');
    let result =maskSeperator.test(event.key);   return result;
  }


  noSpecialChars(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode;  // k = event.charCode;  (Both can be used)
    if ((k == 45))
      return;
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      return;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      return;
    }
    e.preventDefault();
  }

  onInput() {
    this.getinfor.getCompany = '';
    this.getinfor.getFname = '';
    this.getinfor.getLname = '';
    this.getinfor.getPhone = '';
    this.getinfor.getEmail = '';
  }

  mustInclude = [];

  onCleartextbox() {
    this.getCompany = '';
    this.getFname = '';
    this.getLname = '';
    this.getPhone = '';
    this.getEmail = '';
  }

  isSwitchStyle: string;


  getinfor: InputInfo = new InputInfo();

  getshowIndex: number;

  async onSignup() {
    this.getshowIndex = this.onUserInput();
    this.objCustInfo.bustypeid = this.getBusiness.bussid;
    this.onShowAlertMessage(this.getshowIndex);
  }

  loading: boolean = true
  onLoad() {
    this.loading = false;
  }
  failIcon = 'assets/to_website/addon-module/circle_cancel.svg';
  successIcon = 'assets/to_website/addon-module/circlecheck.svg';

  async onShowAlertMessage(a) {
    let key = new localSTKey();
    this.mustInclude = [];
    if (a == 0) {
      this.mustInclude[0] = '* Company name is require';
    } else if (a == 1) {
      this.mustInclude[1] = "* First name is require"
    } else if (a == 2) {
      this.mustInclude[2] = "* Last name is require"
    } else if (a == 3) {
      this.mustInclude[3] = "* Phone number is require"
    }
    else if (a == 4) {
      this.mustInclude[3] = "* Phone number must be at lease 9 digits"
    }
    // else if (a == 5) {
    //   //this.mustInclude[4] ="* This should include @";
    // } else if (a == 6) {
    //   //this.mustInclude[4] ="* Email is require";
    // }
    else if (a == -1) {
      this.objCustInfo.lstAdd = this.lstAdd;
      let custid = await this.svrRequestMail.OnAddCustomerInfo(this.objCustInfo);
      if (custid > 0) {
        let objemail = new Email();
        objemail.custid = custid;
        objemail.ToMail = this.objCustInfo.email;
        await this.svrRequestMail.GetEmailToSale(objemail).then((value) => {
            if (!value) {
              localStorage.setItem(key.dialogIconinRequest, this.failIcon);
              this.svrOpr.NavigateRoute(this.route, 'product');
              localStorage.setItem(this.key.emailtosale_status_frm_req,'Failed' )
              localStorage.setItem(this.key.offdec_status_frm_req,'off' )
            } else {
              localStorage.setItem(key.dialogIconinRequest, this.successIcon);
              localStorage.setItem(key.usersigninemail,this.objCustInfo.email);
              localStorage.setItem(this.key.emailtosale_status_frm_req,'Success' )
              localStorage.setItem(this.key.offdec_status_frm_req,'on' )
              this.onClearLocalStorage();
              this.svrOpr.NavigateRoute(this.route, 'product');
            }
          }
        );
      }
      if (custid)
        await this.svrStoreData.clearKey(this.svrData._KEYADDON)
      await this.svrSpinner.hide();
    }
  }

  key = new localSTKey();

  onClearLocalStorage() {
    this.svrStoredata.clearKey("FrmBuyNowComponent");
    //localStorage.setItem(this.key.isClearAddon, '1');
  }

  onCheckInput(): number {
    if (clsNan.isNullOrUndefined(this.objCustInfo.compname))
      return 0;
    else if (clsNan.isNullOrUndefined(this.objCustInfo.fname))
      return 1
    else if (clsNan.isNullOrUndefined(this.objCustInfo.lname))
      return 2
    else if (clsNan.isNullOrUndefined(this.objCustInfo.phone))
      return 3
    else if (clsNan.isNullOrUndefined(this.objCustInfo.email))
      return 4
    else
      return -1
  }

  onUserInput(): number {
    if (this.objCustInfo.compname == '')
      return 0;
    else if (this.objCustInfo.fname == '')
      return 1
    else if (this.objCustInfo.lname == '')
      return 2
    else if (this.objCustInfo.phone == '')
      return 3
    else if ( this.objCustInfo.phone.length<9)
      return 4
    /*else if (this.objCustInfo.email=='')
      return 6*/
    /*else if (!this.objCustInfo.email.includes("@"))
      return 5*/
    else
      return -1;
  }

  objFormData: any;

  onCheckSnapshot(route: string): boolean {
    return false;
  }

  onRestoreSaveData(data: any) {
  }
}

export class InputInfo {
  getCompany: string;
  getFname: string;
  getLname: string;
  getPhone: string;
  getEmail: string;
  master: number;
  tran: number;
}


// {{{{{{open dialog}}}}}}
// this.dialog.open(ProductComponent,{
//   data:this.packageid
// }).afterClosed().toPromise().then(result => {
//   console.log('----->result===>',result)
// });
