import { Component, OnInit } from '@angular/core';
import { clsHearderMain, clsHeaderTypePro, clsProductType, clsBussinessType, clsProduct, clsHeaderRight } from '../../../Store/models/clsModels';
import { Router } from '@angular/router';
import {MenuService} from "@core";

@Component({
  selector: 'app-ctl-front-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class CtlHeaderComponent implements OnInit {
  _Retail: boolean;
  _Restaurant: boolean;
  _Hardware : boolean;
  _MainHeader: boolean = true



  MainHeaderRight: clsHeaderRight[] = [
    { name: 'About', fontawesomeCode: '', route: 'aboutus',link:'' },
    { name: 'Login', fontawesomeCode: '', route: '' ,link:''},
    { name: 'Free Trial', fontawesomeCode: '', route: 'https://angkorwat.app:448/FrontPageApp/#/' ,link:'https://angkorwat.app:448/FrontPageApp/#/' }
  ]
  MainHeader: clsHearderMain[] = [
    { description: 'Retail', fontawesomeCode: '', route: '/retail' },
    { description: 'Restaurant', fontawesomeCode: '', route: '/restaurant' },
    { description: 'Hardware', fontawesomeCode: '', route: '/hardware' },
    { description: 'Service', fontawesomeCode: '', route: '/service' },
  ];

  _retail: clsHeaderTypePro[] = [
    { description: 'Retail', fontawesomeCode: '' },
    { description: 'Product', fontawesomeCode: '' },
    { description: 'Bussiness Type', fontawesomeCode: '' },
  ];

  // - Point of Sale
  // - Inventory Control
  // - Order Management
  // - Account Receivable
  // - Account Payable
  // - Purchase Management
  // - Cloud Report
  // - iCount
  // - Cash Collection
  // - Price Checking
  // - iTracking
  // - Vembers


  _productRetail: clsProduct[] = [
    { proname: 'Point of Sales', fontawesomeCode: '' },
    { proname: 'Inventory Control', fontawesomeCode: '' },
    { proname: 'Order Management', fontawesomeCode: '' },
    { proname: 'Account Receivable', fontawesomeCode: '' },
    { proname: 'Account Payable', fontawesomeCode: '' },
    { proname: 'Purchase Management', fontawesomeCode: '' },
    { proname: 'ICount', fontawesomeCode: '' },
    { proname: 'Cloud Reports', fontawesomeCode: '' },
    { proname: 'Cash Collection', fontawesomeCode: '' },
    { proname: 'ITracking', fontawesomeCode: '' },
    { proname: 'Price Checking', fontawesomeCode: '' },
    // { proname: 'Loyalty', fontawesomeCode: '' },
    // { proname: 'Payment', fontawesomeCode: '' },
    // { proname: 'Hardware', fontawesomeCode: '' },
    { proname: 'Vembers', fontawesomeCode: '' },
  ]
  _bussinessTypeRetail: clsBussinessType[] = [
    { bussinessType: 'Supermarket / Minimart', fontawesomeCode: '' },
    { bussinessType: 'Cosmetic', fontawesomeCode: '' },
    { bussinessType: 'Souvenir Shop', fontawesomeCode: '' },
    { bussinessType: 'Pharmacy', fontawesomeCode: '' },
    { bussinessType: 'baby Shop', fontawesomeCode: '' },
    { bussinessType: 'Electronic', fontawesomeCode: '' },
    { bussinessType: 'Phone Shop', fontawesomeCode: '' },
    { bussinessType: 'Sporting Goods ', fontawesomeCode: '' },
  ]

  // Point of Sales
  // - Recipe Management
  // - Kitchen/Pick Up Display System
  // - Mobile Ordering
  // - Kiosk Self-Ordering
  // - Cloud Report
  // - Vembers

  _restaurant: clsHeaderTypePro[] = [
    { description: 'Restaurant', fontawesomeCode: '' },
    { description: 'Product', fontawesomeCode: '' },
    { description: 'Bussiness Type', fontawesomeCode: '' },
  ];
  _productRestaurant: clsProduct[] = [
    { proname: 'Point of Sales', fontawesomeCode: '' },
    { proname: ' Recipe Management', fontawesomeCode: '' },
    { proname: 'Kitchen/Pick Up Display System', fontawesomeCode: '' },
    { proname: 'Kiosk Self-Ordering ', fontawesomeCode: '' },
    { proname: 'Mobile Ordering', fontawesomeCode: '' },
    { proname: 'Cloud Report ', fontawesomeCode: '' },
    { proname: 'Vembers ', fontawesomeCode: '' },

    // { proname: 'Purchase Management ', fontawesomeCode: '' },
    // { proname: 'Loyalty', fontawesomeCode: '' },
    // { proname: 'Payment', fontawesomeCode: '' },
    // { proname: 'Hardware', fontawesomeCode: '' },
    // { proname: 'Integration', fontawesomeCode: '' },
  ]
  _bussinessTypeRestaurant: clsBussinessType[] = [
    { bussinessType: 'Full Service', fontawesomeCode: '' },
    { bussinessType: 'Club/Bar/ KTV', fontawesomeCode: '' },
    { bussinessType: 'Fast Service Restaurant', fontawesomeCode: '' },
    { bussinessType: 'Coffee/Drink Shop', fontawesomeCode: '' },
  ]
  _hardware: clsHearderMain[] = [
    { description: 'Hardware', fontawesomeCode: '', route: '/hardware' },
  ]
  _protype : clsProductType[] = [
    {proname: this._productRetail,  fontawesomeCode: '' , Type : 'Retail'},
    {proname: this._productRestaurant,  fontawesomeCode: '' , Type : 'Restaurant'},
  ]


  constructor(private router: Router,private menuService: MenuService) {
    const states = this.router.url.slice(1).split('/');
    if (states.length > 1) {
      this.onChangeHeader(states[1]);
    } else if (states.length === 1) {
        if(states[0].toLowerCase() === 'console') {
          this.onChangeHeader(states[0]);
        }
    }
  }

  ngOnInit() { }
  onChangeHeader(desc: string) {
    if (desc.toLowerCase() === 'retail') {
      this._Retail = true;
      this._Restaurant = false;
      this._MainHeader = false;
    } else if (desc.toLowerCase() === 'restaurant') {
      this._Retail = false;
      this._Restaurant = true;
      this._MainHeader = false;
    } else if (desc.toLowerCase() === 'hardware') {
      this._Hardware = true;
      this._Retail = false;
      this._Restaurant = false;
      this._MainHeader = false;
    }
    else {

      this._Retail = false;
      this._Restaurant = false;
      this._MainHeader = true;
      this._Hardware = false;
    }
  }


}

