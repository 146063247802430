import {EventEmitter, Injectable, OnChanges, Output} from "@angular/core";
import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {Router} from "@angular/router";

// @Directive({
//   selector: '[imagePipe]'
// })
// export class ImageDirective implements AfterViewInit {

//   @Input() src;
//   @Input('defaultSrc') defaultSrc;

//   constructor(private imageRef: ElementRef) {
//   }

//   ngAfterViewInit(): void {

//     const img = new Image();
//     img.onload = () => {
//       this.setImage(this.src);
//     };

//     img.onerror = () => {
//       if (this.defaultSrc) {
//         this.setImage(this.defaultSrc);
//       } else {
//         this.setImage('assets/media/placeholder.png');
//       }
//     };

//     img.src = this.src;
//   }

//   private setImage(src: string) {
//     this.imageRef.nativeElement.setAttribute('src', src);
//   }
// }

@Directive({
  selector: '[imagePipe]',
})
export class ImageDirective implements AfterViewInit, OnChanges {

  @Input() src;
  @Input('defaultSrc') defaultSrc;

  constructor(private imageRef: ElementRef) {
  }

  ngOnChanges(changes: any) {
    this.onSetImage();
  }
  ngAfterViewInit(): void {
    this.onSetImage();
  }

  onSetImage() {
    setTimeout(() => {
      const img = new Image();

      img.onload = () => {
        this.setImage(this.src);
      };

      img.onerror = () => {
        if (this.defaultSrc) {
          this.setImage(this.defaultSrc);
        } else {
          this.setImage('assets/media/placeholder.png');
        }
      };

      img.src = this.src;
    });
  }

  private setImage(src: string) {

    this.imageRef.nativeElement.setAttribute('src', src);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  @Output() onMappingRoute: EventEmitter<any> = new EventEmitter<any>();
  constructor(public  svrRoute: Router) {
  }

  public newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:one-variable-per-declaration no-bitwise
      const r = Math.random() * 16 | 0,
        // tslint:disable-next-line:no-bitwise
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public currentRoute(url: string): string {
    const routeState = url.split('/page');
    let route = '/';
    if (routeState && routeState.length > 1) {
      route = 'page' + routeState[1];
    }
    return route;
  }

  public swapArray(arr, index1, index2) {
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
  }
}
