import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="T_O-user-panel" fxLayout="column" fxLayoutAlign="center center">
      <img
        class="T_O-user-panel-avatar"
        src="assets/images/avatars/avatar-1.jpg"
        alt="avatar"
        width="64"
      />

      <h4 *ngIf='!toggleChecked' class="T_O-user-panel-name">T.O Software</h4>
      <h5 *ngIf='!toggleChecked' class="T_O-user-panel-email">software_developement@gmail.com</h5>
     
    </div>
  `,
})
export class UserPanelComponent {
  @Input() toggleChecked = false;
  @Input() showToggle = false;
}
