import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Header_Action_Type,HeaderSuccess} from '../action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {OperationService} from "@core";
import {clsHeaders} from "../models/clsModels";
@Injectable()
export class HeaderEffect {
  constructor(
    private actions: Actions,
    private srvOperation: OperationService
  ) {
  }

  @Effect()
  LOAD_INITIALIZE = this.actions.pipe(
    ofType(Header_Action_Type.HeaderInitial),
    switchMap(() => {
      return this.srvOperation.loadHeader().pipe(
        map((db: clsHeaders) => new HeaderSuccess(null)),
        catchError(error => of())
      );
    })
  );
}
