
import { Action } from '@ngrx/store';
import * as TOSoftwareStore from '../models/clsModels';
export enum  CollectionItem_Action_Type {
    CollectionItemInitial = 'CollectionItemInitial',
    CollectionItemInitSuccess= 'CollectionItemInitSuccess',
    CollectionItemUpdate= 'CollectionItemUpdate',
    CollectionItemRemove= 'CollectionItemRemove',
    CollectionItemAdd= 'CollectionItemAdd',
}
export class CollectionItemInitial implements Action {
    readonly type = CollectionItem_Action_Type.CollectionItemInitial;
    constructor() {
    }
}
export class CollectionItemUpdate implements Action {
    readonly type = CollectionItem_Action_Type.CollectionItemUpdate;
    constructor(public payload: TOSoftwareStore.ClsCollectionItem) {
    }
}
export class CollectionItemRemove implements Action {
    readonly type = CollectionItem_Action_Type.CollectionItemRemove;
    constructor(public payload: TOSoftwareStore.ClsCollectionItem) {
    }
}
export class CollectionItemAdd implements Action {
    readonly type = CollectionItem_Action_Type.CollectionItemAdd;
    constructor(public payload: TOSoftwareStore.ClsCollectionItem) {
    }
}
export class CollectionItemInitSuccess implements Action {
    readonly type = CollectionItem_Action_Type.CollectionItemInitSuccess;
    constructor(public payload: TOSoftwareStore.ClsCollectionItem[]) {
    }
}

export type ActionsCollectionItem = CollectionItemInitial | CollectionItemInitSuccess |CollectionItemUpdate | CollectionItemRemove | CollectionItemAdd ;
