import { Component } from '@angular/core';




@Component({
  selector: 'data-not-found',
  templateUrl: './ctl_data-not-found.component.html',
  styleUrls: ['./ctl_data-not-found.component.scss']
})

export class CtlDataNotFoundComponent {



}
