import {Component, OnInit} from '@angular/core';
import {clsCollection} from '../../../../Store/models/clsModels';
import {InitializeService} from '@core';
import {CtlCollectionComponent} from '../collection/collection.component';

@Component({
  selector: 'app-ctl-item-product',
  templateUrl: 'feed-item-product.component.html',
  styleUrls: ['./feed-item-product.component.scss']
})
export class CtlFeedItemProductComponent extends CtlCollectionComponent implements OnInit {
  constructor(initialize: InitializeService) {
    super(initialize);
  }
   ngOnInit(): void {
    this.collectionLevel =1;
    this.collectionIndex =-1;
     this.collections = this.initialize.getDatByFeedId(this.feed.type, this.feed.feedid) as clsCollection[];
   }
}

