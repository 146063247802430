import { Component, Input } from '@angular/core';




@Component({
  selector: 'ToggleEdit',
  templateUrl: './ctl-toggle-edit.component.html',
  styleUrls: ['./ctl-toggle-edit.component.scss']
})

export class CtlToggleEditComponent {

 @Input() checked = false;
 @Input() label: string;

}
