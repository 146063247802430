import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-frm-message-box',
  templateUrl: './frm-message-box.component.html',
  styleUrls: ['./frm-message-box.component.scss'],
  animations: [
    trigger('onShowMsg', [
      transition('void => *', [
        style({ opacity: 0.3 }),
        animate(100, style({ opacity: 1 })),
      ]),
    ])],
})
export class FrmMessageBoxComponent implements OnInit, OnDestroy {
  private _interval: any;
  @Input() isShow: boolean = false;
  @Input() lblMsg: string = 'Do you want to discard?';
  @Input() lblTitle: string = 'Confirmation';
  @Input() lblNegative: string = 'Cancel';
  @Input() lblPositive: string = 'OK';
  @Input() lblNuetral: string = 'OK';
  @Input() isShowNuetral: boolean = false;
  @Input() isShowCancel: boolean = false;
  @Input() isUseTick: boolean = false;
  @Input() tickCount: number = 0;

  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNuetral: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    /*if (this.isUseTick) {
      const Itv = setInterval(() => {
        this.tickCount--;
        if (this.tickCount <= 0) {
          this.onOK.emit();
          clearInterval(Itv);
        }
      }, 1000);
    }*/

  }

  ngOnInit() {
    if (this.isUseTick) {
      this._interval = setInterval(() => {
        this.tickCount--;
        if (this.tickCount <= 0) {
          this.onOK.emit();
          clearInterval(this._interval);
        }
      }, 1000);
    }
  }

  onOKClick() {
    this.onOK.emit();
    this.isShow = false;
  }

  onCancelClick() {
    this.onCancel.emit();
    this.isShow = false;
  }

  onNuetralClick() {
    this.onNuetral.emit();
    this.isShow = false;
  }

  ngOnDestroy() {
    clearInterval(this._interval);
  }

  KEY_F5 = 116;
  CTRLR = 82;

  @HostListener('window:keydown', ['$event']) onKeyDown(event) {
    if (this.isUseTick) {
      if (event.keyCode === this.KEY_F5 || event.keyCode == this.CTRLR) {
        event.preventDefault();
        return false;
      }
    }
  }
}
