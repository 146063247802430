import { Component, Input } from '@angular/core';




@Component({
  selector: 'PictureBox',
  templateUrl: './ctl-picture-box.component.html',
  styleUrls: ['./ctl-picture-box.component.scss']
})

export class CtlPictureBoxComponent {

 @Input() src: any;
 @Input() label: string;
 @Input() style: any;

}
