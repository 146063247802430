import {Component, OnInit, OnDestroy} from '@angular/core';


const MOBILE_MEDIAQUERY = 'screen and (max-width: 599px)';
const TABLET_MEDIAQUERY = 'screen and (min-width: 600px) and (max-width: 959px)';
const MONITOR_MEDIAQUERY = 'screen and (min-width: 960px)';

@Component({
  selector: 'app-website-layout',
  templateUrl: 'website-layout.component.html',
  styleUrls: ['./website-layout.component.scss'],
})
export class WebsiteLayoutComponent  implements OnInit, OnDestroy {
  // @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;
  // @ViewChild('content', {static: true}) content: MatSidenavContent;

  // options = this.settings.getOptions();

  // private layoutChanges: Subscription;
  //
  // private isMobileScreen = false;
  //
  // get isOver(): boolean {
  //   return this.isMobileScreen;
  // }

  // private contentWidthFix = true;
  //
  // @HostBinding('class.T_O-content-width-fix') get isContentWidthFix() {
  //   return (
  //     this.contentWidthFix &&
  //     this.options.navpos === 'side' &&
  //     this.options.issidenavopen &&
  //     !this.isOver
  //   );
  // }

  // private collapsedWidthFix = true;
  //
  // @HostBinding('class.T_O-sidenav-collapsed-fix') get isCollapsedWidthFix() {
  //   return (
  //     this.collapsedWidthFix &&
  //     (this.options.navpos === 'top' || (this.options.issidenavopen && this.isOver))
  //   );
  // }
  //
  // // Demo purposes only
  // @HostBinding('class.theme-dark') get themeDark() {
  //   return this.options.theme === 'dark';
  // }
  //
  // constructor(svrChange: ChangeDetectorRef,
  //             store: Store<TOSoftwareStore.TOSoftwareState>,
  //             svrRoute: Router,
  //             fb: FacebookService,
  //             svrOpt: OperationService,
  //             svrStorage: LocalStorage,
  //             navService: NavService,
  //             svrInit: InitializeService,
  //             svrData: OperationData) {
  //   super(svrChange, store, svrRoute, fb, svrOpt, svrStorage, navService,svrInit,svrData);
  // }

  // constructor(
  //   private router: Router,
  //   private breakpointObserver: BreakpointObserver,
  //   private overlay: OverlayContainer,
  //   private menuService: MenuService,
  //   private settings: SettingsService,
  //   public dialog: MatDialog,
  //   public store: Store<TOSoftwareStore.TOSoftwareState>
  // ) {
  //   // Set dir attr on body
  //   // this.store.dispatch(new NavMenuInitial());
  //   document.body.dir = this.options.dir;
  //
  //   this.layoutChanges = this.breakpointObserver
  //     .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY])
  //     .subscribe(state => {
  //       // issidenavopen must be reset true when layout changes
  //       this.options.issidenavopen = true;
  //
  //       this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
  //       this.options.issidenavcollapse = state.breakpoints[TABLET_MEDIAQUERY];
  //       this.contentWidthFix = state.breakpoints[MONITOR_MEDIAQUERY];
  //     });
  //   // TODO: Scroll top to container
  //   this.router.events.subscribe(evt => {
  //     if (evt instanceof NavigationEnd) {
  //       this.content.scrollTo({ top: 0 });
  //     }
  //   });
  // }
  // @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
  //   let result = confirm('Changes you made may not be saved.');
  //   if (result) {
  //     // Do more processing...
  //   }
  //   event.returnValue = false; // stay on same page
  // }
  ngOnInit() {

    // setTimeout(() => (this.contentWidthFix = this.collapsedWidthFix = false));

  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(EditorComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  ngOnDestroy() {
    // this.layoutChanges.unsubscribe();
  }
  //
  // toggleCollapsed() {
  //   this.options.issidenavcollapse = !this.options.issidenavcollapse;
  //   this.resetCollapsedState();
  // }

  // resetCollapsedState(timer = 400) {
  //   // TODO: Trigger when transition end
  //   setTimeout(() => {
  //     this.settings.setNavState('collapsed', this.options.issidenavcollapse);
  //   }, timer);
  // }

  // sidenavCloseStart() {
  //   this.contentWidthFix = false;
  // }

  // issidenavopenChange(isOpened: boolean) {
  //   this.options.issidenavopen = isOpened;
  //   this.settings.setNavState('opened', isOpened);
  //
  //   this.collapsedWidthFix = !this.isOver;
  //   this.resetCollapsedState();
  // }

  // Demo purposes only
  // receiveOptions(options: AppSettings): void {
  //   this.options = options;
  //   this.setTheme(options);
  //   this.setBodyDir(options);
  // }

  // setTheme(options: AppSettings) {
  //   if (options.theme === 'dark') {
  //     this.overlay.getContainerElement().classList.add('theme-dark');
  //   } else {
  //     this.overlay.getContainerElement().classList.remove('theme-dark');
  //   }
  // }

  // setBodyDir(options: AppSettings) {
  //   if (options.dir === 'rtl') {
  //     document.body.dir = 'rtl';
  //   } else {
  //     document.body.dir = 'ltr';
  //   }
  // }
}
