import { ActionReducerMap, createSelector, createFeatureSelector } from '@ngrx/store';

import * as InitState from './rdc-init.reducer';
import * as HeaderState from './rdc-header.reducer';
import * as NavMenuState from './rdc-navmenu.reducer';
import * as MenuEditorState from './rdc-menu-editor.reducer';
import * as CollectionState from './rdc-collection.reducer';
import * as CollectionItemState from './rdc-collectionItem.reducer';

export interface TOSoftwareState {
    init: InitState.Init;
    header: HeaderState.Header;
    navMenu: NavMenuState.NavMenu[] ;
    collection: CollectionState.Collection[];
    collectionItem: CollectionItemState.CollectionItem[];
    menuEditor: MenuEditorState.MenuEditor;
  }

export const reducers: ActionReducerMap<TOSoftwareState> = {
    init: InitState.initReducer,
    header: HeaderState.headerReducer,
    navMenu: NavMenuState.navMenuReducer,
    collection: CollectionState.CollectionReducer,
    collectionItem: CollectionItemState.CollectionItemReducer,
    menuEditor: MenuEditorState.menuEditorReducer



};
export const getTOSoftwareState = createFeatureSelector<TOSoftwareState>('toSoftware');

//Initail
const initState = createSelector(getTOSoftwareState, (state: TOSoftwareState) => state.init);

export const getAllInit = createSelector(initState, InitState.getInitData);


//Header
const headerState = createSelector(getTOSoftwareState, (state: TOSoftwareState) => state.header);

export const getHeader = createSelector(headerState, HeaderState.getHeaderData);

//Nav menu


/*const navMenuState = createSelector(getTOSoftwareState, (state: TOSoftwareState) => state.navMenu);
export const getNavMenu = createSelector(navMenuState, NavMenuState.getNavData);*/


//Collection

const collectionState = createSelector(getTOSoftwareState, (state: TOSoftwareState) => state.collection);
export const getCollection = createSelector(collectionState, CollectionState.getCollectionData);



//CollectionItem

const collectionItemState = createSelector(getTOSoftwareState, (state: TOSoftwareState) => state.collectionItem);
export const getCollectionItem = createSelector(collectionItemState, CollectionItemState.getCollectionItemData);

//Menu Editor
export const menuEditorItemState = createSelector(getTOSoftwareState, (state: TOSoftwareState) => state.menuEditor);
export const getMenuEditor = createSelector(menuEditorItemState, MenuEditorState.getMenuEditorData);
