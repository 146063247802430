import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';


import { Injectable } from '@angular/core';

import { StartupService } from '@core';


@Injectable({
  providedIn: 'root',
})

@Injectable()
export class SvrStartUpResolver implements Resolve<any> {
  constructor(private svrStartUp: StartupService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    console.log("===>start up")
    return this.svrStartUp.ongetMenu();
  }
}


