import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';


@Component({
  selector: 'FormTab',
  templateUrl: './ctl_form-tab.component.html',
  styleUrls: ['./ctl_form-tab.component.scss'],
})

export class CtlFormTabComponent {
  @Input() headerTitle: string;
  @Input() subtitle: string;
  @Input() isForm: boolean;
  @Input() showLeftDivider: boolean = true;
  @Input() noTitle: boolean;
  @Input() tabItem: TemplateRef<any>[];
  @Input() tabItemHeader: string[];

  @ContentChild('tab', { static: false }) tabTemplateRef: TemplateRef<any>;
  @Output() EventTabChange:EventEmitter<number> = new EventEmitter<number>();

  current_tab: number = 0;
  constructor() {
  }
  onTabChange(val) {
    this.current_tab = val.index;
    this.EventTabChange.emit(val.index);

  }

  /**
   * Set Tab from external source
   * @Note Currently use in Quote, Sale Order, Sale Invoice
   */
  onSetTab(tabindex: number = 0) {
    if (this.current_tab != tabindex) {
      this.current_tab = tabindex;
    }
  }
}
