import {Component, Input, OnInit} from '@angular/core';
import {clsNan} from "../../../../Class/svr-operation.service";
import {LCcustomerinfo} from "../../../../Model/clsCMModel";
import {svrProduct} from "../../../../Controller/svrProduct";
import {clsitemmaster} from "../../../../Model/clsICModule";

@Component({
  selector: 'app-frm-upgrade-package',
  templateUrl: './frm-upgrade-package.component.html',
  styleUrls: ['./frm-upgrade-package.component.css']
})
export class FrmUpgradePackageComponent implements OnInit {
  @Input() data : LCcustomerinfo = new LCcustomerinfo();
  objFormData: {
    objLicense: LCcustomerinfo,
    objitem :clsitemmaster
  } = {
    objLicense: new LCcustomerinfo(),
    objitem : new clsitemmaster()
  }
  constructor(public svrPro: svrProduct) { }
  Config = {
    displayKey: 'itemdesc', // if objects array passed which key to be displayed defaults to description
    search: false,
    limitTo: 3,
    height: '260px',
  }
  options: any = []
  async ngOnInit() {
    let ispack = true;
    let dataPack = await this.svrPro.onGetTotalPro(ispack)
    if (dataPack.length > 0){
      this.options = dataPack;
      this.objFormData.objitem = dataPack.find(e=>e.itemid==this.data.erptype);
    }
    if (!clsNan.isNullOrUndefined(this.data)) {
      this.objFormData.objLicense = this.data;
    }
   /* let ispack = true;
    let dataPack = await this.svrPro.onGetTotalPro(ispack)
    if (dataPack.length > 0){
      this.options = dataPack;
      this.objFormData.objitem = dataPack.find(e=>e.itemid==this.data.erptype);
    }
    if (!clsNan.isNullOrUndefined(this.data)) {
      this.objFormData.objLicense = this.data;
    }*/
  }
  dochangePackage(e) {
    this.objFormData.objLicense.erptype = e.itemid
  }
}
