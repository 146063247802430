import {Component, OnInit, AfterViewInit, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CtlYoutubeVdoComponent} from '../home/youtube-video/youtube-video.component';
import {IBlog, IFeed} from '../../../../Store/intity/IModels';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {InitializeService} from "@core";
import {GlobalService} from "@core/services/global.service";

@Component({
  selector: 'app-ctl-feed-blog',
  templateUrl: './feedblog.component.html',
  styleUrls: ['./feedblog.component.scss'],


})
export class CtlFeedBlogComponent implements OnInit, AfterViewInit {

  blogs: IBlog[];
  @Input() feed: IFeed;
  sliderOption: OwlOptions;

  constructor(
    public  initialize: InitializeService,
    public  global: GlobalService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.sliderOption = {
        loop: true,
        lazyLoad: true,
        autoplay: false,
        stagePadding: 4,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        autoplaySpeed: 4000,
        dotsSpeed: 500,
        responsive: {
          0: {
            items: 1
          }
        }
        // responsive: {
        //   0: {
        //     items: 1,
        //     dots: false
        //   },
        //   400: {
        //     items: 2,
        //     dots: false
        //   },
        //   740: {
        //     items: 3
        //   },
        //   940: {
        //     items: 4
        //   },
        //   1280: {
        //     items: 5,
        //   },
        //   1920: {
        //     items: 6,
        //   },
        // }
      };
    }, 300);
    this.blogs = this.initialize.getDatByFeedId(this.feed.type, this.feed.feedid) as IBlog[];
  }

  openDialog(e): void {

    const dialogRef = this.dialog.open(CtlYoutubeVdoComponent, {
      width: 'auto',

      data: {name: e}

    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }
  onRouteMapping(route) {
    if(route){
      this.global.onMappingRoute.emit(route);
    }
  }

  ngAfterViewInit() {

  }

}

