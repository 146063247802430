import { Injectable, Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'ppNavigationPath',
  pure: true,
})
export class PpNavigationPathPipe implements PipeTransform {
  @memo()
  transform(value: any[], ...args: any[]): string {
    let state = '';
    value.forEach(item => {
      const itm = item ?? '';
      if (itm && itm.trim()) {
        state += '/' + itm.replace(/^\/+|\/+$/g, '');
      }
    });
    return state;
  }

}
