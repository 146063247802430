import {Component, OnInit, AfterViewInit} from '@angular/core';
import {InitializeService} from "@core";
import * as _ from "lodash";
import {Router} from "@angular/router";
import {GlobalService} from "@core/services/global.service";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class FrontComponent implements OnInit, AfterViewInit {

  // headers: clsHeaders = this.initialize.headers;
  // feeds: IFeed[];

  constructor(public initialize: InitializeService) {
    // this.headers = this.initialize.headers;
    // this.headers = this.initialize.getHeaders('/', 0) as clsHeaders;


  }

  onMappingRoute(route) {
    // this.initialize.route = route;
    // this.initialize.getHeaders(route, 0);
    // this.initialize.getFeedByRoute(route, 0);
    //
    // const states = this.route.url.slice(1).split('/');
    // if (_.includes(states, 'console')) {
    //
    //   this.route.navigate([
    //     '/console/view/' + route
    //   ]);
    //
    // } else {
    //   this.route.navigate([route]);
    // }
  }

  ngOnInit() {


  }

  ngAfterViewInit(): void {

  }

}
