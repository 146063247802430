import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  FormControl,
} from '@angular/forms';


@Component({
  selector: 'TextEdit',
  templateUrl: './ctl-text-edit.component.html',
  styleUrls: ['./ctl-text-edit.component.scss'],

})
export class CtlTextEditComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() public uppercase: boolean = false;
  @Input() public disabled: boolean;
  @Input() public label: string;
  @Input() public readonly: boolean = false;
  @Input() public placeholder: string = '';
  @Input() public type: 'text' | 'email' | 'password' | 'number' = 'text';
  @Input() public prefix: null;
  @Input() public suffix: null;
  @Input() public value: any = '';
  @Input() public required: boolean = false;
  @Input() public minlength = 0;
  @Input() public maxlength: number = 999;
  @Input() public row: number = 10;
  @Input() public lengthError: string = 'The characters should not be less than ';
  @Input() public maxLengthError: string = 'Maximum length ';
  @Input() public prefixIcon: boolean = false;
  @Input() public prefixSvg: boolean = false;
  @Input() public suffixIcon: boolean = false;
  @Input() public suffixSvg: boolean = false;
  @Input() public focused: boolean = false;
  @Input() public formControl: FormControl;
  @Input() public matAutocomplete: string = 'off';
  @Input() public customError: string = null;
  @Input() public minNumber: number = 0;
  @Input() public maxNumber: number = -1;
  @Output() public onSuffixClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onPrefixClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() public isRTF: boolean = false;
  @Input() public isMultipleLine: boolean = false;
  @Input() public isDigit: boolean = false;
  @Input() public id: string = "";
  @Input() public autocomplete: string = "nope";
  @Input() public name: string = "";
  @Input() public suffixLabel: string = "";
  @Input() public suffixLabelClass: any;
  public errorMessages = new Map<string, () => string>();
  @Input() public isshowlabel  :boolean = true;


  quillModule = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'direction': 'rtl'}],                         // text direction

      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': []}],
      [{'align': []}],

      ['clean'],                                         // remove formatting button
      ['image']
      //['link', 'image', 'video']                         // link and image, video
    ]
  };


  //@ViewChild('ctlHtmlEditor',{static:false})ctlHtmlEditor:Editor;
  // value: any = '';

  constructor(
    // Retrieve the dependency only from the local injector,
    // not from parent or ancestors.
    @Self()
    // We want to be able to use the component without a form,
    // so we mark the dependency as optional.
    @Optional()
    private ngControl: NgControl,
    private elref: ElementRef,
    public element: ElementRef<HTMLElement>,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.errorMessages.set('required', () => `${this.label} ${'required'}`);
    this.errorMessages.set('minlength', () => `${this.lengthError}${this.minlength}.`);
    this.errorMessages.set('maxlength', () => `${this.maxLengthError}${this.maxlength}.`);

console.log('label',this.label)
  }

  ctlQuill: any;

  onQuillEditorCreate(e) {
    this.ctlQuill = e;

    console.log(e);
  }

  public doRTFEditorChange(e) {
    this.onChange(e);

  }

  public get invalid(): boolean {

    return this.ngControl ? (this.ngControl.invalid || this.customError != null) : false;
  }

  public get showError(): boolean {
    if (!this.ngControl) {
      return false;
    }

    const {dirty, touched} = this.ngControl;

    return this.invalid ? (dirty || touched) : false;
  }

  ngAfterViewInit(): void {
    // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.


    if (this.focused) {
      const doc = document.body.getBoundingClientRect();
      if (doc.width > 1024) {
        this.doFocus();
      }

      // setTimeout(() => this.element.nativeElement.focus(), 0);
    }
  }

  onFocus(e: FocusEvent) {
    this.doFocus();
    // if(this.type == "number") {
    //   console.log("Current focus----->",e);

    //   this.doFocus();
    // }

  }

  public doFocus() {
    let txtInput = this.elref.nativeElement.querySelector('input') as HTMLInputElement;
    if (this.type == 'number') {
      txtInput.type = 'text';
    }

    txtInput.setSelectionRange(0, txtInput.value.length, 'forward');
    txtInput.focus();
    if (this.type == 'number') {
      txtInput.type = this.type;
    }
  }

  public get errors(): Array<string> {
    if (!this.ngControl) {
      return [];
    }

    const {errors} = this.ngControl;
    return Object.keys(errors).map(key => this.errorMessages.has(key) ? this.errorMessages.get(key)() : <string>errors[key] || key);

  }

  ngOnInit() {
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  public onChange(e) {
    this.value = e;
  }

  public onTouched() {
  }
}
