import { Component, Input, Output, EventEmitter } from '@angular/core';




@Component({
  selector: 'buttonEdit',
  templateUrl: './ctl-button.component.html',
  styleUrls: ['./ctl-button.component.scss']
})

export class CtlButtonComponent {
 
  @Input() label: string;
  @Input() class: any;
  @Input() style: any;
  @Input() icon: string;
  @Output() public addClick: EventEmitter<any> = new EventEmitter();

  onAddClick($e) {
 
      this.addClick.emit($e);

  }

}
