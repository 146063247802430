import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-translate',
  template: `
    <button mat-icon-button class="T_O-toolbar-button" [matMenuTriggerFor]="menu">
      <mat-icon>translate</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="switchLanguage('to_ca')">
        <div fxFlex="row wrap" fxLayoutAlign="center center">
          <img style="height: 2vh;width: 2vh;" src="assets/country_icon/kh.png">
          <span class="flag-icon flag-icon-cn">
          </span>
          <span>ភាសាខ្មែរ</span>
        </div>
      </button>

      <button mat-menu-item (click)="switchLanguage('to_en')">
        <div fxFlex="row wrap" fxLayoutAlign="center center">
          <img style="height: 2vh;width: 2vh;" src="assets/country_icon/en.png">
          <span class="flag-icon flag-icon-cn">
          </span>
          <span>English</span>
        </div>
      </button>
    </mat-menu>
  `,
  styles: [
      `
      .flag-icon {
        margin-right: 8px;
      }
    `,
  ],
})
export class TranslateComponent {

  constructor(private translateService: TranslateService) {
/*     console.log('====>' + this.translateService.instant('Dashboard'));
      this.translateService.get('Dashboard').subscribe((res: any) => {
        console.log('====>' + res);
      });*/

  }


  switchLanguage(language: string) {
        this.translateService.use(language);
        localStorage.setItem('lang', language);
  }
}
