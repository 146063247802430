import {Component, Input, EventEmitter, Output, HostListener, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

@Component({
  selector: 'MenuButton',
  templateUrl: './ctl-menu-button.component.html',
  styleUrls: ['./ctl-menu-button.component.scss'],
})

export class CtlMenuButtonComponent implements ControlValueAccessor {
  @ViewChild('dropdown', {static: false}) dropdownContainer: ElementRef;
  /**
   * Menu Button Input item.
   */
  @Input() items: any[] = [];

  /**
   * Event on selected Change item.
   */
  @Output() onSelectChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Event on selected item.
   */
  @Output() public onSelected: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Event emitted when dropdown is open.
   */
  @Output() public open: EventEmitter<any> = new EventEmitter();

  /**
   * Event emitted when dropdown is open.
   */
  @Output() public close: EventEmitter<any> = new EventEmitter();
  /**
   * variable to track if clicked inside or outside of component
   */
  public clickedInside: boolean = false;
  /**
   * Toogle the dropdown list
   */
  public toggleDropdown: boolean = false;
  private toggleClick: boolean;

  @Input() public autoCloseOnItemClick = false;


  constructor(private render: Renderer2) {
  }

  /**
   * click listener for host inside this component i.e
   * if many instances are there, this detects if clicked inside
   * this instance
   */
  @HostListener('click')
  public clickInsideMenu() {
    this.clickedInside = true;
  }

  /**
   * click handler on documnent to hide the open dropdown if clicked outside
   */
  @HostListener('document:click')
  public clickOutsideMenu() {
    if (!this.clickedInside) {
      this.toggleDropdown = false;
      this.close.emit();
    }
    if (this.toggleClick) {

      const cdkScrollable = document.getElementsByTagName('cdk-virtual-scroll-viewport');

      if (cdkScrollable && cdkScrollable.length > 0) {
        /*cdkScrollable.item(0).setAttribute('style', '');*/
        this.render.setAttribute(cdkScrollable.item(0), 'style', '');
      }
      this.toggleClick = false;

    }
    this.clickedInside = false;
  }

  public onRowSelected(row) {
    this.onOptionClick(row);
  }

  public toggleSelectDropdown() {
    this.toggleDropdown = !this.toggleDropdown;
    if (this.toggleDropdown) {
      this.open.emit();
    } else {
      this.close.emit();
    }
    setTimeout(() => {
      const cdkScrollable = document.getElementsByTagName('cdk-virtual-scroll-viewport');
      // const cdkScrollable = document.getElementsByClassName('.ui-virtual-scroll') as HTMLCollection;
      const buttonDropdown = this.dropdownContainer.nativeElement.querySelector('.icon-menu') as HTMLInputElement;
      const dropdownRec = this.dropdownContainer.nativeElement.querySelector('.dd-menu') as HTMLDivElement;


      const doSetStyle = (el: any, top: number, width: number, right: number) => {
        this.render.setStyle(el, 'z-index', '9999');
        // this.render.setStyle(el, 'position', 'fixed');
        this.render.setStyle(el, 'margin-top', `-${top}px`);
        this.render.setStyle(el, 'max-width', `${width}px`);
        this.render.setStyle(el, 'min-width', `${width}px`);
        // this.render.setStyle(el, 'left', `auto`);
        // this.render.setStyle(el, 'right', `${-(right - width)}px`);
      };

      if (this.dropdownContainer !== null && buttonDropdown !== null && dropdownRec != null) {
        const buttonDropdownRec = buttonDropdown.getBoundingClientRect();
        const docRec = document.body.getBoundingClientRect();
        const clientTop = this.offset(dropdownRec).top;
        const isTable = this.dropdownContainer.nativeElement.offsetParent.childNodes[0];
        if (clientTop + dropdownRec.offsetHeight + buttonDropdown.offsetHeight + 10 >= docRec.height) {

          const top = dropdownRec.offsetHeight + 20;
          doSetStyle(dropdownRec, top, dropdownRec.offsetWidth, dropdownRec.offsetLeft);
          // setTimeout(() => {
          //   if (isTable.tagName === 'TABLE') {
          //
          //     if (cdkScrollable && cdkScrollable.length > 0) {
          //       cdkScrollable.item(0).setAttribute('style', 'overflow: unset !important');
          //       this.toggleClick = true;
          //     }
          //   }
          // });
          /*dropdownRec.setAttribute('style', 'z-index:auto; margin-top:-' + top + 'px');
          dropdownRec.style.maxWidth = dropdownRec.style.minWidth = buttonDropdownRec.width + 'px';*/

        } else {

          if (isTable.tagName === 'TABLE') {
            if (clientTop + dropdownRec.offsetHeight + buttonDropdown.offsetHeight + 10 >= docRec.height) {
              const top = dropdownRec.offsetHeight + 45;
              doSetStyle(dropdownRec, top, dropdownRec.offsetWidth, dropdownRec.offsetLeft);

              /*dropdownRec.setAttribute('style', 'z-index:999; margin-top:-' + top + 'px');
              dropdownRec.style.maxWidth = dropdownRec.style.minWidth = buttonDropdownRec.width + 'px';*/
            }
          }
        }

        /*dropdownRec.style.minWidth = '200px';*/
        this.render.setStyle(dropdownRec, 'min-width', '200px');
      }
    }, 0);


  }

  private innerValue: string | number | boolean;

  get value(): string | number | boolean {
    return this.innerValue;
  }

  set value(v: string | number | boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.change(v);
    }
  }

  onChange: any;
  onTouched: any;

  writeValue(value: string | number | boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value: string | number | boolean) {
    this.innerValue = value;
    this.onChange(value);
    this.onTouched(value);
    this.onSelectChange.emit();

  }

  public offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
  }

  onClose(): boolean {

    return this.toggleDropdown;
  }

  onOptionClick(val) {
    this.onSelected.emit(val);
    if (this.autoCloseOnItemClick) {
      this.toggleDropdown = false;
    }
  }


}
