import { Component, Input } from '@angular/core';




@Component({
  selector: 'FormPreview',
  templateUrl: './ctl_form-preview.component.html',
  styleUrls: ['./ctl_form-preview.component.scss']
})

export class CtlFormPreviewComponent {
 
@Input() isForm: boolean;


}
