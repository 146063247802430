
import { Action } from '@ngrx/store';
import * as TOSoftwareStore from '../models/clsModels';
export enum  MenuEditor_Action_Type {
    MenuEditorInitial = 'MenuEditorInitial',
    MenuEditorInitSuccess= 'MenuEditorInitSuccess',
    MenuEditorUpdate= 'MenuEditorUpdate',
    MenuEditorRemove= 'MenuEditorRemove',
    MenuEditorAdd= 'MenuEditorAdd',
}
export class MenuEditorInitial implements Action {
    readonly type = MenuEditor_Action_Type.MenuEditorInitial;
    constructor(public menuId: number) {
    }
}
export class MenuEditorUpdate implements Action {
    readonly type = MenuEditor_Action_Type.MenuEditorUpdate;
    constructor(public menuId: number, public payload: TOSoftwareStore.ClsChildrenItem) {
    }
}
export class MenuEditorRemove implements Action {
    readonly type = MenuEditor_Action_Type.MenuEditorRemove;
    constructor(public menuId: number, public payload: TOSoftwareStore.ClsChildrenItem) {
    }
}
export class MenuEditorAdd implements Action {
    readonly type = MenuEditor_Action_Type.MenuEditorAdd;
    constructor(public menuId: number, public payload: TOSoftwareStore.ClsChildrenItem) {
    }
}
export class MenuEditorInitSuccess implements Action {
    readonly type = MenuEditor_Action_Type.MenuEditorInitSuccess;
    constructor(public items: TOSoftwareStore.ClsChildrenItem[]) {
    }
}
export type ActionsMenuEditor = MenuEditorInitial | MenuEditorInitSuccess |MenuEditorUpdate | MenuEditorRemove | MenuEditorAdd ;
