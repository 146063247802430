export class LCUser {
  username: string; // user name
  userid: number; // user id
  usercode: string; // user code
  islogin: boolean; //is log in
  addmachine: string;// add machine
  edtmachine: string;// edit machine
  password: string;// password
  confirm: string;// confirm
  active: boolean;// active
  contact: string;// Contact
  fname: string; // first name
  lname: string;// last name
  gender: string;// gender
  phone: string; // Phone
  roleid?: number;// role id
  token?: string

  constructor(usercode?: string, userid?: number, username?: string, contact?: string, password?: string,
              confirm?: string, fname?: string, lname?: string, active?: true, gender?: string, phone?: string, roleid?: number) {
    this.usercode = usercode ?? '';
    this.userid = userid ?? 0;
    this.username = username ?? '';
    this.contact = contact ?? '';
    this.password = password ?? '';
    this.confirm = confirm ?? '';
    this.fname = fname ?? '';
    this.lname = lname ?? '';
    this.active = active ?? true;
    this.gender = gender;
    this.phone = phone ?? '';
    this.roleid = roleid ?? 0;
  }

//   public long userId { get; set; }
// public string companyName { get; set; }
// public string domain { get; set; }
// public string fname { get; set; }
// public string lname { get; set; }
// public string gender { get; set; }
// public string contact { get; set; }
// public string avatar { get; set; }
// public string email { get; set; }
// public string address { get; set; }
// public string businessCategory { get; set; }
// public string businessOutlet { get; set; }
// public int status { get; set; }
// public UserSecurity userSecurity { get; set; }
}


export class MailConfig {
  name: string;
  email: string;
  outgoing: string;
  username: string;
  password: string;
  logAuth: boolean;
  host: string;
  userid: number;
  required: boolean;
  expiredday: number;

  constructor(_name?: string, _email?: string, outgoing?: string, username?: string, password?: string, logAuth?: boolean, host?: string, userid?: number,
              required?: boolean, expired?: number) {

    this.name = _name ?? '';
    this.email = _email ?? '';
    this.outgoing = outgoing ?? '';
    this.username = username ?? '';
    this.password = password ?? '';
    this.host = host ?? '';
    this.userid = userid ?? 0;
    this.logAuth = logAuth ?? true;
    this.required = required ?? true;
    this.expiredday = expired ?? 0;


  }
}

export class LCRole {
  roleid: number;
  rolecode: string;
  roledesc: string;
  roledesc2: string;
  totaluses: number;
  active: boolean;
  userid: number;
  deep: number;
  username: string;
  modules: LCRoleModule[];

  constructor() {
    this.roleid = 0;
    this.rolecode = '';
    this.roledesc = '';
    this.roledesc2 = '';
    this.active = true;
    this.modules = [];
    this.userid = 0;
    this.totaluses = 0;
    this.deep = 0;
    this.username = '';
  }
}

export class LCRoleModule {
  moduleid: number;
  gmoduleid: number;
  moduledesc?: string;
  moduledesc2?: string;
  accesstype: number;
  maxaccess?: number;
  mtypeid?: number;
  submodules?: LCRoleModule[];

  constructor() {
    this.moduleid = 0;
    this.moduledesc = '';
    this.moduledesc2 = '';
    this.accesstype = 1;
    this.submodules = [];
    this.maxaccess = 1;
    this.mtypeid = 0;
  }
}

export class LCModuleType {
  mtypeid: number;
  mtypedesc: string;

  constructor() {
    this.mtypeid = 0;
    this.mtypedesc = '';
  }
}

export class LCCompany {
  compid: number;
  compcode: string;
  compname: string;
  domain: string
  datetimeformat: LCDateFormat;
  numberformat: LCNumberFormat;

  constructor(compid?: number, compcode?: string, compname?: string, domain?: string) {
    this.compid = compid
    this.compcode = compcode
    this.compname = compname
    this.domain = domain
    this.numberformat = new LCNumberFormat();
    this.datetimeformat = new LCDateFormat();

  }
}

export class LCDateFormat {
  dateformatorder: number;
  dateformat: string;
  timeformatorder: number;
  timeformat: string;

  constructor(_dateformatorder: number = 0, _dateformat: string = 'MM/yyyy/dd', _timeformatorder: number = 0, _timeformat: string = 'HH:mm') {
    this.dateformatorder = _dateformatorder;
    this.dateformat = _dateformat;
    this.timeformatorder = _timeformatorder;
    this.timeformat = _timeformat;
  }
}

export class LCNumberFormat {
  qtydecplace: number;
  qtythousandSep: string;
  qtynegpattern: number;

  factordecplace: number;
  factortSep: string;

  percentagedecplace: number;

  constructor(_qtydecplace: number = 2, _qtythousandSep: string = ',', _qtynegpattern: number = 0,
              _factordecplace: number = 2, _factortSep: string = ',', _percentagedecplace: number = 2) {
    this.qtydecplace = _qtydecplace;
    this.qtythousandSep = _qtythousandSep;
    this.qtynegpattern = _qtynegpattern;

    this.factordecplace = _factordecplace;
    this.factortSep = _factortSep;

    this.percentagedecplace = _percentagedecplace;
  }

}
