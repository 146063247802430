import {Component, OnInit, AfterViewInit, Input} from '@angular/core';
import {IFeed} from "../../../../Store/intity/IModels";
import {OwlOptions} from 'ngx-owl-carousel-o';
import * as _ from 'lodash';
import {
  clsCollection,
  clsCollectionDetail,
  clsCollectionGroup,
  clsCollectionTitleRoute
} from "../../../../Store/models/clsModels";
import {InitializeService} from "@core";
import {GlobalService} from "@core/services/global.service";

@Component({
  selector: 'app-ctl-product',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CtlCollectionComponent implements OnInit, AfterViewInit {
  collectionsGroups: clsCollectionGroup[] = [];
  @Input() collections: clsCollection[] = [];
  @Input() collection: clsCollection = new clsCollection();
  collectionIndex: number = -1;
  @Input() itemIndex: number;
  isScrumbarData: clsCollectionTitleRoute = new clsCollectionTitleRoute();
  ClickCollection: any[] = [];
  collectionLevel = 0;
  scrumbar: any[] = [];
  collectionDetailDefualt: clsCollectionDetail = {
    collectionid:0 ,
    collectiondetaildescriptionlist: [],
    collectiondetailthumbnail: [],
    collectiondetaildescription: ''
  };
  // collectionDetail: clsCollectionDetail = new clsCollectionDetail();
  @Input() feed: IFeed;
  sliderOption: OwlOptions ={
    loop: true,
    lazyLoad: true,
    autoplay: false,
    stagePadding: 4,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 4000,
    dotsSpeed: 500,
    responsive: {
      0: {
        items: 1
      }
    }
  };

  constructor(
    public initialize: InitializeService) {
    this.itemIndex = -1;
    // this.initialize.collectionLevel = 0;
    // this.initialize.scrumbar = [];
  }

  ngOnInit() {
    this.collectionsGroups = this.initialize.getDatByFeedId(this.feed.type, this.feed.feedid) as clsCollectionGroup[];
  }

  onCollectionRoute(grpIndex, group: clsCollectionGroup) {
    debugger
    this.isScrumbarData = {collectionid: group.collectiongroupid, collectionname: group.collectiongroupname};
    this.collectionLevel = 1;
    this.collectionIndex = -1;
    this.collections = [...this.initialize.getCollections(group.collectiongroupid)];
    if (!_.includes(this.scrumbar, this.isScrumbarData)) {
      this.scrumbar.push(this.isScrumbarData);
    }
  }

  oncollectiondetailRoute(index: number, collection: clsCollection) {
    this.isScrumbarData = {collectionid: collection.collectionid, collectionname: collection.collectionname};
    this.collectionLevel = 2;
    this.collectionIndex = index;
    setTimeout(() => {
      this.sliderOption = {
        loop: true,
        lazyLoad: true,
        autoplay: false,
        stagePadding: 4,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        autoplaySpeed: 4000,
        dotsSpeed: 500,
        responsive: {
          0: {
            items: 1
          }
        }
      };
    }, 300);
    this.collections[index] = {...collection};
    if (!_.includes(this.scrumbar, this.isScrumbarData)) {
      this.scrumbar.push(this.isScrumbarData);
    }
  }

  onItemProductNavigate() {

    this.collectionLevel=1
    this.collectionIndex = -1;
    this.collection.collectiondetail = {
      ...this.collectionDetailDefualt,
      collectiondetaildescription:'',
      collectiondetaildescriptionlist:[],
      collectiondetailthumbnail:[],
      collectionid:0
    };
   /* this.collection.collectiondetail = {
      ...this.collectionDetailDefualt,
      collectiondetaildescription:'',
      collectiondetaildescriptionlist:[],
      collectiondetailthumbnail:[],
      collectionid:0
    };*/
    this.collection ={...this.collection};
  }
  onNavigate(index: number) {
    this.collectionLevel = index;
    if (index === 0) {
      this.scrumbar = [];
      this.itemIndex = -1;
    } else {
      this.scrumbar.splice(this.scrumbar.length - 1, 1);
      this.collectionIndex = -1;
    }
    this.collection.collectiondetail = {...this.collectionDetailDefualt};
  }

  ngAfterViewInit() {

  }

}

