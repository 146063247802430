import {Injectable} from '@angular/core';
import {clsResponseAccount, clsUserProfile, clsUserSecurity, clsUserSetup} from "../../../Store/models/clsModels";
import {HttpClient} from "@angular/common/http";
import {clsNan, OperationService} from "@core";
import {Email, LCParamStatus, LCPartner, LCRegisterAccount} from "../Model/clsCMModel";
import {ActivatedRoute, Router} from "@angular/router";
import {LCRecord, LCTotalField} from "../../license-app/Model/clsGlobalData";
import {SvrDataServiceRegister} from "./svr-data.service";
import {LCcustomerinfo} from "../../license-app/Model/clsCMModel";


@Injectable({
  providedIn: 'root',
})
export class ClientUserProfileService {

  public isSignUp: boolean = false;

  constructor(private http: HttpClient,
              private operation: OperationService,
              public svrRoute: Router,
  ) {
  }

  public userProfiles: clsUserProfile[] = [];
  public userProfile: clsUserProfile = new clsUserProfile();
  // return await this.http
  // .post(this.accessPointUrl + "operation/SaveImg", { objFileImg: objMedia.objFileImg }, {
  //   headers: this.headers
  // }).toPromise();
  public routeprefix = "license/cm_customer"

  async onResendMail(email: Email): Promise<any> {
    const url = await this.http
      .post(this.operation.accessPointUrl + this.routeprefix + "/ResendEmail", email,
        {headers: this.operation.headers})
      .toPromise()
    return url;
  }

  async onSignup(clientuser: LCPartner): Promise<any> {
    const url = await this.http
      .post(this.operation.accessPointUrl + this.routeprefix + "/AddCustomer", clientuser,
        {headers: this.operation.headers})
      .toPromise()
    return url;
  }

  async onGetCustInfo(code: string): Promise<any> {
    const url = await this.http
      .get(this.operation.accessPointUrl + this.routeprefix + "/GetCustInfo",
        {
          params: {
            _code: code
          }
        }
      )
      .toPromise()
    return url;
  }

  async onCheckAccountStatus(_paramStatus: LCParamStatus): Promise<LCParamStatus> {
    const url = await this.http
      .post(this.operation.accessPointUrl + this.routeprefix + "/CheckAccountStatus", _paramStatus,
        {headers: this.operation.headers})
      .toPromise()
    return url as LCParamStatus;
  }

  async onCreateAccount(registerAccount: LCRegisterAccount): Promise<clsResponseAccount> {
    const data = await this.http.post<clsResponseAccount>(this.operation.accessPointUrl + this.routeprefix + "/RegisterAccount", registerAccount,
        {headers: this.operation.headers})
      .toPromise()
    return data;
  }

  async onGetUserProfile(userInfo: clsUserSecurity): Promise<any> {
    return await this.http
      .post(this.operation.accessPointUrl + "auth/userProfile", {user: userInfo}, {
        headers: this.operation.headers
      }).toPromise();
  }

  public NavigateRoute(activateRoute: ActivatedRoute, route: string, ...params: any[]) {
    const parentRoute = this.onGetParentRoute(activateRoute);
    if (!clsNan.isNullOrUndefined(params) && params.length > 0) {
      this.svrRoute.navigate([`${parentRoute}/${route}`, ...params]).then();
    } else {
      this.svrRoute.navigate([`${parentRoute}/${route}`]).then();
    }
  }

  public onGetParentRoute(actRoute: ActivatedRoute) {
    return actRoute.snapshot['_urlSegment']['segments'][0].path;
  }

  public async onGetRecord(_record: LCRecord): Promise<LCcustomerinfo[]> {
    let iscustomer = await
      this.http
        .post<LCcustomerinfo[]>(this.operation.accessPointUrl + this.routeprefix + "/GetRecord", _record, {headers: this.operation.headers}).toPromise();
    return iscustomer;
  }

  public async onGetTotal(_total: LCRecord): Promise<number> {
    const res = await this.http.post(this.operation.accessPointUrl + this.routeprefix + "/GetTotal", _total, {headers: this.operation.headers}).toPromise() as number;
    return res ?? 0;
  }

  public async onGetEdit(id: number): Promise<any> {
    let res = await
      this.http
        .get(this.operation.accessPointUrl + this.routeprefix + "/editCustomer", {
          params: {
            "_id": id.toString()
          }
        }).toPromise();
    return res;
  }

  public async GetLicenseEdit(clientid: string, proid: number): Promise<any> {
    let res = await
      this.http
        .get(this.operation.accessPointUrl + this.routeprefix + "/GetLicenseEdit", {
          params: {
            "clientid": clientid,
            "proid": proid.toString()
          }
        }).toPromise();
    return res;
  }

  /* public async onSaveLicense(id: number): Promise<any> {
     let res = await
       this.http
         .get(this.operation.accessPointUrl + this.routeprefix + "/SaveExtendLicense", {
           params: {
             "_id": id.toString()
           }
         }).toPromise();
     return res;
   }*/
  public async onDelete(id: number): Promise<any> {
    let res = await
      this.http
        .delete(this.operation.accessPointUrl + this.routeprefix + "/deleteCustomer", {
          params: {
            "_id": id.toString()
          }
        }).toPromise();
    return res;
  }

  async onRegisterTesting(_paramStatus: LCParamStatus): Promise<LCParamStatus> {
    const url = await this.http
      .post(this.operation.accessPointUrl + this.routeprefix + "/RegisterTesting", _paramStatus,
        {headers: this.operation.headers})
      .toPromise()
    return url as LCParamStatus;
  }

  public async onSaveLicense(objuserinfo: LCcustomerinfo): Promise<number> {
    let res = await this.http.post<number>(this.operation.accessPointUrl + this.routeprefix + "/SaveExtendLicense", objuserinfo).toPromise();
    return res;
  }

  public async onVerifyDomain(_domain: string): Promise<any> {

    let res = await
      this.http
        .get(this.operation.accessPointUrl + this.routeprefix + "/CheckAWSDomain", {
          params: {
            domain: _domain
          }
        }).toPromise();
    return res;
  }


  public onGetProductRoute(productid: number): string {
    let productroute: string = "ERPApp";
    if (productid == 5)
      productroute = "RESApp";
    return productroute;
  }

}
