import { Component, OnInit } from '@angular/core';



@Component({
    selector: 'app-ctl-not-found',
    templateUrl: './ctl-not-found.component.html',
    styleUrls: ['./ctl-not-found.component.scss']
  })
  export class CtlNotFoundComponent implements OnInit {


    constructor() {

    }

    ngOnInit() {  }



  }

