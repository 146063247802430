import {Injectable} from '@angular/core';
import {enumStatus} from './svr-data.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class SvrOperationService {

  constructor(
    public toast: ToastrService,
    public tranService: TranslateService,
    public svrRoute: Router,
    public objDatePipe: DatePipe,
    public http: HttpClient,
  ) {
  }
  public onGetParentRoute(actRoute: ActivatedRoute) {
    return actRoute.snapshot['_urlSegment']['segments'][0].path;
  }




  public onToastDelete(formdesc: string, type: number, optMsg: string = '') {
    switch (type) {
      case enumStatus.Complete : {
        this.onToastSuccess(optMsg ? ('Remove Succeed') : (optMsg));//: `${this.tranService.instant(optMsg)}${this.tranService.instant('Succeed')} `)
        break;
      }
      case enumStatus.inUsed: {
        this.onToastWarning(this.onGetInUseMessage(formdesc));
        break;
      }
      default : {
        this.onToastFailed(optMsg ? ('Remove Failed') : optMsg) ;//: `${this.tranService.instant(optMsg)} ${this.tranService.instant('Failed')}`)
        break;
      }
    }
  }

  public onToastModify(formdesc: string, type: number, isUpdate: boolean = false) {
    switch (type) {
      case enumStatus.Complete : {
        this.onToastSuccess(isUpdate ? ('Update Succeed') : ('Save Succeed'));
        break;
      }
      case enumStatus.Update : {
        this.onToastSuccess(isUpdate ? ('Update Succeed') : ('Save Succeed'));
        break;
      }
      case enumStatus.inUsed: {
        if (isUpdate) {
          this.onToastWarning(this.onGetInUseMessage(formdesc));
        }
        break;
      }
      case enumStatus.DuplicateCode: {
        if (!isUpdate) {
          this.onToastWarning(this.onGetExistMessage(formdesc));
        }
        break;
      }
      case enumStatus.DuplicateUserName: {
        if (!isUpdate) {
          this.onToastWarning(this.onGetExistUserName(formdesc));
        }
        break;
      }
      default : {
        this.onToastFailed(isUpdate ? ('Update Failed') : ('Save Failed'));
        break;
      }
    }
  }

  public onToastSuccess(msg: string = '', title: string = '') {

    setTimeout(() => {
      this.toast.success(this.tranService.instant(msg), title.length > 0 ? this.tranService.instant(title) : title);
    }, 100);
  }

  public onToastFailed(msg: string = '', title: string = '') {
    this.toast.error(this.tranService.instant(msg), title.length > 0 ? this.tranService.instant(title) : title);
  }

  public onToastInfo(msg: string = '', title: string = '') {
    this.toast.info(this.tranService.instant(msg), title.length > 0 ? this.tranService.instant(title) : title);
  }


  public onToastWarning(msg: string = '', title: string = '') {
    this.toast.warning(this.tranService.instant(msg), title.length > 0 ? this.tranService.instant(title) : title);
  }

  public onGetExistMessage(str: string) {
    return `${this.tranService.instant(str)} ${this.tranService.instant('Code is already exist!')}`;
  }
  public onGetExistUserName(str: string) {
    return `${this.tranService.instant(str)} ${this.tranService.instant('User Name is already exist!')}`;
  }

  public onGetInUseMessage(str: string) {
    return `{this.tranService.instant(str)} ${this.tranService.instant(('is already has transaction!'))}`;
    // return `This ${str} could\'t be deleted or updated! It is already in used!`;
  }

  public onToastNoAccess() {
    this.onToastWarning('Access Denied', '');
  }

  public NavigateRoute(activateRoute: ActivatedRoute, route: string, ...params: any[]) {
    const parentRoute = this.onGetParentRoute(activateRoute);
    if (params !== undefined) {
      this.svrRoute.navigate([`${parentRoute}/${route}`, ...params]).then();
    } else {
      this.svrRoute.navigate([`${parentRoute}/${route}`]).then();
    }
  }

  public onDisplayStatus(status = 0,id:number=0,route:string='',descinfo:string=''){
    //Function check all Toast Status [Add , update , fail]
      if (status === enumStatus.Complete && id == 0) {// Check Add
        this.onToastModify(descinfo, status);
        this.svrRoute.navigate([route]);

      } else if (status === enumStatus.Update && id > 0) {//Check Update
        this.onToastModify(descinfo, status,true);
        this.svrRoute.navigate([route]);
      } else if (status === enumStatus.DuplicateCode) {//Check Duplicate code
        this.onToastModify(descinfo, status);
        return;
      }else if (status === enumStatus.DuplicateUserName) {//Check Duplicate code
        this.onToastModify(descinfo, status);
        return;
      }





      if (status === enumStatus.Failed) {//Check Failed
        this.onToastModify(descinfo, status);
        return;
      }
  }

  public FormatDate(val: Date, format: string): string {
    return this.objDatePipe.transform(val, format);
  }


}

export class clsNan {
  public static isNullOrUndefined = (obj: any) => (obj === undefined || obj === null);
}
