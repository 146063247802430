import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Collection_Action_Type, CollectionInitSuccess} from '../action';
import { switchMap, map, catchError, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ClsNewCollection } from '../models/clsModels';
import {CollectionService, OperationService} from "@core";
@Injectable()
export class CollectionEffect {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private actions: Actions,
    private srvOperation: OperationService,
    private collectionService: CollectionService,
  ) {
  }

  @Effect()
  LOAD_DATA = this.actions.pipe(
    ofType(Collection_Action_Type.CollectionInitial),
    switchMap(() => {
      return  this.srvOperation.loadCollection().pipe(
        map((db: ClsNewCollection[]) => new CollectionInitSuccess(db)), takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
  @Effect()
  POST_DATA = this.actions.pipe(
    ofType(Collection_Action_Type.CollectionAdd),
    switchMap((obj) => {

      return  this.srvOperation.addCollection(obj['payload']).pipe(
        map(db => new CollectionInitSuccess(this.collectionService.Collection)), takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );

  @Effect()
  PUT_DATA = this.actions.pipe(
    ofType(Collection_Action_Type.CollectionUpdate),
    switchMap((obj) => {
      return  this.srvOperation.updateCollection(obj['payload']).pipe(
        map(db => new CollectionInitSuccess(this.collectionService.Collection)), takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
  @Effect()
  REMOVE_DATA = this.actions.pipe(
    ofType(Collection_Action_Type.CollectionRemove),
    switchMap((obj) => {
      return  this.srvOperation.removeCollection(obj['payload']).pipe(
        map(db => new CollectionInitSuccess(this.collectionService.Collection)), takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
}
