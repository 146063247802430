import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Init_Action_Type,LoadSuccess} from '../action';
import { switchMap, map, catchError, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import {OperationService} from "@core";
@Injectable()
export class InitEffect {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private actions: Actions,
    private srvOperation: OperationService
  ) {
  }

  @Effect()
  LOAD_INITIALIZE = this.actions.pipe(
    ofType(Init_Action_Type.LoadInitial),
    switchMap(() => {
      return this.srvOperation.loadInit().pipe(
        map(db => new LoadSuccess(null), takeUntil(this.ngUnsubscribe)),
        catchError(error => of())
      );
    })
  );
}
