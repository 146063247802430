import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class svrDataServiceWebsite {
   _KEYADDON = "_KEYADDON";
  _Duration = "_KEYDURATION";
  constructor() {

  }


}



