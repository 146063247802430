import { Component, OnInit, AfterViewInit } from '@angular/core';


@Component({
    selector: 'app-ctl-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
  })
  export class FrmAboutUsComponent implements OnInit , AfterViewInit {

    carouselData: CarouselData[] = [
      { text: 'Retail Specialize', src: 'url(assets/slideImage/Retail.jpg)', dataMerge: 2, dotContent: 'text1', dataHash: 'one',buttonView:"View Feature"},
      { text: 'Restaurant Specialize', src: 'url(assets/slideImage/slider-restaurant.jpg)', dataMerge: 1, dotContent: 'text2', dataHash: 'two',buttonView:"View Feature"},
    ];
    title = 'owl-carousel-libdemo';
    owlNext = '&rarr;';
    owlPrev = '&larr;';
  
  
    customOptions: any = {
      // autoWidth: true,
      loop: true,
      // autoHeight: true,
      // lazyLoad: true,
      // lazyLoadEager: 1,
      // animateOut: 'slideOutDown',
      // animateIn: 'flipInX',
      // items: '10',
      // margin: 10,
      // slideBy: 'page',
      // merge: true,
      autoplay: true,
      // autoplayTimeout: 5000,
      // autoplayHoverPause: true,
          // autoplaySpeed: 4000,
      // dotsSpeed: 500,
      // dots: false,
      // dotsData: true,
      // mouseDrag: false,
      // touchDrag: false,
      // pullDrag: false,
      // smartSpeed: 400,
      // fluidSpeed: 499,
      // dragEndSpeed: 350,
      // dotsEach: 4,
      center: true,
      URLhashListener:true,
      startPosition: 'URLHash',
      // rewind: true,
      // rtl: true,
      // startPosition: 1,
      // navText: [ '<i class=fa-chevron-left>left</i>', '<i class=fa-chevron-right>right</i>' ],
      responsive: {
        0: {
          items: 1
        },
       
      },
      // stagePadding: 40,
      // nav: true
    }
  
    activeSlides: any;
  
    classes: {[key:string]: boolean} = {
      'animated': true,
      'fadeIn': true
    }
  
    constructor() {
  
    }

    animating() { console.log('Animating...'); }
  animated() { console.log('Animated'); }
      ngAfterViewInit() {
     
    }
    ngOnInit() {  }

    getChangeData(data: any) {
        this.activeSlides = data;
        console.log('HomeComponent -> change');
        console.log(data);
      }
    
      getChangedData(data: any) {
        this.activeSlides = data;
        console.log('HomeComponent -> changed');
        console.log(data);
     }
    getPassedData(data: any) {
      this.activeSlides = data;
      console.log(this.activeSlides);
    }
  
    addClassObj() {
      const startClasses: any = { ...this.classes};
      startClasses['fade-spin'] = true;
      this.classes = startClasses;
    }
  
    deleteOneClass() {
      const startClasses: any = { ...this.classes};
      delete startClasses['fade-spin'];
      this.classes = startClasses;
    }
  
  }

export class CarouselData {
    id?: string;
    text: string;
    dataMerge?: number;
    width?: number;
    dotContent?: string;
    src?: string;
    dataHash?: string;
    buttonView?: string;
  }