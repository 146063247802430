import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {FrmMessageBoxComponent} from "../../projects/website-app/console/frm-message-box/frm-message-box.component";

@Injectable({
  providedIn: 'root'
})
export class SvrMessageBoxService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  ShowMessage(isShow: boolean = false, Message: string = '', Title: string = '', IsNotShowCancel: boolean = false, IsNotShowNuetral: boolean = false, NegativeText: string = '', NuetralText: string = '',
              PositiveText: string = '', onOk: () => void = null, onCancel: () => void = null, onNeutral: () => void = null, isUseTick: boolean = false, TickCount: number = 0) {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(FrmMessageBoxComponent)
      .create(this.injector);
    /*componentRef.instance.isShow = isShow;*/
    componentRef.instance.lblMsg = Message;
    componentRef.instance.lblTitle = Title;
    componentRef.instance.lblNegative = NegativeText;
    componentRef.instance.lblPositive = PositiveText;
    componentRef.instance.isShowCancel = IsNotShowCancel;
    componentRef.instance.isUseTick = isUseTick;
    componentRef.instance.tickCount = TickCount;
    componentRef.instance.lblNuetral = NuetralText;
    componentRef.instance.isShowNuetral = IsNotShowNuetral

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    setTimeout(() => {
      componentRef.instance.isShow = true;
    }, 100);

    const onCancelData = componentRef.instance.onCancel.subscribe(() => {
      if (onCancel != null)
        onCancel();

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      onOKData.unsubscribe();
    });
    let onOKData = componentRef.instance.onOK.subscribe(() => {
      if (onOk != null)
        onOk();

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      onCancelData.unsubscribe();
    });
    let onNeutralData = componentRef.instance.onNuetral.subscribe(() => {
      if (onNeutral != null)
        onNeutral();
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      onNeutralData.unsubscribe();
    });

  }

  ShowAlertMessage(Message: string = '', Title: string = 'Info', PositiveText: string = 'OK', onOk: () => void = null) {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(FrmMessageBoxComponent)
      .create(this.injector);
    /*componentRef.instance.isShow = isShow;*/
    componentRef.instance.lblMsg = Message;
    componentRef.instance.lblTitle = Title;
    componentRef.instance.lblNegative = '';
    componentRef.instance.lblPositive = PositiveText;
    componentRef.instance.isShowCancel = true;
    componentRef.instance.isUseTick = false;
    componentRef.instance.tickCount = 0;
    componentRef.instance.lblNuetral = '';
    componentRef.instance.isShowNuetral = true;

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    setTimeout(() => {
      componentRef.instance.isShow = true;
    }, 100);


    let onOKData = componentRef.instance.onOK.subscribe(() => {
      if (onOk != null)
        onOk();

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();

    });
  }


  ShowConfirmMessage(Message: string = '', onOk: () => void = null, onCancel: () => void = null, Title: string = 'Confirm', NegativeText: string = 'NO', PositiveText: string = 'YES') {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(FrmMessageBoxComponent)
      .create(this.injector);
    /*componentRef.instance.isShow = isShow;*/
    componentRef.instance.lblMsg = Message;
    componentRef.instance.lblTitle = Title;
    componentRef.instance.lblNegative = NegativeText;
    componentRef.instance.lblPositive = PositiveText;
    componentRef.instance.isShowCancel = false;
    componentRef.instance.isUseTick = false;
    componentRef.instance.tickCount = 0;
    componentRef.instance.lblNuetral = '';
    componentRef.instance.isShowNuetral = false

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    setTimeout(() => {
      componentRef.instance.isShow = true;
    }, 100);

    let onCancelData = componentRef.instance.onCancel.subscribe(() => {
      if (onCancel != null)
        onCancel();

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      onOKData.unsubscribe();
    });
    let onOKData = componentRef.instance.onOK.subscribe(() => {
      if (onOk != null)
        onOk();

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      onCancelData.unsubscribe();
    });
  }

}

