import { animate, animation, style } from '@angular/animations';


export const animateOpacity = animation([
  style({ opacity: 0 }),
  animate('{{time}}', style({ opacity: '{{opacity}}' })),
]);

export const animateTransitionX = animation([
  style([{ transform: 'translateX({{axisBefore}})', opacity: '{{opaBefore}}' }]),
  animate('{{time}}', style({ transform: 'translateX({{axisAfter}})', opacity: '{{opaAfter}}' })),
]);

export const animateTransitionY = animation([
  style([{ transform: 'translateY({{axisBefore}})', opacity: '{{opaBefore}}' }]),
  animate('{{time}}', style({ transform: 'translateY({{axisAfter}})', opacity: '{{opaAfter}}' })),
]);
export const animateScale = animation([
  style([{ transform: 'scale({{scaleBefore}})', opacity: '{{opaBefore}}' }]),
  animate('{{time}}', style({ transform: 'scale({{scaleAfter}})',opacity: '{{opaAfter}}' })),
]);

/*animation([
  style({ opacity: 0 }),
  animate(300, style({ opacity: 1 })),
]);*/
