import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'ImportButtonEdit',
  templateUrl: './ctl-import-button.component.html',
  styleUrls: ['./ctl-import-button.component.scss']
})

export class CtlImportButtonEditComponent {
  @Input() xs: boolean = false;
  @Input() icon: any;
  @Input() label: string;
  @Input() class: any;
  @Input() model: any;
  @Input() style: any;
  @Output() popupClick:EventEmitter<any>=new EventEmitter<any>();
  @Output() public importClick: EventEmitter<any> = new EventEmitter();
  @ViewChild('ImportXSPopup', { static: false }) importXsPopup: any;
  public dialogRef: MatDialogRef<unknown, any>;
  public togglePopup: boolean = false
  constructor(public dialog: MatDialog, ) {

  }
  onImportClick($e) {
    this.importClick.emit($e);

  }
  onImportXSClick($e) {
    this.dialogRef.close();
    this.importClick.emit($e);

  }
  openDialog() {




    if( this.model != null &&  this.model.length>0) {

      this.dialogRef = this.dialog.open(this.importXsPopup, { width: '100vh', panelClass: 'import-dialog' });

      this.dialogRef.afterOpened().subscribe(result => {
        this.togglePopup = true;
      });
      this.dialogRef.afterClosed().subscribe(result => {
        this.togglePopup = true;
      });
    }
    else
    {
       this.popupClick.emit();
    }
  }

}
