import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { StorageMap } from '@ngx-pwa/local-storage';
import { MenuService } from './menu.service';
import { Observable } from 'rxjs';
import {IBlog, IFeed, IItem, ISlider, IContent, ICollection} from "../../Store/intity/IModels";
import { OwlOptions, ResponsiveSettings } from "ngx-owl-carousel-o";
import {
  ClsChildrenItem,
  clsCollection,
  clsCollectionGroup,
  ClsFooter, clsIniialize,
  clsMenuGroup,
  IMenus, Menu
} from "../../Store/models/clsModels";

export enum FeedType {
  item = 'item',
  slider = 'slider',
  blog = 'blog',
  collection = 'collection',
  content = 'content',
  product= 'product',
  other = 'other'
}

export enum imagePosition {
  topCenter = 'top-center',
  bottomCenter = 'bottom-center',
  leftCenter = 'left-center',
  rightCenter = 'right-center',
  topLeft = 'top-left',
  leftBottom = 'left-bottom',
  rightBottom = 'right-bottom',
  topRight = 'top-right'
}

export enum imageType {
  svg = 'svg',
  image = 'image',
  icon = 'icon'
}

@Injectable({
  providedIn: 'root'
})
export class InitializeService {
  public loadingPromise: Promise<any>;
  public initData: clsIniialize = new clsIniialize();
  public childMenuList: ClsChildrenItem[] = [];
  public routeMenuId: number = 0;
  public feedType = FeedType;
  public imagePosition = imagePosition;
  public imageType = imageType;
  public headersIndex: number = 0;
  public feeds: IFeed[];
  public feedData: any[] = [];
  public collections: clsCollection[] = [];
  public collection: clsCollection = new clsCollection();
  public route: string = '/';
  public feedTypeData: any[] = [];
  public collectionLevel = 0;
  public scrumbar: any[] = [];
  // public collectiondetails: ICollectionDetail[];
  public isEditor: boolean = false;
  responsiveSettingDefault: ResponsiveSettings = {
    0: { items: 2, dots: false },
    400: { items: 2, dots: false },
    740: { items: 3 },
    940: { items: 4 },
    1280: { items: 5 },
    1920: { items: 7 }
  };
  public sliderOption: OwlOptions = {
    loop: true,
    lazyLoad: true,
    autoplay: true,
    skip_validateItems: true,
    slideBy: 'page',
    margin: 10,
    // dots: true,
    startPosition: 0,
    stagePadding: 0,
    // autoplayTimeout: 5000,
    autoplayHoverPause: true,
    // autoplaySpeed: 4000,
    responsiveRefreshRate: 10,
    // dotsSpeed: 500,
    responsive: { ...this.responsiveSettingDefault }
    // items: 4,
  };

  constructor(private storage: StorageMap, private menuService: MenuService) {

  }

  getMenu(route: string, menus: IMenus[]): number {
    _.find(menus, (m) => {
      if (m.menuroute === route.toLowerCase()) {
        this.routeMenuId = m.menuid;
        return this.routeMenuId;
      } else if (m.children && m.children.length > 0) {
        this.getMenu(route, m.children);
      }
    });
    return this.routeMenuId;
  }

  setMenuIdByRoute(route: string, grp: number): number {
    this.routeMenuId = 0;
    _.find(this.initData.menugroup, (it) => {
      return this.getMenu(route, it.menus);
    });
    // this.getMenu(route, this.initData.menugroup[grp].menus);
    // if (menu !== null && menu !== undefined) {
    //   this.routeMenuId = menu.menuid;
    // } else {
    //   this.routeMenuId = 0;
    // }
    return this.routeMenuId;
  }

    getDatByFeedId(type: string, feedId: string) {

    if (type === FeedType.item) {
      return this.feedData = _.filter(this.initData.items, (f) => {
        return f.feedid === feedId;
      }) as IItem[];
    } else if (type === FeedType.slider) {
      return this.feedData = _.filter(this.initData.sliders, (f) => {
        return f.feedid === feedId;
      }) as ISlider[];
    } else if (type === FeedType.blog) {
      return this.feedData = _.filter(this.initData.blogs, (f) => {
        return f.feedid === feedId;
      }) as IBlog[];
    }else if(type === FeedType.product){
      return this.feedData = _.filter(this.initData.collections, (f) => {
        return f.feedid === feedId;
      }) as clsCollection[];
    } else if (type === FeedType.collection) {
      const collectionData = _.filter(this.initData.collectiongroups, (f) => {
        return f.feedid === feedId;
      }).map(data => {
        data.totalcollection = _.filter(this.initData.collections, (pro) => {
          return pro.collectiongroupid === data.collectiongroupid;
        }).length;
        return data;
      });

      // productData.map((data) => {
      //   data.products = _.filter(this.initData.products, (pro) => {
      //     return pro.collectiongroupid === data.collectiongroupid;
      //   }).map((prod) => {
      //     prod.collectiondetails = _.filter(this.initData.collectiondetails, (prdd) => {
      //       return prdd.collectionid === prod.collectionid;
      //     });
      //     return prod;
      //   });
      // });

      return this.feedData = collectionData as clsCollectionGroup[];
    }else if (type === FeedType.content) {
      return this.feedData = _.filter(this.initData.content, (f) => {
        return f.feedid === feedId;
      }) as IContent[];
    }

    return null;
  }

  getCollections(grpId) {
    return _.filter((this.isEditor ? this.collections : this.initData.collections), (pro) => {
      return pro.collectiongroupid === grpId;
    });
  }
  getCollectionsByFeedId(feedId) {
    return _.filter((this.isEditor ? this.collections : this.initData.collections), (pro) => {
      return pro.feedid === feedId;
    });
  }
  // getcollectiondetails(proId) {
  //   return this.collectiondetails = _.filter(this.initData.collectiondetails, (dt) => {
  //     return dt.collectionid === proId;
  //   });
  // }

  getFeedByRoute(route: string, grp: number): IFeed[] {
    this.setMenuIdByRoute(route, grp);
    // const dataGroup = this.initData.menugroup[grp];
    // const dataMenu = dataGroup.menus.filter(n => n.menuid === this.routeMenuId);
    if (this.routeMenuId && this.routeMenuId != 0) {
      // const dataFeed = this.initData.feeds.filter((feed) => dataMenu.every(menu => feed.menuid === menu.menuid));
      const dataFeed = this.initData.feeds.filter((feed) => feed.menuid === this.routeMenuId);
      return this.feeds = [...dataFeed];
    } else {
      this.feeds = [];
    }
    return this.feeds;
  }

  getHeaders(route: string, grp: number): number {

    this.setMenuIdByRoute(route, grp);
    const headers = this.initData.headers.filter(f => f.menuid === this.routeMenuId);
    if (headers.length > 0) {
      this.headersIndex = this.initData.headers.indexOf(headers[0]);
    } else {
      const tmpHeader = {
        headersliders: [],
        headertexts: '',
        showaction: false,
        isvdobackground: false,
        background: 'assets/media/BackgroundHeader.png',
        menuid: this.routeMenuId,
        headerid: this.initData.headers.length + 1,
        headername: '',
        headerstatus: true,
        isshowheader:true,
        isshowmap:false,
        rtl: false
      };
      this.headersIndex = this.initData.headers.length;
      this.initData.headers.push(tmpHeader);
    }
    return this.headersIndex;
  }

  async updateMenuChildList() {
  }

  setTempLocalStorage(obj: clsIniialize) {
    this.storage.set('tempInitialize', { data: obj, date: Date.now() }).subscribe(() => {
    });
  }

  getTempLocalStorage():
    Observable<any> {
    return this.storage.get('tempInitialize');
  }

  removeTempLocalStorage() {
    this.storage.delete('tempInitialize').subscribe();
  }

  setLocalStorage() {
    this.storage.set('objInitialize', { data: this.initData, date: Date.now() }).subscribe(() => {
    });
  }

  getInitData():
    Observable<any> {
    return this.storage.get('objInitialize');
  }

  setMenuLocalStorage(menu: Menu[]) {
    this.storage.set('objMenu', menu).subscribe(() => {
    });
  }

  getMenLocalStorage()
    :
    Observable<any> {
    return this.storage.get('objMenu');
  }

  getChildMenu()
    :
    ClsChildrenItem[] {
    // const objArr = this.initData.menus.filter(
    //   n => n.id === this.menuService.SelectedMenu.id
    // );
    // if (objArr.length > 0) {
    //   this.childMenuList = objArr[0].children;
    // }
    return this.childMenuList;
  }

  setInitial(dataInit: clsIniialize) {

    this.initData = {
      menugroup: dataInit?.menugroup ?? [],
      collectiongroups: dataInit?.collectiongroups ?? [],
      blogs: dataInit?.blogs ?? [],
      sliders: dataInit?.sliders ?? [],
      items: dataInit?.items ?? [],
      feeds: dataInit?.feeds ?? [],
      content:dataInit?.content??[],
      footer: dataInit?.footer ?? new ClsFooter(),
      headers: dataInit?.headers ?? [],
      collections: dataInit?.collections ?? [],
      collectiondetails: dataInit?.collectiondetails ?? []
    };
    this.setLocalStorage();
  }

  setMenuChildList(menu
    :
    ClsChildrenItem[]
  ) {
    return this.childMenuList = menu;
  }

  addMenuChild(menu
    :
    ClsChildrenItem
  ):
    Observable<any> {
    return this.getMenLocalStorage().pipe(
      // map(n => {
      //   this.initData.menu = (n !== undefined && n.length > 0) ? n : this.initData.menu;
      //   this.initData.menu
      //     .filter(f => f.id === this.menuService.SelectedMenu.id)
      //     .map(m => {
      //       m.children.push(menu);
      //     });
      //   this.setMenuLocalStorage(this.initData.menu);
      //   return this.initData.menu;
      // })
    );
  }

  deleteMenuChild(menu
    :
    ClsChildrenItem
  ):
    Observable<any> {
    return this.getMenLocalStorage().pipe(
      // map(n => {
      //   debugger
      //   this.initData.menu = (n !== undefined && n.length > 0) ? n : this.initData.menu;
      //   this.initData.menu
      //     .filter(f => f.id === this.menuService.SelectedMenu.id).map(m => {
      //     m.children.filter(f => f.id !== menu.id);
      //   });
      //
      //   this.setMenuLocalStorage(this.initData.menu);
      //   return this.initData.menu;
      // })
    );
    // debugger;
    // this.initData.menu = this.initData.menu
    //   .filter(n => n.id === this.menuService.SelectedMenu.id)
    //   .filter(n => n.children.filter(c => c.id !== Id));
    // this.getChildMenu();
    // this.setLocalStorage();
  }

  async updateMenuChild(menu
    :
    ClsChildrenItem
  ) {
    // this.initData.menu
    //   .filter(n => n.id === this.menuService.SelectedMenu.id)
    //   .map(m => {
    //     m.children
    //       .filter(c => c.id === menu.id)
    //       .map(mc => {
    //         mc = menu;
    //       });
    //   });
    // this.getChildMenu();
    // this.setLocalStorage();
  }

  getMenuGroup()
    :
    clsMenuGroup[] {
    return this.initData.menugroup;
  }

  async addMenu(menu
    :
    Menu
  ) {

    // this.initData.menu.push(menu);
    // this.setLocalStorage();
  }

  async updateMenu(menu: Menu) {
    // this.initData.menu
    //   .filter(n => n.id === menu.id)
    //   .map(m => {
    //     m = menu;
    //   });
    // this.setLocalStorage();
  }

  async deleteMenu(id
    :
    number
  ) {
    // debugger;
    // this.initData.menu = this.initData.menu.filter(n => n.id !== id);
    // this.setLocalStorage();
  }
}
