import {Component, Inject, Input, OnInit} from '@angular/core';
import {clsNan} from '../../projects/license-app/Class/svr-operation.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ctl-input-reference',
  templateUrl: './ctl-input-reference.component.html',
  styleUrls: ['./ctl-input-reference.component.scss']
})
export class CtlInputReferenceComponent implements OnInit {

  @Input() lblTitle: string = 'Void';
  @Input() lblNegative: string = 'Cancel';
  @Input() lblPositive: string = 'OK';
  @Input() isShowCancel: boolean = false;
  @Input() tickCount: number = 0;
  public isRTF = false;
  public objReference: string = '';

  constructor(
    public dialogRef: MatDialogRef<CtlInputReferenceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

  }

  ngOnInit(): void {
    if (!clsNan.isNullOrUndefined(this.data)) {
      console.log('longdesc', this.data.longdesc);
      this.isRTF = this.data.isRTF ?? false;
      this.lblTitle = this.data.label ?? '';
      this.objReference = this.data.longdesc ?? '';
    }
  }

  onOKClick() {
    /*this.onOK.emit();*/
    this.dialogRef.close(this.objReference);
  }

/*  onCancelClick() {
    /!*this.onCancel.emit();*!/
    this.dialogRef.close(null);
  }*/
  onCancelClick(){
    this.dialogRef.close(null);
  }

}
