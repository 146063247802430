import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef
} from "@angular/core";

import {CdkScrollable} from "@angular/cdk/overlay";
import {Store} from "@ngrx/store";
import * as _ from "lodash";
import * as TOSoftwareStore from "../../Store/reducer";
import {clsMenuGroup} from "../../Store/models/clsModels";
import {Router, NavigationEnd} from "@angular/router";
import {FacebookService, InitParams} from "ngx-facebook";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {NavService} from "../../shared/services/nav.service";
import {GlobalService} from "../../core/services/global.service";
import {InitializeService, OperationData, OperationService} from "@core";
import {$animations} from "../../animation/animate.animations";
import {MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {MatToolbar} from '@angular/material/toolbar';
import {ClientUserProfileService} from "../../projects/register-app/Class/clientUserProfile.service";

@Component({
  selector: "app-font-layout",
  templateUrl: "./front-layout.component.html",
  styleUrls: ["./front-layout.component.scss"],
  animations: [$animations]
})
export class FrontLayoutComponent implements OnInit, AfterViewInit {
  items: clsMenuGroup[];
  showFiller = false;
  @ViewChild("sidenav") appDrawer: ElementRef;
  @ViewChild("scroller1") scroller: ElementRef;
  @ViewChild(MatSidenavContainer, {static: true})
  sidenavContainer: MatSidenavContainer;
  @ViewChild(CdkScrollable, {static: true}) scrollable: CdkScrollable;
  @ViewChild(MatSidenavContent, {static: true}) content: MatSidenavContent;
  @ViewChild("toolBara", {static: true}) toolbar: MatToolbar;
  isShow: boolean;
  isEditor: boolean;
  modal: HTMLElement;
  scrollTop = 0;
  //
  // _MessengerFB: clsMessagerFB =
  //   {
  //     theme_color: '#870A0A',
  //     page_id: '110897480538724',
  //     logged_in_greeting: 'Hello ! I\'m T.O Group. How can we help you?',
  //     logged_out_greeting: 'Hello ! I\'m T.O Group. How can we help you?'
  //   };
  //
  // // liveChatApi: LiveChatWidgetApiModel
  // // treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  // // dataSource = new MatTreeNestedDataSource<FoodNode>();
  // email = 'sales@t-ogroup.com';
  // phonenumber = '023 224 035';
  // phonenumber1 = '093 999 258'
  checkroute: string = '/';

  constructor(
    public svrChange: ChangeDetectorRef,
    public store: Store<TOSoftwareStore.TOSoftwareState>,
    public svrRoute: Router,
    public fb: FacebookService,
    public svrOpt: OperationService,
    public svrStorage: LocalStorage,
    public navService: NavService,
    public svrInit: InitializeService,
    public global: GlobalService,
    public svrData: OperationData,
    public userService?: ClientUserProfileService
  ) {
    this.onHomeModule();
    if (this.svrInit.isEditor == false)
      this.initFacebookService();
    this.global.onMappingRoute.subscribe(n=> {
      this.onRouter(n);
    })
    this.svrRoute.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const states = this.svrRoute.url.slice(1).split('/');
        if (_.includes(states, 'editor')) {
          this.svrInit.isEditor = this.isEditor = true;
        } else {
          this.checkroute = states[0]
        }
        this.gotoTop();
      }
    });
  }


  // hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
  private initFacebookService(): void {
    const initParams: InitParams = {
      xfbml: true,
      version: "v6.0",
      cookie: true
    };
    this.fb.init(initParams);
  }

  ngOnInit() {
    if (!this.svrInit.isEditor && this.checkroute != '404') {
      const routState = this.svrRoute.url.split('page');
      if (routState && routState[0] == '/') {
        if (routState.length > 1) {
          const route = routState[1].replace('/:', 'page/:')

          this.onHomeRoute(route);
        } else {
          this.onHomeRoute("/");
        }
      } else {
        this.onHomeRoute(this.svrRoute.url);
      }
    }
  }

  onHomeModule() {
    if (this.isEditor) {
      return;
    }

    this.items = this.svrInit.initData.menugroup;

  }

  onSearch() {
    if (this.sidenavContainer.scrollable !== undefined) {
      this.sidenavContainer.scrollable.scrollTo({
        top: 56,
        left: 0,
        behavior: "smooth"
      });
    }
    this.toolbar._elementRef.nativeElement.classList.add("sticky");
    this.toolbar._elementRef.nativeElement.classList.remove("fixed");
    this.isShow = true;

  }

  ngAfterViewInit() {

    this.navService.appDrawer = this.appDrawer;
    this.scrollable.elementScrolled().subscribe(() => {

      this.scrollTop = this.sidenavContainer.scrollable.getElementRef().nativeElement.scrollTop;
      if (this.svrInit.initData.headers[this.svrInit.headersIndex].isshowheader) {
        if (this.scrollTop > 60) {
          this.toolbar._elementRef.nativeElement.classList.add("sticky");
          this.toolbar._elementRef.nativeElement.classList.remove("fixed");
          this.isShow = true;
        } else {
          this.isShow = false;
          this.toolbar._elementRef.nativeElement.classList.add("fixed");
          this.toolbar._elementRef.nativeElement.classList.remove("sticky");
        }
      } else {
        this.toolbar._elementRef.nativeElement.classList.add("sticky");
        this.toolbar._elementRef.nativeElement.classList.remove("fixed");
        this.isShow = true;
      }
    });
    this.modal = document.querySelector(".modal-container") as HTMLElement;

  }

  onSignUp() {
    /*this.svrRoute.navigate(['verification'],{queryParams : {id: "13bfc689-d08f-48a0-ab35-209f3beab3ce"}})*/
     this.svrRoute.navigate(['sign-up']);
     this.userService.isSignUp = true;
  }

  onHomeRoute(route: any) {
    this.onRouter(route);
    this.gotoTop();
  }

  gotoTop() {
    if (this.sidenavContainer.scrollable !== undefined) {
      this.sidenavContainer.scrollable.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      this.scrollTop = 0;
    }
  }

  async onPublic() {
    this.modal.style.display = "flex";
    let issuccess = await this.svrOpt.doPublic(this.svrInit.initData);
    if (issuccess != null && issuccess != undefined) {
      setTimeout(() => {
        this.modal.style.display = "none";
        this.svrInit.isEditor = this.isEditor = false;
        this.onRouter('/');
      }, 5000);
    }
  }

  onEditor() {
    this.svrInit.isEditor = this.isEditor = true;
    // this.svrInit.getHeaders("/", 0);
    // this.svrInit.getFeedByRoute("/", 0);
    // this.svrRoute.navigate(["/console/editor"]);
    this.onRouter(this.svrInit.route);
  }

  onLogOut() {
    localStorage.removeItem('tosoftwarejwt');
    this.svrRoute.navigate(['/']);
  }

  onPreview() {
    this.svrInit.isEditor = this.isEditor = false;
    this.onRouter(this.svrInit.route);
  }

  onDisableHeader() {
    this.toolbar._elementRef.nativeElement.classList.add("sticky");
    this.toolbar._elementRef.nativeElement.classList.remove("fixed");
    this.isShow = true;
  }

  onRouter(e: any) {
    this.svrInit.route = (_.isString(e) ? e : e.menuroute);
    this.svrInit.getHeaders(_.isString(e) ? e : e.menuroute, 0);
    this.svrInit.getFeedByRoute(_.isString(e) ? e : e.menuroute, 0);

    const states = this.svrRoute.url.slice(1).split('/');
    if (_.includes(states, 'console')) {
      if (this.svrInit.isEditor) {
        this.svrInit.feedTypeData = [];
        this.svrInit.collections =[];
        this.svrRoute.navigate([
          '/console/editor/' + (_.isString(e) ? e : e.menuroute)
        ]);
      } else {
        this.svrRoute.navigate([
          '/console/view/' + (_.isString(e) ? e : e.menuroute)
        ]);
      }
    } else {
      this.svrRoute.navigate([e]);
    }
    if (!this.svrInit.initData.headers[this.svrInit.headersIndex].isshowheader)
      this.onDisableHeader();

  }
}
