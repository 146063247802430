import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { NavMenu_Action_Type, NavMenuInitSuccess } from "../action";
import { switchMap, map, catchError, takeUntil } from "rxjs/operators";
import { of, Subject, forkJoin } from "rxjs";
import { Menu } from '../models/clsModels';
import {InitializeService, OperationService} from "@core";

@Injectable()
export class NavMenuEffect {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private actions: Actions,
    private initialize: InitializeService,
    private srvOperation: OperationService
  ) {}

  @Effect()
  LOAD_INITIALIZE = this.actions.pipe(
    ofType(NavMenu_Action_Type.NavMenuInitial),
    switchMap(() => {
      return forkJoin([
        this.srvOperation.loadNavMenu(),
        this.initialize.getMenLocalStorage()
      ]).pipe(
        map(obj => {
          const server = obj[0];
          const local: Menu[] = obj[1];
          const result: Menu[] = (local !== undefined && local.length > 0 ) ? local : server;
          return new NavMenuInitSuccess(result);
        }),
        takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
}
