import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
// import {Circle, latLng, Map, Marker, tileLayer, LatLngExpression, Polyline} from 'leaflet';
@Component({
    selector: 'app-ctl-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
  })
  export class CtlMapComponent implements OnInit {
    @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
    zoom = 12;
    private _maxZoom = 19;
    private _defaultZoom = 14;
    center: google.maps.LatLngLiteral;

    // layersControl = {
    //   baseLayers: {
    //     'Open Street Map': tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //       maxZoom: this._maxZoom,
    //       minZoom: 2,
    //       attribution: '...',
    //       opacity: 0.9,
    //       detectRetina: true,
    //       id: 'mapbox.streets',
    //       accessToken: 'pk.eyJ1IjoibWx0bWFwYm94IiwiYSI6ImNrM2Y3NTFqODAxcmMzdHQ2OXVraDhzOWQifQ.-QaZDlOo5b7H-8RMnSh7jA'
    //     }),
    //     'Satellite Map': tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    //       maxZoom: this._maxZoom,
    //       minZoom: 2,
    //       attribution: '...',
    //       opacity: 0.9,
    //       detectRetina: true,
    //       id: 'mapbox.streets',
    //       accessToken: 'pk.eyJ1IjoibWx0bWFwYm94IiwiYSI6ImNrM2Y3NTFqODAxcmMzdHQ2OXVraDhzOWQifQ.-QaZDlOo5b7H-8RMnSh7jA'
    //     })
    //   }
    // };

    // options = {
    //   layers: [],
    //   style: 'mapbox://styles/mapbox/satellite-v9',
    //   zoom: this._defaultZoom,
    //   center: [11.5563470508766, 104.92825119735568],
    //   animate: true,
    //   attributionControl: false,
    //   zoomControl: false,
    //   fadeAnimation: true,
    //   zoomAnimation: true,
    //   useCache: false,
    //   crossOrigin: true
    // };




    constructor() {
    }


    ngOnInit() {


      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
      }

  }

