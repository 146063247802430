import { Pipe, PipeTransform } from '@angular/core';
import * as momenttz from "moment-timezone";
import {SvrDataService} from "../projects/license-app/Class/svr-data.service";
import {clsNan} from "../projects/license-app/Class/svr-operation.service";

export const pipeDateOrder = {
  Datetime: 0,
  Time: 1,
  Date: 2,
};

@Pipe({
  name: 'ERPDatetimeFormat',
  pure: true,
})
export class PpDatetimeFormatPipe implements PipeTransform {
  static Datetime = 0;
  static Time = 1;
  static Date = 2;
  Type = pipeDateOrder;

  private static _map = new Map<string, any>();

  constructor(private svrData: SvrDataService) {
  }

  /*
    public static onResetMemo() {
      this._map.clear();
    }
  */

  primeTimeTranform() {
    return this.svrData.objInit.objCompany.datetimeformat.timeformat.includes('H') ? '24' : '12';
  }

  primengDateTranform() {
    const _dateTime = this.svrData.objInit.objCompany.datetimeformat.dateformat.replace(/[-/]/g, '.').split('.');
    return _dateTime.map(n => {
      let _opt = n.toLowerCase();
      let _val = n.substring(-2, 2);

      if (_opt.includes('m')) {
        if (n.length > 2) {
          _val = n.substring(-2, 2).toUpperCase();
        } else {
          _val = n.substring(-2, 2).toLocaleLowerCase();
        }
      }
      return _val;
    }).join(this.svrData.objInit.objCompany.datetimeformat.dateformat.includes('/') ? '/' : '-');
  };

  /**
   * @Param format?:string use in Form SmCompany
   * */

  /* @memo(
     {
       resolver: (value) => `DT-${value}`,
       cache: PpDatetimeFormatPipe._map
     }
   )*/
  transform(value: any, args: number = this.Type.Date): any {
    try {
      if (clsNan.isNullOrUndefined(this.svrData.objInit.objCompany.datetimeformat) || clsNan.isNullOrUndefined(value) || value === '') {
        return value;
      }

      let result = '';
      switch (args) {
        case this.Type.Datetime: {
          /*result = this.svrDatePipe.transform(value, `${this.svrData.objInit.objCompany.datetimeformat.dateformat} ${this.svrData.objInit.objCompany.datetimeformat.timeformat}`);*/
          result = momenttz(value).tz(this.svrData.objInit.objTimezone.tzcode).format(`${this.svrData.objInit.objCompany.datetimeformat.dateformat} ${this.svrData.objInit.objCompany.datetimeformat.timeformat}`);
          break;
        }
        case this.Type.Time: {
          /*result = this.svrDatePipe.transform(value, this.svrData.objInit.objCompany.datetimeformat.timeformat);*/
          result = momenttz(value).tz(this.svrData.objInit.objTimezone.tzcode).format(this.svrData.objInit.objCompany.datetimeformat.timeformat);
          break;
        }
        default : {
          /*result = this.svrDatePipe.transform(value, this.svrData.objInit.objCompany.datetimeformat.dateformat);*/
          result = momenttz(value).tz(this.svrData.objInit.objTimezone.tzcode).format(this.svrData.objInit.objCompany.datetimeformat.dateformat);
          break;
        }
      }
      return result;
    } catch (e) {
      console.log('error--->', e);
      return '';
    }


  }


}

