import {InitEffect} from './efc-init.effect';
import { HeaderEffect } from './efc-header.effect';
import { NavMenuEffect } from './efc-navmenu.effect';
import { CollectionEffect } from './efc-collection.effect';
import { MenuEditorEffect } from './efc-meu-editor.effect';
export const effects: any[] = [
  InitEffect,
  HeaderEffect,
  NavMenuEffect,
  MenuEditorEffect,
  CollectionEffect
];

export * from './efc-init.effect';
export * from './efc-header.effect';
export * from './efc-navmenu.effect';
export * from './efc-collection.effect';
export * from './efc-meu-editor.effect';

