import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtlCheckBoxEditComponent),
  multi: true,
};

@Component({
  selector: 'CheckBoxEdit',
  providers: [CHECKBOX_VALUE_ACCESSOR],
  templateUrl: './ctl-checkbox-edit.component.html',
  styleUrls: ['./ctl-checkbox-edit.component.scss']
})

export class CtlCheckBoxEditComponent implements ControlValueAccessor {

 @Input() checked = false;
 @Input() hidden = false;
  @Input() showLabel = true;
 @Input() disabled = false;
 @Input() label: string;
 @Output() public onCheck: EventEmitter<any> = new EventEmitter();
  private innerValue: string | number | boolean;
  get value(): string | number | boolean {
    return this.innerValue;
  }

  set value(v: string | number | boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.change(v);
    }
  }

  onTouched = (_: any) => {
  };
  onChange = (_: any) => {
  };
  writeValue(value: string | number | boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value: string | number | boolean) {

    this.innerValue = value;
    this.onChange(value);
    this.onTouched(value);
    this.onCheck.emit(value);
  }
}
