
import { Action } from '@ngrx/store';
import * as TOSoftwareStore from '../models/clsModels';
export enum  NavMenu_Action_Type {
    NavMenuInitial = 'NavMenuInitial',
    NavMenuInitSuccess= 'NavMenuInitSuccess',
    NavMenuUpdate= 'NavMenuUpdate',
    NavMenuRemove= 'NavMenuRemove',
    NavMenuAdd= 'NavMenuAdd',
}
export class NavMenuInitial implements Action {
    readonly type = NavMenu_Action_Type.NavMenuInitial;
    constructor() {
    }
}
export class NavMenuUpdate implements Action {
    readonly type = NavMenu_Action_Type.NavMenuUpdate;
    constructor(public payload: TOSoftwareStore.Menu) {
    }
}
export class NavMenuRemove implements Action {
    readonly type = NavMenu_Action_Type.NavMenuRemove;
    constructor(public payload: TOSoftwareStore.Menu) {
    }
}
export class NavMenuAdd implements Action {
    readonly type = NavMenu_Action_Type.NavMenuAdd;
    constructor(public payload: TOSoftwareStore.Menu) {
    }
}
export class NavMenuInitSuccess implements Action {
    readonly type = NavMenu_Action_Type.NavMenuInitSuccess;
    constructor(public payload: TOSoftwareStore.Menu[]) {
    }
}

export type ActionsNavMenu = NavMenuInitial | NavMenuInitSuccess |NavMenuUpdate | NavMenuRemove | NavMenuAdd ;
