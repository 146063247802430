import { Component, Input } from '@angular/core';




@Component({
  selector: 'FormBase',
  templateUrl: './ctl_form-base.component.html',
  styleUrls: ['./ctl_form-base.component.scss']
})

export class CtlFormBaseComponent {
@Input() headerTitle: string;
@Input() subtitle: string;
@Input() isForm: boolean;
@Input() showLeftDivider: boolean = true;
@Input() noTitle:boolean = false;


}
