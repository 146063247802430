import { Injectable } from '@angular/core';
import {ClsHeader} from "../../Store/models/clsModels";

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public header: ClsHeader = new ClsHeader();

  getAll(): ClsHeader {
    return this.header;
  }
  set(header: ClsHeader): ClsHeader {
    this.header = header;
    return this.header;
  }

}
