import { ClsNewCollection } from "../models/clsModels";
import { ActionsCollection, Collection_Action_Type } from "../action/index";
export interface Collection extends ClsNewCollection {}

export function CollectionReducer(
  state: ClsNewCollection[] = [],
  action: ActionsCollection
): Collection[] {
  switch (action.type) {
    case Collection_Action_Type.CollectionInitial:
      return { ...Object.values(state) };
    case Collection_Action_Type.CollectionAdd: {
      var objState = Object.values(state);
      const objAdd = action.payload;
      objState.push(objAdd);
      return { ...objState };
    }
    case Collection_Action_Type.CollectionRemove: {
      const objRemove = action.payload;
      const objState = Object.values(state);
      state = objState.filter(n => n.id !== objRemove.id);
      return { ...state };
    }
    case Collection_Action_Type.CollectionUpdate: {

      const objState = Object.values(state);
      const objUpdate = objState.filter(n => n.id !== action.payload.id)[0];
      if (objUpdate !== undefined && objUpdate !== null) {
        const index = objState.indexOf(objUpdate);
        objState[index] = action.payload;
      }
      return { ...objState };
    }
    case Collection_Action_Type.CollectionInitSuccess: {
      const obj = action.payload;
      return { ...obj };
    }

    default:
      return state;
  }
}

export const getCollectionData = (state: ClsNewCollection[]) => state;
