import {Component, OnInit, AfterViewInit, Input} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {CtlYoutubeVdoComponent} from '../home/youtube-video/youtube-video.component';
import {IFeed, ISlider} from "../../../../Store/intity/IModels";
import { ResponsiveSettings} from "ngx-owl-carousel-o";
import {InitializeService} from "@core";

@Component({
  selector: 'app-ctl-feed-slider',
  templateUrl: './feedslider.component.html',
  styleUrls: ['./feedslider.component.scss']
})
export class CtlFeedSliderComponent implements OnInit, AfterViewInit {
  _isShow: boolean;
  sliders: ISlider[];
  @Input() feed: IFeed;
  responsiveSetting: ResponsiveSettings = {};
  templates: { value: number, label: string }[] = [{label: 'Template 1', value: 1}, {
    label: 'Template 2',
    value: 2
  }];

  constructor(
    public sanitizer: DomSanitizer,
    public initialize: InitializeService,
    public dialog: MatDialog) {


  }

  setSliderOption(breakpoint: ResponsiveSettings) {
    setTimeout(() => {
      // this.initialize.sliderOption.responsive = {...breakpoint};
      this.feed.responsive = {...this.initialize.sliderOption, responsive: breakpoint};
    }, 300);
  }

  ngOnInit() {

    this.sliders = this.initialize.getDatByFeedId(this.feed.type, this.feed.feedid) as ISlider[];
    // this.setSliderOption(this.responsiveSettingDefault);


  }


  getVideoUrl(vid): SafeResourceUrl {
    this._isShow = true;
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + vid);
  }

  onSwipe(evt) {
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';


  }

  openDialog(e): void {
    const dialogRef = this.dialog.open(CtlYoutubeVdoComponent, {
      width: 'auto',
      data: {name: e}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
    // }

  }

  onIndexChange(e) {
    // this.directiveRef.swiper().updateSlides();
  }

  ngAfterViewInit() {

  }

}

