import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'DataTable',
  templateUrl: './ctl_data-table.component.html',
  styleUrls: ['./ctl_data-table.component.scss']
})

export class CtlDataTableComponent {

  @Input() headerTitle: string;
  @Input() subtitle: string;
  @Input() NoTitle: boolean = false;
  @Input() NotFound: boolean = false;
  constructor(private http: HttpClient) {




  }
}
