import {Injectable} from '@angular/core';
//import {publish} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
//import { LCUser} from "../Model/clsSMModel";
//import {Observable} from "rxjs";

import {OperationService} from "@core";
import {clsGetmodule, clsGetmodulePackage, clspricing} from "../Model/clsPricing";
import {clsBuynowGetmodule, clsBuynowGetmodulePackage, clsbuynow, clsAddonModuleGroup} from "../Model/clsBuynow";


@Injectable({
  providedIn: 'root',
})
export class svrPricing {
  public routeprefix = "licens-package";

  constructor(
    public http: HttpClient,
    public operation: OperationService,
  ) {

  }


  public async GetPricingPackage(): Promise<clspricing[]> {

    let getPackage = await
      this.http
        .get<clspricing[]>(this.operation.accessPointUrl + this.routeprefix + "/GetPricingPackage").toPromise();
    return getPackage;
  }

  public async GetModule(): Promise<clsGetmodule[]> {
    let GetModule = await
      this.http
        .get<clsGetmodule[]>(this.operation.accessPointUrl + this.routeprefix + "/GetModule").toPromise();
    return GetModule;
  }

  public async GetModulePackage(): Promise<clsGetmodulePackage[]> {
    let GetModulePackage = await
      this.http
        .get<clsGetmodulePackage[]>(this.operation.accessPointUrl + this.routeprefix + "/GetModulePackage").toPromise();
    return GetModulePackage;
  }

  public async GetBuynowModulePackage(): Promise<clsBuynowGetmodulePackage[]> {


    let GetBuyNowModulePackage = await
      this.http
        .get<clsBuynowGetmodulePackage[]>(this.operation.accessPointUrl + this.routeprefix + "/GetBuyNowModulePackage").toPromise();
    return GetBuyNowModulePackage;
  }
  public async onGetModuleGrp(packageid:number): Promise<clsAddonModuleGroup> {
    let GetBuyNowModuleGrp = await
      this.http
        .post<clsAddonModuleGroup>(this.operation.accessPointUrl + this.routeprefix + "/GetAddOnModuleGroup",packageid).toPromise();
    return GetBuyNowModuleGrp;
  }
}


