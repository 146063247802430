import {Component, Input, OnInit} from '@angular/core';
import {ClientUserProfileService} from "../../../../../register-app/Class/clientUserProfile.service";
import {clsNan} from "../../../../Class/svr-operation.service";
import {LCcustomerinfo} from "../../../../Model/clsCMModel";
import {OperationService} from "@core";
import {svrProduct} from "../../../../Controller/svrProduct";
import {clsitemmaster} from "../../../../Model/clsICModule";

@Component({
  selector: 'app-frm-extend-licese',
  templateUrl: './frm-extend-licese.component.html',
  styleUrls: ['./frm-extend-licese.component.css']
})
export class FrmExtendLiceseComponent implements OnInit {
  @Input() data: LCcustomerinfo = new LCcustomerinfo();
  objFormData: {
    objLicense: LCcustomerinfo,
    objitem :clsitemmaster
  } = {
    objLicense: new LCcustomerinfo(),
    objitem:new clsitemmaster()
  }

  expDate: Date;
  inputMonths: number = 0;
  Config = {
    displayKey: 'itemdesc', // if objects array passed which key to be displayed defaults to description
    search: false,
    limitTo: 3,
    height: '260px',
  }
  options: any = []

  constructor(
    public svrCientUserProfile: ClientUserProfileService,
    public svrOpt: OperationService,
    public svrPro: svrProduct
  ) {
  }

  async ngOnInit() {
    let ispack = true;
    let dataPack = await this.svrPro.onGetTotalPro(ispack)
    if (dataPack.length > 0){
      this.options = dataPack;
      this.objFormData.objitem = dataPack.find(e=>e.itemid==this.data.erptype);
    }
    if (!clsNan.isNullOrUndefined(this.data)) {
      this.objFormData.objLicense = this.data;
    }
  }
// Function to compare two dates
   compareFullDates(date1: Date, today: Date): number {
    // Compare years
    const yearDiff = date1.getFullYear() - today.getFullYear();
    if (yearDiff !== 0) {
      return yearDiff;
    }

    // Compare months
    const monthDiff = date1.getMonth() - today.getMonth();
    if (monthDiff !== 0) {
      return monthDiff;
    }

    // Compare days
    const dayDiff = date1.getDate() - today.getDate();
    if (dayDiff !== 0) {
      return dayDiff;
    }

    // Compare hours
    const hourDiff = date1.getHours() - today.getHours();
    if (hourDiff !== 0) {
      return hourDiff;
    }

    // Compare minutes
    const minuteDiff = date1.getMinutes() - today.getMinutes();
    if (minuteDiff !== 0) {
      return minuteDiff;
    }

    // Compare seconds
    const secondDiff = date1.getSeconds() - today.getSeconds();
    if (secondDiff !== 0) {
      return secondDiff;
    }

    // If all components are equal, the dates are equal
    return 0;
  }


  onInputMonths(month) {
    this.expDate = new Date(this.objFormData.objLicense.expiredate);
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    const comparisonResult = this.compareFullDates(this.expDate, today);
    if (comparisonResult < 0) {
      this.expDate = today;
      this.expDate.setMonth(today.getMonth() + parseInt(month));
      this.objFormData.objLicense.month = parseInt(month);
      this.objFormData.objLicense.newexpiredate = this.expDate;
    } else {
      this.expDate.setMonth(this.expDate.getMonth() + parseInt(month));
      this.objFormData.objLicense.month = parseInt(month);
      this.objFormData.objLicense.newexpiredate = this.expDate;
    }
  }


}
