
import { Action } from '@ngrx/store';
import * as TOSoftwareStore from '../models/clsModels';
export enum  Init_Action_Type {
    LoadInitial = 'LoadInitial',
    LoadSuccess= 'LoadSuccess'
}
export class LoadInitial implements Action {
    readonly type = Init_Action_Type.LoadInitial;
    constructor() {
    }
}
export class LoadSuccess implements Action {
    readonly type = Init_Action_Type.LoadSuccess;
    constructor(public payload: TOSoftwareStore.InitSate) {
    }
}

export type ActionsInit = LoadInitial | LoadSuccess ;
