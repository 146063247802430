import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { MenuEditor_Action_Type, MenuEditorInitSuccess } from "../action";
import { switchMap, map, catchError, takeUntil } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { Menu } from "../models/clsModels";
import {InitializeService, OperationService} from "@core";

@Injectable()
export class MenuEditorEffect {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private actions: Actions,
    private initialize: InitializeService,
    private srvOperation: OperationService
  ) {}

  @Effect()
  LOAD_INITIALIZE = this.actions.pipe(
    ofType(MenuEditor_Action_Type.MenuEditorInitial),
    switchMap(({menuId}) => {
      return this.initialize.getMenLocalStorage().pipe(
        map((obj: Menu[]) => {
          let result = obj.filter(n => n.id === menuId);
          if (result !== undefined) {
            return new MenuEditorInitSuccess(result[0].children);
          }
          return new MenuEditorInitSuccess([]);
        }),
        takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
  @Effect()
  ADD_INITIALIZE = this.actions.pipe(
    ofType(MenuEditor_Action_Type.MenuEditorAdd),
    switchMap(({menuId, payload}) => {
      return this.initialize.addMenuChild(payload).pipe(
        map((obj: Menu[]) => {
          let result = obj.filter(n => n.id === menuId);
          if (result === undefined) {
            return new MenuEditorInitSuccess([]);
          }
          return new MenuEditorInitSuccess(result[0].children);
        }),
        takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
  @Effect()
  REMOVE_INITIALIZE = this.actions.pipe(
    ofType(MenuEditor_Action_Type.MenuEditorAdd),
    switchMap(({menuId, payload}) => {
      return this.initialize.deleteMenuChild(payload).pipe(
        map((obj: Menu[]) => {
          let result = obj.filter(n => n.id === menuId);
          if (result === undefined) {
            return new MenuEditorInitSuccess([]);
          }
          return new MenuEditorInitSuccess(result[0].children);
        }),
        takeUntil(this.ngUnsubscribe),
        catchError(error => of())
      );
    })
  );
}
