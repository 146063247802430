import {LCCompany, LCRoleModule, LCUser} from './clsSMModel';
import {clsUserSetup} from '../../../Store/models/clsModels';
import {clsPending, clsRequestmaster} from './clsOLModel';

export class clsInit {
  objCompany: LCCompany;
  objUser: LCUser;
  objTimezone: clsTimezone;
  objPending: clsRequestmaster;
  lstPrivilege: LCRoleModule[];
  constructor() {
    this.objCompany = new LCCompany();
    this.objUser = new LCUser();
    this.objTimezone = new clsTimezone();
    this.lstPrivilege = [];
    this.objPending = new clsRequestmaster();
  }
}

export class clsTimezone {
  tzid: number;
  tzoffset: string;
  tzdesc: string;
  tzcode: string;

  constructor() {
    this.tzoffset = '+07:00';
    this.tzdesc = '(GMT+07:00) Bangkok, Christmas, Davis, Hanoi, Hovd, Jakarta, Moscow+04-Krasnoyarsk';
    this.tzcode = 'Asia/Bangkok';
  }
}
export class clsLogin {
  username: string;
  pwd: string;
  store: string;
  whid: number;
  roleid: number;
  secure_hash: string;
  isAuto: boolean;

  constructor() {
    this.username = '';
    this.pwd = '';
    this.whid = 0;
    this.secure_hash = '';
    this.isAuto = false;
    this.roleid = 0;
  }
}
export class clsCards {
  name: string;
  icon: string;
  fontawesomeCode: string;
  route: string;
  type?: string;
  subTitle?: string;
  rpttype ?: string;
  state?:string;
}
export class LCTotalField {
  findBy: string; //sort condition
  sortField: string; //sort on other field
  sortBy: number = -1; //sort A-Z or Z-A
  findText: string; // search Text

}
export class LCRecord {
  from: any;
  to: any;
  findText: string;
  sortField :string;
  sortBy: number;
  findBy: string;
  constructor() {
    this.findText = '';
    this.from = 0;
    this.to = 0;
    this.sortField = '';
    this.sortBy = -1 ;
  }
}

export class clsMenuItems {
  label: string;
  id: any;
  svg: boolean;
  hidden: boolean;
  data?: any;
  icon: string;
  divider: boolean;
}



