import { Injectable } from '@angular/core';
import {ClsNewCollection} from "../../Store/models/clsModels";
@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  public Collection: ClsNewCollection[] = [];
  public SelectedCollection: ClsNewCollection = new ClsNewCollection();
  setCollectionSelected(id: number) {
    this.SelectedCollection = this.Collection.filter(n => n.id === id)[0];
    return this.SelectedCollection;
  }
  getAll(): ClsNewCollection[] {
    return this.Collection;
  }
  update(Collection: ClsNewCollection[]): ClsNewCollection[] {
    this.Collection = Object.values(Collection);
    return this.Collection;
  }
  set(Collection: ClsNewCollection[]): ClsNewCollection[] {
    this.Collection = this.Collection.concat(Collection);
    return this.Collection;
  }
  remove(Collection: ClsNewCollection) {
    this.Collection = this.Collection.filter(n => n.id !== Collection.id);
    return this.Collection;
  }

  add(Collection: ClsNewCollection) {
    this.Collection.push(Collection);
  }

}
