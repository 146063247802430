export class clspricing{
  active: boolean;// active
  packageid: string;
  itempackageid: string;
  packageName: string;
  price: string;
  disc: number;
  duration: string;
  //lstick: [];

}
export class clsGetmodule{
  moduledesc: string;
}
export class clsGetmodulePackage {
  modulid: number;
}
