import {NgModule, APP_INITIALIZER} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS,} from '@angular/common/http';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {ThemeModule} from './theme/theme.module';
import {RoutesModule} from './/routes.module';
import {AppComponent} from './app.component';
import {DefaultInterceptor, InitializeService, StartupService} from './core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment.prod';
import {OperationService, OperationData} from './core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducers} from './Store/reducer';
import {effects} from './Store';
import {StorageMap} from '@ngx-pwa/local-storage';
import {JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import {AuthGuard, LicenseGuard} from './core/services/authGaurd';
import {TokenInterceptor} from './core/services/tokenInterceptor';
import * as Hammer from 'hammerjs';
import {FacebookModule} from 'ngx-facebook';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {InterfaceService} from "./core/services/interface.service";
import {NavService} from "./shared/services/nav.service";
import {SvrEventService} from "./core/services/svr-events.service";
import {svrStoredataService} from "./core/services/svr-storedata.service";
import {from} from 'rxjs';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from "angularx-social-login";
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';


export function StartupServiceFactory(Service: StartupService) {
  return () => {
    return Service.load();
  };

}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL},
  } as any;
}

export function tokenGetter(): string {
  return localStorage.getItem('tosoftwarejwt');
}

export function jwtOptionsFactory(dbStorage: StorageMap) {
  return {
    tokenGetter: () => {
      return dbStorage.get('tosoftwarejwt');
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    RoutesModule,
    ThemeModule,
    FacebookModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot({}),
    StoreModule.forFeature('toSoftware', reducers),
    EffectsModule.forRoot(effects),
    EffectsModule.forFeature(effects),

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['https://192.168.4.205:5001', 'http://192.168.4.205:5001'],
        blacklistedRoutes: ['https://192.168.4.205:5001/api/auth/login'],
        throwNoTokenError: true,
        headerName: 'T.O Software',
        skipWhenExpired: true
      },
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [StorageMap],
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    DatePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              'clientId'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
    {provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    LicenseGuard,
    AuthGuard,
    SvrEventService,
    svrStoredataService,
    StartupService,
    NavService,
    OperationService,
    InterfaceService,
    InitializeService,
    OperationData,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// tokenGetter: () => tokenGetter(),
