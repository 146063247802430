

export  class LCCustomer{
  compid: number;
  cusid: number;
  cuscode: string;
  compdesc: string ;
  gender: string;
  active: boolean;
  fname: string;
  lname: string;
  phone: string;
  address:string;
  storename: string;
  email: string;

  constructor(compid?: number,cusid?:number,cuscode?:string,compdesc?:string,gender?:string,active?:boolean,fname?:string,lname?:string,
              phone?: string,address?:string,storename?: string,email?:string) {
    this.compid = compid ?? 0;
    this.cusid = cusid ?? 0;
    this.cuscode = cuscode ?? '';
    this.compdesc = compdesc ?? '';
    this.gender = gender ?? '';
    this.active = active ?? true;
    this.fname = fname ?? '';
    this.lname = lname ?? '';
    this.phone = phone ?? '';
    this.address = address ?? '';
    this.storename = storename ?? '';
    this.email = email ?? '';

  }
}

export  class LCProduct{
  compid: number;
  proid : number ;
  code : string ;
  prodesc:string;
  price :number;
  factor:number;
  unitid : number;
  payfrequency:string;
  protype:string;
  selected: boolean = false;
  userid: number;
  proModule:boolean;
  adddate: any;
  exptype: string;
  qty: number;
  active:boolean;
  amount: number;

  edtdate: any;
  edtuser: number;

  constructor(compid?: number,proid?:number,code?:string,prodesc?:string,factor?:number,unitid?:string,active?:boolean,
              selected?:boolean,exptype?:string,qty?:number,amount?:number) {
  this.code = code ?? '';
  this.prodesc = prodesc ?? '';
  this.factor = factor ?? 0;
  this.active = true;
  this.selected = selected;
  this.exptype = exptype ?? '';
  this.qty = qty ?? 0;
  this.amount = amount ?? 0;
  }
}

 export class LCcustomerinfo
{
 clientuserid :number;
 clientusercode :string;
 compname:string;
 email:string;
 phone:string;
 fullname:string;
 active:boolean;
 address:string;
 clientid:string;
 prodesc:string;
 expiredate:Date;
 expireday:number;
 remaindday: number;
 proid:number;
 erptype:number;
 month:number;
 newexpiredate: Date;
  packagedesc: string;
  form_type :string;
}
