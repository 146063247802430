import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {FormBuilder, FormControl} from '@angular/forms';
import {clsNan, MenuService} from '@core';
import {Menu} from '../../../Store/models/clsModels';

export interface IRouteResponse {
  total: number;
  menu: Menu[];
}

@Component({
  selector: 'app-searching-pag',
  templateUrl: './searching-pag.component.html',
  styleUrls: ['./searching-pag.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchingPagComponent implements OnInit {
  filteredRoute: { state: string, value: string, name: string, icon: string }[] = [];
  stateCtrl: FormControl;
  isLoading = false;
  state = '';

  constructor(public router: Router,
              private fb: FormBuilder,
              private http: HttpClient,
              public menuService: MenuService) {
    this.stateCtrl = new FormControl();

    this.stateCtrl.valueChanges.subscribe(el => {
      let _state = '';
      if (!clsNan.isNullOrUndefined(el) && el.state != null)
      {
        _state = el.state;
      }
      else
        _state = el;
      this.filterStates(_state.toLowerCase());



    });

    /*this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => {
          console.log("---------->",state);
        return  this.filterStates(state.toLowerCase());
        })).subscribe(n => { });*/
  }

  displayName = '';

  filterStates(name: string) {
    // const path = this.filteredRoute.filter(state =>
    //   state.value.toLowerCase().indexOf(name.toLowerCase()) === 0);
    this.filteredRoute.forEach(elf=>{
      /*  console.log("filter---->",elf,name);*/
    });
    const path = this.filteredRoute.filter(n => n.state.toLowerCase() === name.toLowerCase());
    /*console.log("test path---->",path,this.filteredRoute,name);*/

    if (path.length > 0) {

      //console.log("do navigate----->",path);
      this.router.navigate([path[0].state]).then();
    } else {
      //console.log("do get all route----->",path,this.menuService.menu);
      this.filteredRoute.splice(0, this.filteredRoute.length);
      return this.getAllRoute(this.menuService.menu, name);
    }

  }

  customDisplayMenu(e) {

    //console.log("II",this.svrtranslate)
    if (clsNan.isNullOrUndefined(e))
      return '';
    return e.name ?? '';
    //return e.name;

    //return this.svrTranslate.instant(e.name);
  }


  getAllRoute(arr: any, filter) {

    //console.log("Filter state---->",filter);

    arr.map((item: Menu) => {

      if (item.state.toLowerCase().includes(filter)) {
        this.filteredRoute.push({
          state: this.state + '/' + item.state,
          value: item.state,
          name: (item.name),
          icon: item.icon,
        });

      } else if (Array.isArray(item.children)) {

        this.state += '/' + item.state.replace(/^\/+|\/+$/g, '');

        let nestedChildItem = [].concat(...item.children);

        this.getAllRoute(nestedChildItem, filter);
        this.state = '';
      }
    });

  }

  ngOnInit() {
    // console.log('I', this.svrtranslate);
  }

}
