import {
  Component,
  Input,
  forwardRef,
  AfterViewChecked, ViewChild, ElementRef, OnInit, Output, EventEmitter, AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar, MenuItem } from 'primeng';
import * as moment from "moment";
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import {PpDatetimeFormatPipe} from "../../pipes/pp-datetime-format.pipe";
import {SvrDataService} from "../../projects/license-app/Class/svr-data.service";
import {clsNan} from "../../projects/license-app/Class/svr-operation.service";


export const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtlDateTimePickerEditComponent),
  multi: true,
};

@Component({
  selector: 'DateTimePickerEdit',
  templateUrl: './ctl-datetime-picker-edit.component.html',
  providers: [VALUE_ACCESSOR],
  styleUrls: ['./ctl-datetime-picker-edit.component.scss'],
})

export class CtlDateTimePickerEditComponent implements ControlValueAccessor, AfterViewChecked, AfterViewInit {
  @ViewChild(Calendar, { static: false }) ctlDateTime: Calendar;
  @Input() showTime: boolean;
  @Input() isTimeOnly: boolean = false;
  @Input() showOnFocus: boolean = true;
  @Input() hourFormat: string = '12';
  @Input() showIcon: boolean = false;
  @Input() readonlyInput: boolean = true;
  @Input() yearRange: string = '1001:3000';
  @Input() label: string = '';
  @Input() isAlignRight = false;
  @Input() disabled = false;
  @Input() dateType: string = '';

  @Input() dtFrom: CtlDateTimePickerEditComponent;
  @Input() DB_Key: string = '';
  @Input() isShowDrop: boolean = false;
  @Output() onBetweenDateChange: EventEmitter<any> = new EventEmitter<any>();

  dateFormat: string = 'yy/mm/dd';
  timeFormat: string = '12';
  containerPicker: HTMLInputElement;
  private isLoaded: boolean = false;

  lbltooltip: string = '';
  private objDate_cache: any;

  constructor(private svrdatetime: PpDatetimeFormatPipe,

              private elref: ElementRef,
              private svrData: SvrDataService,
              public svrTranslate: TranslateService) {
    this.timeFormat = this.svrdatetime.primeTimeTranform();

    //this.dateFormat = this.svrCompany.lstDatePrimeng[this.svrData.objInit.objCompany.datetimeformat.dateformatorder].format;

  }


  doMouseClick(e) {
    if (this.showOnFocus == false) {
      this.ctlDateTime.toggle();
    }
  }

  public doFocus() {
    let txtInput = this.elref.nativeElement.querySelector('input') as HTMLInputElement;
    //txtInput.setSelectionRange(0, txtInput.value.length, 'forward');
    txtInput.focus();
  }


  public innerValue: Date;

  /*get date(): Date {
    return this.innerValue;
  }

  set date(v: Date) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.change(v);
    }
  }*/

  onTouched = (_: any) => {
  };
  onChange = (_: any) => {
  };

  writeValue(date: Date) {
    if (date !== this.innerValue) {
      this.innerValue = date;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(date: Date) {
    this.innerValue = date;
    this.onChange(date);
    this.onTouched(date);
  }

  ngAfterViewInit(): void {
    this.onGetDropSelected();

    if (this.isShowDrop) {
      this.svrTranslate.onLangChange.subscribe(e => {
        if (!clsNan.isNullOrUndefined(this.lbltooltip)) {
          this.lbltooltip = this.svrTranslate.instant(this.objDate_cache.key);
        }
      });
    }
  }

  ngAfterViewChecked() {
    if (!this.isLoaded) {

      this.isLoaded = true;
      this.timeFormat = this.svrdatetime.primeTimeTranform();

      //this.dateFormat = this.svrCompany.lstDatePrimeng[this.svrData.objInit.objCompany.datetimeformat.dateformatorder].format;

      this.onUpdateUI();
    }

  }

  onUpdateUI() {
    this.ctlDateTime.updateInputfield();
  }

  onClose(e) {
    setTimeout(() => {
      try {
        this.containerPicker.style.overflow = 'auto';
      } catch {

      }
    });
  }

  public offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  onShow(e) {

    /*setTimeout(() => {
      this.containerPicker = document.getElementById('staticContainer') as HTMLInputElement;
      this.containerPicker.style.overflow = 'unset';

    }, 0);*/


    setTimeout(() => {

      let txtInput = this.elref.nativeElement.querySelector('#txtInput') as HTMLInputElement;
      let pnShowCalendar = this.elref.nativeElement.querySelector('.pnShowCalendar') as HTMLDivElement;




      let inputRec = txtInput.getBoundingClientRect();
      let documentRec = document.body.getBoundingClientRect();
      let panelRec = pnShowCalendar.getBoundingClientRect();


      let headerFormSize = 130;

      let _psTop = inputRec.top + inputRec.height;

      if ( _psTop + panelRec.height  >= documentRec.height) {






        if(inputRec.top - panelRec.height>headerFormSize)
          _psTop = -panelRec.height-inputRec.height - 15;
        else
          _psTop =  documentRec.height - (inputRec.top + inputRec.height + panelRec.height)-30;


        // if(inputRec.top - panelRec.height>headerFormSize)
        //   _psTop = inputRec.top - panelRec.height
        // else
        //   _psTop = -inputRec.top+headerFormSize;



        pnShowCalendar.style.top = _psTop.toString() + 'px';
      }
      else
        pnShowCalendar.style.top = "-13px";





      // if (pnShowCalendar.offsetTop < 0) {
      //   pnShowCalendar.style.top = (pnShowCalendar.offsetTop + 160).toString() + 'px';
      // }
      if (this.isAlignRight) {

        let overwidth = pnShowCalendar.offsetWidth - txtInput.offsetWidth;
        pnShowCalendar.style.left = (pnShowCalendar.offsetLeft - overwidth).toString() + 'px';
      }

    }, 100);
  }


  /**
   * Activate when using @isShowDrop option
   * @onGetDropSelected use for getting exist option from local-storage
   */
  onGetDropSelected() {
    if (this.isShowDrop) {
      const date_id = parseInt(localStorage.getItem(this.DB_Key) ?? '3');
      this.onDropSelected(date_id);
    }
  }

  /**
   * @onDropSelected use for getting date by id
   * @param id : number
   */
  onDropSelected(id: number) {
    this.objDate_cache = this.items.find(e => e.id == id);
    this.lbltooltip = this.svrTranslate.instant(this.objDate_cache.key);

    const _dateRange = CtlDateTimePickerEditComponent.onGetMomentDate(id, this.DB_Key);

    if (!clsNan.isNullOrUndefined(_dateRange)) {
      _dateRange.start_date.setHours(0, 0, 0, 0);
      _dateRange.end_date.setHours(23, 59, 59, 0);
      /*      this.innerValue = _dateRange.end_date;
            if (!clsNan.isNullOrUndefined(this.dtFrom)) {
              this.dtFrom.change(_dateRange.start_date);
            }*/
      this.onBetweenDateChange.emit(_dateRange);
    }

  }

  /**
   * @onGetMomentDate for calculating date range
   * @param id for calculate date
   * @param db_key for saving to local-storage
   * id => 1 = today, 2 = yesterday, 3 = this week, 4 = this month, 5 = last week, 6 = last month
   */
  private static onGetMomentDate(id: number, db_key: string): { start_date: Date, end_date: Date, disabled: boolean } {

    /*localStorage.setItem(db_key, id.toString());*/

    let objDate: { start_date: string, end_date: string } = null;

    if (id == 1 || id == 0) {
      objDate = {
        start_date: moment().toString(),
        end_date: moment().toString(),
      };
    }
    if (id == 2) {
      objDate = {
        start_date: moment().subtract(1, 'days').toString(),
        end_date: moment().subtract(1, 'days').toString(),
      };
    } else if (id == 3) {
      objDate = {
        start_date: moment().startOf('isoWeek').toString(),
        end_date: moment().endOf('isoWeek').toString(),
      };
    } else if (id == 4) {
      objDate = {
        start_date: moment().startOf('month').toString(),
        end_date: moment().endOf('month').toString(),
      };
    } else if (id == 5) {
      objDate = {
        start_date: moment().subtract(1, 'weeks').startOf('isoWeek').toString(),
        end_date: moment().subtract(1, 'weeks').endOf('isoWeek').toString(),
      };
    } else if (id == 6) {
      objDate = {
        start_date: moment().subtract(1, 'month').startOf('month').toString(),
        end_date: moment().subtract(1, 'month').endOf('month').toString(),
      };
    }

    if (clsNan.isNullOrUndefined(objDate)) {
      return null;
    }

    return { start_date: new Date(objDate.start_date), end_date: new Date(objDate.end_date), disabled: id > 0 };
  }


  items: any[] = [
    {
      id: 1, label: 'Today', key: 'Today', command: () => {
        this.onDropSelected(1);
      },
    },
    {
      id: 2, label: 'Yesterday', key: 'Yesterday', command: () => {
        this.onDropSelected(2);
      },
    },
    {
      id: 3, label: 'This_Week', key: 'This_Week', command: () => {
        this.onDropSelected(3);
      },
    },
    {
      id: 4, label: 'This_Month', key: 'This_Month', command: () => {
        this.onDropSelected(4);
      },
    },
    {
      id: 5, label: 'Last_Week', key: 'Last_Week', command: () => {
        this.onDropSelected(5);
      },
    },
    {
      id: 6, label: 'Last_Month', key: 'Last_Month', command: () => {
        this.onDropSelected(6);
      },
    },
    {
      id: 0, label: 'Custom', key: 'Custom', command: () => {
        this.onDropSelected(0);
      },
    },
  ];

  onDropDown() {
    this.items.map(n => {
      n.label = this.svrTranslate.instant(n.key);
    });
    this.items = [...this.items];

  }
}
