import {Component, Input, OnInit, Output} from '@angular/core';
import {EventEmitter} from "events";

@Component({
  selector: 'app-to-check-box',
  templateUrl: './to-check-box.component.html',
  styleUrls: ['./to-check-box.component.scss']
})
export class ToCheckBoxComponent implements OnInit {

  @Input() width: string;
  @Input() height: string;

  checkVal: string;


  @Input()
  get check() {
    return this.checkVal;
  }

  @Output() checkChange = new EventEmitter();

  // tslint:disable-next-line:adjacent-overload-signatures
  set check(val) {
    this.checkVal = val;
    this.checkChange.emit(val);
  }



  constructor() { }

  ngOnInit(): void {
  }

}
