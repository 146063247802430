import { ClsCollectionItem } from "../models/clsModels";
import { ActionsCollectionItem, CollectionItem_Action_Type } from "../action/index";
export interface CollectionItem extends ClsCollectionItem {}

export function CollectionItemReducer(
  state: ClsCollectionItem[] = [],
  action: ActionsCollectionItem
): CollectionItem[] {
  switch (action.type) {
    case CollectionItem_Action_Type.CollectionItemInitial:
      return { ...Object.values(state) };
    case CollectionItem_Action_Type.CollectionItemAdd: {
      var objState = Object.values(state);
      const objAdd = action.payload;
      objState.push(objAdd);
      return { ...objState };
    }
    case CollectionItem_Action_Type.CollectionItemRemove: {
      const objRemove = action.payload;
      const objState = Object.values(state);
      state = objState.filter(n => n.id !== objRemove.id);
      return { ...state };
    }
    case CollectionItem_Action_Type.CollectionItemUpdate: {
      const objState = Object.values(state);
      const objUpdate = objState.filter(n => n.id !== action.payload.id)[0];
      if (objUpdate !== undefined && objUpdate !== null) {
        const index = objState.indexOf(objUpdate);
        objState[index] = action.payload;
      }
      return { ...objState };
    }
    case CollectionItem_Action_Type.CollectionItemInitSuccess: {
      const obj = action.payload;
      return { ...obj };
    }

    default:
      return state;
  }
}

export const getCollectionItemData = (state: ClsCollectionItem[]) => state;
