export class clsBusinesstype {
  bussid : number;
  bussdesc : string
}
export class clsWhrange {
  whrangeid : number;
  whrangedesc : string
}
export class LCSetting {
  alertbtnexpire :number
  userid:number
  limitday:number
}
