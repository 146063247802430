import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Columns, Config} from 'ngx-easy-table';
import {IContent, IFeed} from "../../../../Store/intity/IModels";
import {clsContent} from "../../../../Store/models/clsModels";
import {InitializeService} from "@core";

@Component({
  selector: 'app-feed-content',
  templateUrl: './feed-content.component.html',
  styleUrls: ['./feed-content.component.scss'],
})
export class CtlFeedContentComponent  implements OnInit {

  public configuration: Config;
  public columns: Columns[];
  thumbnail: string = '';
  @Input() itemIndex: number;
  @Input() feed: IFeed;
  contents: IContent[];
  @Output() onItemAdd: EventEmitter<any> = new EventEmitter<any>();


  constructor(

    public initialize: InitializeService,
   ) {



  }



  ngOnInit() {
    //this.collectionsGroups = this.initialize.getDatByFeedId(this.feed.type, this.feed.feedid) as clsCollectionGroup[];

    this.contents = this.initialize.getDatByFeedId(this.feed.type, this.feed.feedid) as clsContent[];
  }




}
