import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-ctl-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class CtlMenuBarComponent implements OnInit {


  constructor() {
   
  }

  ngOnInit() { }
}

