import { Injectable } from '@angular/core';
import {LCPartner} from "../Model/clsCMModel";

@Injectable({
  providedIn: 'root'
})
export class SvrDataServiceRegister {
  public domainERP = ""
  public popupParam = {};
  public  onCustomerData = new LCPartner();
  public initalizestatus=2;
  public resenddata=null;
  constructor() {

  }


}

export enum enumRegisterStatus {
  ErrorSendMail = -4,
  NotFound = -3,
  DuplicateCode = -2,
  InUsed = -1,
  InComplete = 0,
  Complete = 1,
  isactivated=2,
  isinitialized=3,
}

