import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OperationService} from "@core";


@Injectable({
  providedIn: 'root'
})
export class SvrSMWhRangeService {
  public routeprefix = "license/sm_whrange"
  constructor(private http: HttpClient,
              private operation: OperationService) {

  }
  async onGetWhRange(): Promise<any> {
    const url = await this.http
      .get(this.operation.accessPointUrl + this.routeprefix + "/GetWhRange")
      .toPromise()
    return url;
  }

}



