export class clsitemmaster{
  compid :number
  itemid :number
  itemmoduleid :number
  itemcode :string
  itemdesc :string
  itemtype :number
  price :number
  qty : number
  amount:number;
  paytype :number
  ismodule :boolean
  active :boolean
  selected: boolean;
  constructor(compid?:number,itemid?:number,itemmoduleid?:number,itemcode?:string,itemdesc?:string,itemtype?:number,price?:number,paytype?:number,
              ismodule?:boolean,active?:boolean,selected?:boolean) {
    this.compid = compid ?? 0;
    this.itemid = itemid ?? 0;
    this.itemmoduleid = itemmoduleid ??0;
    this.itemcode = itemcode ?? '';
    this.itemdesc = itemdesc ?? '';
    this.itemtype = itemtype ?? 0;
    this.price = price ?? 0;
    this.paytype = paytype ?? 0;
    this.active = active ?? true;
    this.selected = selected ?? false;

  }
}
export class clsitemmodule{
  compid :number
  itemmoduleid :number
  moduleid :number
  itemmoduledesc :string
}
export class clsitemmodulegroup{
  compid :number
  itemmodulegrpid :number
  itemmoduleid:number
  itemmoduledescgrp :string
}
export class LCProductSuggestWrap {
  lastdate: Date;
  data: clsProductSuggest[];


}

export class clsProductSuggest{
  compid :number
  itemid: number;
  itemmoduleid: number;
  itemcode: string;
  itemdesc :string
  itemtype: number;
  price: number;
  amount: number;
  paytype:number
  constructor(itemcode?:string,itemdesc?:string, price?:number) {
    this.itemcode = itemcode ??'';
    this.itemdesc = itemdesc ?? '';
    this.price = price ?? 0;
  }
}

export class clsCustomerSuggest{
  compid: number;
  clientuserid: number;
  clientid: string;
  clientusercode: string;
  fullname : string;
  compname: string;
  email : string;
  contact: string;
  constructor(compid?: number,clientuserid?:number,clientid?:string,clientusercode?:string,compname?:string,_fullname?:string,
              email?:string, contact?:string) {
    this.compid = compid ?? 0;
    this.clientuserid = clientuserid ?? 0;
    this.clientid = clientid ?? '';
    this.clientusercode = clientusercode??'';
    this.compname = compname ?? '';
    this.fullname = _fullname ?? '';
    this.email = email ?? '';
    this.contact = contact ?? '';

  }

}
