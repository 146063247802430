import {ClsHeader} from '../models/clsModels';
import {ActionsHeader, Header_Action_Type} from '../action/index';
export interface Header extends ClsHeader { }
const headerStates: Header = {id: 0, companyName: '', email: '', phone: '', logo: '', menu: [],socialMedia: []};

export function headerReducer(
  state = headerStates,
  action: ActionsHeader): Header {
  switch (action.type) {
    case Header_Action_Type.HeaderInitial:
      return {...state};
    case Header_Action_Type.HeaderSuccess:
      const obj = action.payload;
      state = obj;
      return {...state};

    default:
      return state;
  }
}

export const getHeaderData = (state: ClsHeader) => state;
