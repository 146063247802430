import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MenuService, OperationService} from '../../../core';
import {CtlConsoleNavMenuComponent} from "../../../projects/website-app/console/ctl-nav-menu/ctl-nav-menu.component";

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidemenuComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;
  // menuService: any[] = [];
  // menus = this.menuService.getAll();

  constructor(public svrOpt: OperationService, public menuService: MenuService) {

    // this.svrOpt.GetHeaderMenu().subscribe(mainheader => {
    //   this.menuService = mainheader;
    //
    // });

  }

  ChangeState() {
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(CtlConsoleNavMenuComponent);
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  // Delete empty values and rebuild state
  buildState(states: string[]) {
    let state = '';
    states.forEach(item => {
      if (item && item.trim()) {
        state += '/console/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return state;
  }
}
