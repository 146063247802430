import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Email} from "../../Model/clsCMModel";
import {enumRegisterStatus, SvrDataServiceRegister} from "../../Class/svr-data.service";
import {ClientUserProfileService} from "../../Class/clientUserProfile.service";
import {OperationService} from "@core";
import {NgxSpinnerService} from "ngx-spinner";
@Component({
  selector: 'app-ctl-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss']
})
export class CtlResendEmailComponent implements OnInit {
  isSignup: boolean;
  objEmail:Email = new Email()

  constructor( public svrCientUserProfile: ClientUserProfileService,
              public route: ActivatedRoute,
               private svrOpt: OperationService,
              public svrDataReg: SvrDataServiceRegister,
               private svrSpinner: NgxSpinnerService,
          ) {
  }

  async ngOnInit() {
    await this.svrSpinner.hide()
    this.objEmail.ToMail = this.svrDataReg.resenddata.email
    this.objEmail.subdomain = this.svrDataReg.resenddata.domain
    this.objEmail.verifycode = this.svrDataReg.resenddata.code
  }
  async onResend(){
    await this.svrSpinner.show();
    let e = await this.svrCientUserProfile.onResendMail(this.objEmail)
    if(e==enumRegisterStatus.Complete){
      await this.svrSpinner.hide()
      return this.svrOpt.onToastSuccess('Send Success');
    }


  }

}

