import {
  IBlog,
  IFeed,
  IHeader,
  IItem,
  IMenu,
  IMenuGroup, ICollectionDetail, ICollection, ICollectionGroup, ISlider, ICollectionDetailDescriptionList, IContent
} from "../intity/IModels";
import {OwlOptions} from "ngx-owl-carousel-o";


export class ClsSolutions {
  src: string;
  title: string;
  subTitle?: string;


}

export class ClsVideoBlock {
  src: string;
  title: string;
  subTitle?: string;
}

export class clsCounter {
  src: string;
  desc: string;
  qty: number;
  background ?: string;
}

export class ClsRetailFeature {
  src: string;
  title: string;
  description: string[];
  buttonCaption: string;
  href?: string;
  isLeft: boolean;
  readmore ?: boolean

}

export class ClsRestaurantFeature {
  src: string;
  title: string;
  description: string[];
  buttonCaption: string;
  href?: string;
  isLeft: boolean;
  readmore: boolean;
}

export class ClsWhatWeSell {
  src: string;
  title: string;
  subtitle: string;
}

export class ClsTag {
  color: string; // Background Color
  value: string;
}

export class ClsChildrenItem {
  id?: any;
  state?: string;
  name: string;
  templateId: number;
  icon?: "";
  type: "link" | "sub";
  children?: ClsChildrenItem[];
}

export class ClsNewCollection {
  id: number;
  title: string;
  icon?: string;
  parent: string;

  constructor(id?: number, title?: string, icon?: string, parent?: string) {
    id = id;
    title = title;
    icon = icon;
    parent = parent;
  }
}

export class ClsCollectionItem {
  id?: number;
  title?: string;
  singleSubTitle?: string;
  multiSubTitle?: string[];
  isMultiText?: boolean;
  icon?: string;
  src?: string;
  parent?: string;
  type?: string;

  constructor(
    id?: number,
    title?: string,
    singleSubTitle?: string,
    multiSubTitle?: string[],
    isMultiText?: boolean,
    icon?: string,
    src?: string,
    parent?: string,
    type?: string
  ) {
    id = id;
    title = title;
    singleSubTitle = singleSubTitle;
    multiSubTitle = multiSubTitle;
    isMultiText = isMultiText;
    icon = icon;
    src = src;
    parent = parent;
    type = type;
  }
}

export class Menu {
  id?: any;
  state?: string;
  name: string;
  type: "link" | "sub";
  icon: string;
  label?: ClsTag;
  badge?: ClsTag;
  templateId?: number;
  children?: ClsChildrenItem[] = [];

  constructor(
    id?: number,
    state?: string,
    name?: string,
    type?: string,
    icon?: string,
    label?: ClsTag,
    badge?: ClsTag,
    children?: ClsChildrenItem[],
    templateId?: number
  ) {
    id = id;
    state = state;
    name = name;
    icon = icon;
    label = label;
    badge = badge;
    children = children;
    templateId = templateId;
  }
}

export class ClsSocialMedia {
  id?: number;
  icon: string;
  link: string;
  iconColor: string;
  color: string;
}

export class ClsHeader {
  id: number;
  email: string;
  phone: string;
  socialMedia?: ClsSocialMedia[];
  logo: string;
  companyName: string;
  menu?: Menu[];
}

export class ClsEditorMenu {
  id?: number;
  state: string;
  icon: string;
  data?: any;
}

export class ClsWelcome {
  id?: number;
  background?: string;
  title: string;
  subTitle?: string;
  buttonView?: ClsButton[];

  constructor(
    id?: number,
    background?: string,
    title?: string,
    subTitle?: string,
    buttonView?: ClsButton[]
  ) {
    id = id;
    background = background;
    title = title;
    subTitle = subTitle;
    buttonView = buttonView;
  }
}

export class ClsSlider {
  id?: number;
  text: string;
  subTitle: string;
  dataMerge?: number;
  width?: number;
  dotContent?: string;
  src?: string;
  dataHash?: string;
  buttonView?: ClsButton[];
}

export class ClsButton {
  id?: number;
  title: string;
  link?: string;
  icon?: string;
  color?: string;
}

// export class ClsItemCollection {
//   id?: number;
//   title: string;
//   src?: string;
//   subtitle?: string;
//   color?: string;
//   type?: string;
// }

export class InitSate {
  menu: Menu[];
  header: ClsHeader;
}

export class ClsLogin {
  userId: number;
  username: string;
  password: string;
  email: string;

  constructor(
    userId?: number,
    username?: string,
    password?: string,
    email?: string
  ) {
    userId = userId;
    username = username;
    password = password;
    email = email;
  }
}


export class clsEventComing {
  src ?: string;
  title ?: string;
  subtitle ?: string;
  detail ?: string;
  date ?: string;
}

export class clsTributor {
  src ?: string;
  name ?: string
  position ?: string;

}


export class clsSolutions {
  src: string;
  title: string;
  subTitle?: string
}

export class clsVideoBlock {
  src: string;
  title: string;
  subTitle?: string
}

// export class ClsRetailFeature {
//   src: string;
//   title: string;
//   description: string[];
//   buttonCaption: string;
//   href?: string;
//   isLeft: boolean;
// }

// export class ClsRestaurantFeature {
//   src: string;
//   title: string;
//   description: string[];
//   buttonCaption: string;
//   href?: string;
//   isLeft: boolean;
// }
// export class ClsWhatWeSell {
//   src: string;
//   title: string;
// }

export class clsHardwareProduct {
  src: string;
  title: string;
  route: string;
}

// tslint:disable-next-line: class-name
export class clsHearderMain {
  description: string;
  //imgStr: string;
  fontawesomeCode: string;
  route: string;
  iconClass ?: string
  active?: boolean
  submenu?: clsHeaderTypePro[]
}

export class clsHeaderTypePro {
  description: string;
  //imgStr: string;
  fontawesomeCode: string;
  active?: boolean
}

export class clsProductType {
  proname: clsProduct[];
  fontawesomeCode: string;
  Type: string;
}

export class clsBussinessType {
  bussinessType: string
  fontawesomeCode: string;
}

export class clsProduct {
  proname: string
  fontawesomeCode: string

}

export class clsService {
  description: string;
  //imgStr: string;
  fontawesomeCode: string;
  route: string;
}

export class clsServiceType {
  bussinessType: string
  fontawesomeCode: string;
}

export class clsHeaderRight {
  link?: string
  name: string;
  fontawesomeCode: string
  route: string;
  iconClass ?: string
  active?: boolean
  submenu?: clsHeaderTypePro[]
}

export class clsProductList {

  id: number;
  //brand : string;
  name: string;
  price: string;
  src: string;
}

export class clsProductBrand {
  id: number;
  brand: string;
}

export class clsFreeTrial {
  name: string;
  id: string;
}

export class selectBusType {
  id: string;
  name: string;
}

export class clsTrialTitle {
  titleInfo: string;
  src: string;
  freetrialday: number;
}

export class clsSocialLogo {
  src: string
}

export class clsLogo {
  to: string;
  fb: string;
  ig: string;
  line: string;
  twitter: string;
  youtube: string;

}

export class clsAboutUs {
  src: string;
  title: string;
  content: string;
  description: string[];
  buttonCaption: string;
  href?: string;
  isLeft: boolean;
  readmore: boolean
}

export class clsProdesc {
  title: string
  desc: string[]
}

export class clsOverDetail {
  overview ?: string
  detailSpec: clsSpecTitle;
}

export class clsSpecTitle {
  screen ?: string
  Brand ?: string
  Model?: string
  Style?: string
  RAM?: string
  Capability?: string
  OS?: string
  CPU?: string;
}

export class clsSpec {
  title: string[]
}

export class clsOverView {
  overview: string[]
  // detail : string
  // download : string
}

export class clsTOFooterPage {
  src: string[];
  title: string
  address: string
  email: string
  phonenumber: string
  copyright: string
}


export class clsBussinessPartner {
  src: string;
}

export class clsMessagerFB {
  theme_color: string;
  logged_in_greeting: string
  logged_out_greeting: string
  page_id: string
}

export class clsCustomerRef {
  src: string;
}

// export class clsCompInfo{
//   title : string
//   address : string
//   email : string
//   phonenumber : string
//   copyright : string
// }
export class clsTitle {
  title: string
  subtitle: string
}

export class clsCards {
  description: string;
  imgStr: string;
  fontawesomeCode: string;
  route: string;
  type?: string;
}


export interface IMenus extends IMenu {
  children: IMenus[];
}

export class clsHeaders implements IHeader {
  public isshowheader?: boolean=true;
  public headerid;
  public menuid;
  public background;
  public rtl;
  public headerstatus;
  public headername
  public isvdobackground;
  public showaction;
  public headertexts;
  public headersliders;
  public isshowmap?:boolean = false
  //public isshowheader?:boolean = true
}

export class clsMenuGroup implements IMenuGroup {
  public menus: IMenus[];
  public menugroupid;
  public menugroupname;
  public menugrouptype;
  public menugroupstatus;
}

export class clsCollection implements ICollection {
  public feedid?;
  public collectionid;
  public collectiongroupid;
  public collectionname;
  public collectionprice;
  public collectionroute;
  public collectionsrc;
  public collectionaction;
  public collectionisshowprice;
  public collectionfooter: { icon: string, text: string }[];
  public collectionstatus;
  public collectiondescription;
  public collectiondetail: clsCollectionDetail = new clsCollectionDetail();

}
export class clsCollectionTitleRoute{
  public collectionname:string;
  public collectionid:string;
}

export class clsCollectionDetail implements ICollectionDetail {

  public collectionid;
  public collectiondetaildescription;
  public collectiondetaildescriptionlist: ICollectionDetailDescriptionList[];
  public collectiondetailthumbnail: string[];
}

export class clsCollectionGroup implements ICollectionGroup {
  public collectiongroupid: any;
  public feedid: string;
  public collectiongroupname: string;
  public collectiongroupdescription: string;
  public collectiongroupsrc: string;
  public collectiongrouproute: string;
  public collectiongroupstatus: boolean;
  public totalcollection: number;
  public showcollectiontotal: boolean;
  public collection: ICollection[];
}

export class clsItem implements IItem {
  public itemid;
  public feedid;
  public itemimagetype;
  public itemimageposition;
  public itemsrc;
  public itemtitle;
  public itemroute;
  public border;
  public iconborder;
  public itemsubtitle;
  public template;
  public icon;
  public itemprice;
  public isshowprice;
}

export class clsSlider implements ISlider {
  public feedid;
  public sliderid;
  public slidertitle;
  public slidersubtitle;
  public slidersrc;
  public sliderimage;
  public isvideo;
  public template;
}

export class clsBlog implements IBlog {
  public feedid;
  public blogid;
  public blogdescription;
  public mappingroute;
  public blogthumbnail: string[];
  public rtl;
}
export class clsContent implements IContent{
  contentid;
  feedid;
  contentdescription: string = "";
}
export class clsFeed implements IFeed {
  public feedid;
  public menuid;
  public feedslogan;
  public feedtitle;
  public feedsubtitle;
  public feedname;
  public type;
  public mappingroute:string=null;
  public feedstatus: boolean = false
  public rtl: boolean = false;
  public template;
  public responsive?: OwlOptions;


}

export class ClsFooter {
  logo: string;
  description: string;
  socials: { icon: string, color: string, backgroundColor: string , link ?: string,id?:string }[];
  pagegroups: ClsFooterPageGroup[];

}

export class ClsFooterPageGroup {
  pagegroup: string;
  pages: { icon: string, label: string, route: string }[];
}

export class clsUserSecurity {
  domain: string;
  username: string;
  password: string;
  confirmPassword: string;
}
export class clsResponseAccount {
  productid: number;
  domain: string;
  hostdomain: string;
  result:number
}
export class clsUserSetup {
  userId: string;
  companyName: string;
  domain: string;
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  avatar: string;
  email: string;
  address: string;
  businessCategory: string;
  businessOutlet: string;
  status: number;
  userSecurity: clsUserSecurity;
  constructor(){
    this.userId = "";
    this.companyName = "";
    this.domain = "";
    this.firstName = "";
    this.lastName = "";
    this.gender = "";
    this.phone = "";
    this.avatar = "";
    this.email = "";
    this.address = "";
    this.businessCategory = "";
    this.businessOutlet = "";
  }
}

export class clsUserProfile {

}

export class clsIniialize {
  public headers: clsHeaders[] = [];
  public menugroup: clsMenuGroup[] = [];
  public feeds: IFeed[] = [];
  public items: IItem[] = [];
  public content:IContent[]=[]
  public sliders: ISlider [] = [];
  public blogs: IBlog[] = [];
  public collectiongroups: clsCollectionGroup[] = [];
  public collections: clsCollection[] = [];
  public footer: ClsFooter = new ClsFooter();
  public collectiondetails: clsCollectionDetail[];
}

export class clsImg {
  public isSingleImg: boolean;
  public objImg: string[];

  constructor() {
    this.objImg = [];
  }
}

export class clsfileimage {
  fileimageid: string;
  fileimagename: string;
  resImg: string;
  filetype?: string;
  url?: string;

}

export class clsmedia {
  objFileImg: clsfileimage [] = [];
  // objAlbum: clsalbum[]=[]
  issingleImg: boolean = false;
}

export class clsalbum {
  albumid: string;
  albumname: string;
  childrent: clsfileimage[] = []
}
