import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ClientUserProfileService} from "../../../register-app/Class/clientUserProfile.service";
import {svrPricing} from "../../Controller/svrPricing";
import {clsGetmodule, clsGetmodulePackage, clspricing} from "../../Model/clsPricing";
import {OperationService} from "@core";

import {HttpClient} from "@angular/common/http";
import {svrStoredataService} from "@core/services/svr-storedata.service";

@Component({

  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})

export class FrmPricingComponent implements OnInit {

  pricingList: IPricingList = {
    tables: [],
    modulelist: [],
    modulePackage: [],
    classNames: ['pricing-card basic', 'pricing-card echo', 'pricing-card pro'],
  };

  constructor(
    public svrStoredata: svrStoredataService,

    public svrRoute: Router,
    public userService?: ClientUserProfileService,
    public svrPrice?: svrPricing,
    public svrOpr?: OperationService,
    public route?: ActivatedRoute,
    public httpclient?: HttpClient,
  ) {
    this.onGetJson()
  }

  lstModule: clsMainMoudle[] = [];

  async onGetJson() {
    let res2 = (await this.httpclient.get('assets/data/package_mudule.json?_t=' + Date.now()).toPromise());
    let lstMainsub = res2["moduleList"];
    this.lstModule = lstMainsub;
  }

  onSignUp() {
    /*this.svrRoute.navigate(['verification'],{queryParams : {id: "13bfc689-d08f-48a0-ab35-209f3beab3ce"}})*/
    this.svrRoute.navigate(['sign-up']);
    this.userService.isSignUp = true;
  }

  localprice: any;

  dis:any=[];

  getSubModule: any[] = [];

  loading:boolean= false;

  async ngOnInit() {
    localStorage.removeItem(this.key.seletedPackage);
    let getPackage = await this.svrPrice.GetPricingPackage();
    this.pricingList.tables = [...this.pricingList.tables, ...getPackage];
    let getModule = await this.svrPrice.GetModule();
    this.pricingList.modulelist = [...this.pricingList.modulelist, ...getModule];
    let getBuynowPackage = await this.svrPrice.GetBuynowModulePackage();
    this.onclickmodul[0] = 'mat-expansion-panel-modifide';
    this.step.push(false);
    this.svrStoredata.clearKey("FrmBuyNowComponent");

    for (let i = 0; i < this.lstModule.length; ++i) {
      this.step.push(false);
    }
    for (let i = 0; i < this.lstModule.length; ++i) {
      this.moduleStyle.push('moduleStyleNormal');
    }
    this.onClaarLocalStorage ();
    this.loading = true;
  }
   key = new localSTKey();
  getSubModuleatIndex=[];
  onBuyNow(_packageid: number) {

    let pid = _packageid + 1 ;
    this.svrOpr.NavigateRoute(this.route, 'buy-now', _packageid);
    localStorage.setItem(this.key.seletedPackage, _packageid.toString());
    let obj = this.pricingList.tables.filter(el=> el.packageid == pid.toString());
    localStorage.removeItem(this.key.SalePrice);
    obj.forEach((a)=> this.localprice=a.price);
    localStorage.setItem( this.key.SalePrice, this.localprice);
    this.pricingList.tables.forEach((a)=> {
      this.dis = a.price
    });
    let dis= this.pricingList.tables.filter(a=> a.packageid == pid.toString());
    dis.forEach((a)=> this.dis = a.disc)
    localStorage.setItem(this.key.pricing_discount, this.dis);
    this.lstModule.forEach((a)=> {
      this.getSubModule.push(a.available)
    })
    localStorage.setItem(this.key.getSubModule, JSON.stringify(this.getSubModule.map(idn=>idn[_packageid])))


  }
  step: boolean[] = [];

  openclose: boolean[] = [];
  indexOpen: any;
  ischeckOpen: boolean = true;

  onClaarLocalStorage (){
    //localStorage.removeItem(this.key.pricing_discount);
    //localStorage.removeItem(this.key.seletedPackage);
    //localStorage.removeItem(this.key.getSubModule);
    localStorage.setItem(this.key.isClearAddon, '1');
  }



  onUnfold(index) {
    // if(this.step[index]  == false){
    //   this.step[index] = true;
    //
    // }else{
    //
    // }
  }

  onFold(index) {
    // if (this.step[index] == false){
    //   this.step[index] = false;
    //
    // }else{
    //
    // }


  }

  onclickmodul = [];
  onclickmodulIndex: any;
  passIndexstyle: any;
  moduleStyle = [];

  ModuleClick(index) {

    if (this.step[index] == false) {
      this.step[index] = true;
      this.onclickmodul[index] = 'mat-expansion-panel-modifide-click';
      this.moduleStyle[index] = 'moduleStyleBold';
    } else if (this.step[index] == true) {
      this.step[index] = false;
      this.onclickmodul[index] = 'mat-expansion-panel-modifide';
      this.moduleStyle[index] = 'moduleStyleNormal';
    } else {

    }

  }

}

export class saveAddon{
  tranid="tranid";
  addonmid="addonmid";
  addonid="addonid";
  compid="compid";
}

export class localSTKey {
  offdec_status_frm_req="offdec_status_frm_req"
  emailtosale_status_frm_req="emailtosale_status_frm_req"
  usersigninemail="usersigninemail"
  getSubModule="saveSubModule"
  isClearAddon="isClearAddon"
  buy_now_total ="buy_now_total"
  buy_now_grandtotal ="buy_now_grandtotal"
  pricing_discount ="pricing_discount"
  dialogIconinRequest ="dialogIconinRequest"
  dialogStatusinRequest ="dialogIconinRequest"
  saveAddOn ="saveAddOn";
  seletedPackage = "packageID";
  AddOnModule = "AddOnModule";
  PaymentIntegration= "PaymentIntegration";
  AppIntegration = "AppIntegration";
  SalePrice = "Price";
}

interface IPricingList {
  tables?: clspricing[];
  modulelist?: clsGetmodule[];
  modulePackage?: clsGetmodulePackage[];
  lstick?: {}[];
  classNames?: string[];
  stan?: string[];
  contlist?: {}[];
  onClick?: (type: string) => void;
}

export class clsMainMoudle {
  name: string = "";
  available: number[] = [];
  subModul: clsSubmodule[] = [];
}

export class clsSubmodule {
  name: string;
  moduleid: number = 0;
  std: boolean = false;
  avb: number[] = [];
}


