import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { INav, MenuService } from '@core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {ResizeService} from "../../../core/services/svr-resizer.service";



@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [ResizeService],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  @Input() nav: INav[] = [];
  @Input() actions: [];
  @Input() subtitle: '';
  @Input() showAction: true;
  isMobile: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private router: Router, private menuService: MenuService, private resizeService: ResizeService) {
    const doc = document.body.getBoundingClientRect();
    if (doc.width <= 767) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    this.resizeService.onResize$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(size => {
      if (size.innerWidth <= 767) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.nav = Array.isArray(this.nav) ? this.nav : [];

    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }

  }

  trackByNavlink(index: number, navlink: string): string {
    return navlink;
  }

  onNavigate(state: string) {
    this.router.navigate([state]);
  }

  genBreadcrumb() {
    const states = this.router.url.slice(1).split('/');
    this.nav = this.menuService.getMenuLevel(states);
    this.nav.unshift({ name: 'home', state: null });
  }
}
