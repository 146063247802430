import {Component, OnInit, ViewChild, ElementRef, Inject} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

import {OperationData, OperationService} from "@core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ctl-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss']
})
export class CtlYoutubeVdoComponent implements OnInit {


  @ViewChild('ctlFrame', {static: true}) ctlFrame: ElementRef;

  constructor(private svrOpt: OperationService,
              private svrData: OperationData,
              private sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<CtlYoutubeVdoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.doShowVideo(this.data);
  }


  /*getVideoUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + this.vid);
  }*/
  doShowVideo(vdo) {
    const ctl = this.ctlFrame.nativeElement as HTMLIFrameElement;
    const src = "https://www.youtube.com/embed/" + vdo.name + "?wmode=transparent&amp;rel=0&autoplay=1";
    ctl.contentWindow.location.assign(src);


  }


}

