import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import {clsNan} from "@core";

@Component({
  selector: 'app-ctl-dialog',
  templateUrl: './ctl-dialog.component.html',
  styleUrls: ['./ctl-dialog.component.scss'],
})
export class CtlDialogComponent implements OnInit {

  @Input() ShowTitle: boolean = true;
  @ViewChild('popupContainer', { static: false }) popupContainer: ElementRef;
  @Input() hidePositive : boolean = false;
  @Input() Positive: string = 'YS';
  @Input() Negative: string = 'NO';
  @Input() Neutral: string = '';
  @Input() headerTitle: String = '';
  @Input() overflow: false;
  @Input() IsAddNew: boolean = false;
  @Input() isNewClick: boolean = false;
  @Input() isValid: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() moduleid: number = 0;
  @Input() label: string = 'OK';
  @Input() class: any;
  @Input() isPositiveDisable: boolean = false;
  @Input() Title:string="";
  @Output() onPositive: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNegative: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNeutral: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddNew: EventEmitter<any> = new EventEmitter<any>();
  @Output() onApply = new EventEmitter<any>();
  @Input() isClose: boolean = false;
  constructor() {
  /*  const doc = document.body.getBoundingClientRect();


    if (doc.width >= 768) {
      setTimeout(() => {
        const popup = this.popupContainer.nativeElement as HTMLInputElement;
        const datable = this.popupContainer.nativeElement.querySelector('datatable') as HTMLDivElement;
        const wrapper = this.popupContainer.nativeElement.querySelector('.wrapper') as HTMLDivElement;
        try {
          if (!clsNan.isNullOrUndefined(wrapper)) {
            wrapper.setAttribute('style', 'min-height:' + popup.offsetHeight + 'px;height:' + popup.offsetHeight + 'px;overflow:auto');
          }
          if (!clsNan.isNullOrUndefined(datable)) {
            datable.setAttribute('style', 'min-height:' + (popup.offsetHeight - 8) + 'px;height:' + (popup.offsetHeight - 8) + 'px');
          }
        } catch (e) {

        }
      }, 0);
    }*/

  }

  ngOnInit(): void {

  }

  Apply(idx: number) {
    this.onApply.emit(idx);
  }

  onCancelClick(idx: number) {
    this.onNegative.emit(idx);

  }
}
