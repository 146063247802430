import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-appr-download-popup',
  templateUrl: './appr-download-popup.component.html',
  styleUrls: ['./appr-download-popup.component.scss']
})
export class ApprDownloadPopupComponent implements OnInit {
  constructor(
    private svrDialog: MatDialog,
  ) { }
  ngOnInit(): void {
  }
  onApply(ev){
    if(ev == 2){
      this.svrDialog.closeAll();
    }
  }


}
