export interface AppSettings {
  userId?: number;
  whid?: number;
  thmdesc?: string;
  navpos?: 'side' | 'top';
  dir?: 'ltr' | 'rtl';
  theme?: 'light' | 'dark';
  isshowheader?: boolean;
  headerpos?: 'fixed' | 'static' | 'above';
  isuserpanel?: boolean;
  issidenavopen?: boolean;
  issidenavcollapse?: boolean;
}

export const defaults: AppSettings = {
  navpos: 'side',
  dir: 'ltr',
  theme: 'light',
  isshowheader: true,
  headerpos: 'fixed',
  isuserpanel: true,
  issidenavopen: true,
  issidenavcollapse: false,
};
