import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SwiperComponent, SwiperDirective} from 'ngx-swiper-wrapper';
import {IFeed, IItem} from '../../../../Store/intity/IModels';
import {InitializeService} from '@core';
import {GlobalService} from '@core/services/global.service';

@Component({
  selector: 'app-ctl-feed-items',
  templateUrl: './feeditems.component.html',
  styleUrls: ['./feeditems.component.scss']
})
export class CtlFeedItemsComponent implements OnInit {
  @ViewChild(SwiperComponent, {static: true}) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, {static: true}) directiveRef?: SwiperDirective;
  @Input() items: IItem[];
  @Input() public feed: IFeed;
  customOption: any = {
    loop: true,
    lazyLoad: true,
    autoplay: true,
    center: true,
    merge: true,
    slideBy: 'page',
    dots: false,
    autoHeight: true,
    autoWidth: true,
    startPosition: 1,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 4000,
    dotsSpeed: 500,
    responsive: {
      480: {
        items: 1,

      },
      640: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,

      },
      1280: {
        items: 3,
      },
      1366: {
        items: 5,
      },
    }
  };


  constructor(public  initialize: InitializeService, public  global: GlobalService) {


  }

  onClick() {

  }

  initData(type: string, feedId: string) {
    this.items = this.initialize.getDatByFeedId(type, feedId) as IItem[];
  }

  ngOnInit() {
    this.initData(this.feed.type, this.feed.feedid);
  }

  onRouteMapping(route) {
    if(route){
      this.global.onMappingRoute.emit(route);
    }
  }

  onNavigate(route: string) {
    // this.svrRouter.navigate([route]);
  }


}

