import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class svrStoredataService {

  static DATA_KEY_MODULE = 'LIST_KEY_MODULE';
  static DATA_KEY_TYPE = 'DATA_KEY_TYPE';
  static DATA_KEY = 'LIST_SEARCH_KEY';
  objData: Map<string, any> = new Map();

  constructor(
    public db: StorageMap,
    public route: ActivatedRoute,
  ) {

    /*db.get(svrStoredataService.DATA_KEY).subscribe(el => {
      this.objData = el as Map<string, any>;
    });*/
  }

  async loadData(): Promise<boolean> {
    this.objData = (await this.db.get(svrStoredataService.DATA_KEY).toPromise() as Map<string, any> ?? new Map());
    return true;
  }

  async getKey<T = unknown>(key: string): Promise<T> {

    if (key === '') {
      key = this.route.snapshot.routeConfig.component.name;
    }
    if (this.objData.size == 0) {
      await this.loadData();
    }
    /* if (this.objData == null) {
       await this.loadData();
     }*/

    const res = await this.objData.get(key) as T;
    return res;
  }

  async setKey<T = unknown>(key: string, value: T) {
    if (key === '') {
      key = this.route.snapshot.routeConfig.component.name;
    }

    if (this.objData.size == 0) {
      await this.loadData();
    }
    /*    if (this.objData == null) {
          await this.loadData();
        }*/

    this.objData.set(key, value);
    await this.db.set(svrStoredataService.DATA_KEY, this.objData).toPromise()//.subscribe();
  }

  async clearKey<T = unknown>(key: string) {
    if (key === '') {
      key = this.route.snapshot.routeConfig.component.name;
    }

    if (this.objData.size == 0) {
      await this.loadData();
    }
    /*if (this.objData == null) {
      await this.loadData();
    }*/

    this.objData.set(key, null);
    this.db.set(svrStoredataService.DATA_KEY, this.objData).toPromise().then();//.subscribe();
  }
}

