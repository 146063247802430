import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService, OperationService} from "@core";
import {ClsLogin} from "../../../../Store/models/clsModels";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  objLogin: ClsLogin = {userId: 0, username: '', password: '', email: ''};

  constructor(private operation: OperationService, private router: Router, private svrInit: InitializeService) {
  }

  ngOnInit(): void {
  }

  onLogin() {
    this.operation.doLogin(this.objLogin).subscribe(response => {
      let token = (<any>response).token;
      localStorage.setItem('tosoftwarejwt', token);
      this.svrInit.getHeaders('/', 0);
      this.svrInit.getFeedByRoute('/', 0);
      this.router.navigate(['console/view']);

    }, err => {

    });
  }

  onObjChange(e) {
    this.objLogin[
      (e.target as HTMLInputElement).name
      ] = (e.target as HTMLInputElement).value;
  }
}
