import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OperationService} from "@core";
import {clsCustomerInfo, clsSelectBussType} from "../Model/clsRequest";
import {Email} from "../../register-app/Model/clsCMModel";
import {clsAddonModuleGroup} from "../Model/clsBuynow";
import {clspricing} from "../Model/clsPricing";



@Injectable({
  providedIn: 'root',
})
export class svrRequestMail {
  public routeprefix = "license/cm_customer";

  constructor(
    public http: HttpClient,
    public operation: OperationService,
  ) {

  }

  public async GetEmailToSale(email?:Email) {
    let getEmail = await
      this.http.post<clsCustomerInfo[]>(this.operation.accessPointUrl + this.routeprefix + "/SendRequestEmailToSale", JSON.stringify(email), { headers: { 'Content-Type': 'application/json' } }).toPromise();
    return getEmail;
  }

  public async GetBusineesType(): Promise<clsSelectBussType> {
    let getBussType = await
      this.http.get<clsSelectBussType>( this.operation.accessPointUrl + "license/sm_business/GetBusinesstype").toPromise();
    return getBussType;
  }
  public async OnAddCustomerInfo(objCusInfo?: clsCustomerInfo): Promise<number> {
    let custid = await
      this.http.post<number>( this.operation.accessPointUrl + "licens-package/OnAddCustomerInfo", JSON.stringify(objCusInfo), { headers: { 'Content-Type': 'application/json' } }).toPromise();
    return custid;
  }
}


