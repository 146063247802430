import {Component, OnInit} from '@angular/core';
import {clsLogin} from '../../Model/clsGlobalData';
import {transition, trigger, useAnimation} from '@angular/animations';
import {animateTransitionX, animateTransitionY} from '../../Class/cls-animations';
import {Router} from '@angular/router';
import {MenuService, OperationService} from "@core";
import {svrSMUser} from "../../Controller/svrSMUser";
import {LCUser} from "../../Model/clsLicense";
import {SvrDataService} from "../../Class/svr-data.service";

@Component({
  selector: 'app-frm-login',
  templateUrl: './frm-login.component.html',
  styleUrls: ['./frm-login.component.scss'],
  animations: [
    trigger('anShowLogin', [
      transition('* <=> *', [
        useAnimation(animateTransitionX, {
          params: {
            axisBefore: '30%',
            axisAfter: '0',
            time: '800ms',
            opaBefore: '0',
            opaAfter: '1',
          },
        }),
      ]),
    ]),
    trigger('anShowBackground', [
      transition('* <=> *', [
        useAnimation(animateTransitionX, {
          params: {
            axisBefore: '-30%',
            axisAfter: '0',
            time: '900ms',
            opaBefore: '0',
            opaAfter: '1',
          },
        }),
      ]),
    ]),
    trigger('anShowTitle', [
      transition('* <=> *', [
        useAnimation(animateTransitionY, {
          params: {
            axisBefore: '-30%',
            axisAfter: '0',
            time: '900ms',
            opaBefore: '0',
            opaAfter: '1',
          },
        }),
      ]),
    ]),
  ],
})
export class FrmLoginComponent implements OnInit {
  strDomain: string = './../../../../../assets/app_icons/userLogin.svg';
  objLogin: clsLogin = new clsLogin();
  isRemember: boolean = false;
  cachePWD: string = '';
  username: string = "";
  password: string = "";

  constructor(
    public svrRoute: Router,
    public svrOpt: OperationService,
    public menuService: MenuService,
    public svrUser: svrSMUser,
    private svrData: SvrDataService
  ) {

  }

  ngOnInit(): void {
  }

  onUserChange(val, type: string) {
    this.objLogin.pwd = this.cachePWD = val;
  }

  async onLogin() {
    let data = await this.svrUser.Login(this.objLogin)
    if (data) {
      this.svrData.objInit.objUser.username = data.username
      if (data.password != null) {
        localStorage.setItem("userid", data.userid.toString())
        localStorage.setItem("username", data.username.toString())
        localStorage.setItem("licenseToken", data.token);
        this.svrRoute.navigate(['/license']);
      } else
        this.svrOpt.onToastWarning("Incorrect password", "Login")
    } else {
      this.svrOpt.onToastFailed("Invalid Username and password")
    }
  }
}
