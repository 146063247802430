import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';

import {WebsiteLayoutComponent} from './admin-layout/website-app/website-layout.component';
import {SidebarComponent} from './admin-layout/sidebar/sidebar.component';
import {UserPanelComponent} from './admin-layout/sidebar/user-panel.component';
import {SidemenuComponent} from './admin-layout/sidemenu/sidemenu.component';
import {AccordionAnchorDirective} from './admin-layout/sidemenu/accordionanchor.directive';
import {AccordionDirective} from './admin-layout/sidemenu/accordion.directive';
import {AccordionLinkDirective} from './admin-layout/sidemenu/accordionlink.directive';
import {SidebarNoticeComponent} from './admin-layout/sidebar-notice/sidebar-notice.component';
import {HeaderComponent} from './admin-layout/header/header.component';
import {BrandingComponent} from './admin-layout/header/branding.component';
import {NotificationComponent} from './admin-layout/header/notification.component';
import {UserComponent} from './admin-layout/header/user.component';
import {TopmenuComponent} from './admin-layout/topmenu/topmenu.component';
import {TranslateComponent} from './admin-layout/header/translate.component';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {FrontLayoutComponent} from './front-layout/front-layout.component';
import {CtlImagePicker} from '../projects/website-app/component/image-picker/image-picker.component';
import {CtlMapComponent} from '../projects/website-app/component/home/map/map.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {MatSidenavModule} from "@angular/material/sidenav";
import { CtlNotFoundComponent } from '../projects/website-app/component/ctl-not-found/ctl-not-found.component';
import {LicenseLayoutComponent} from "./admin-layout/license-app/license-layout.component";
import {HttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { CustomizerComponent } from './admin-layout/customizer/customizer.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [

    CtlMapComponent,
    FrontLayoutComponent,
    WebsiteLayoutComponent,
    LicenseLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent,
    UserPanelComponent,
    SidemenuComponent,
    AccordionAnchorDirective,
    AccordionDirective,
    AccordionLinkDirective,
    SidebarNoticeComponent,
    HeaderComponent,
    BrandingComponent,
    NotificationComponent,
    UserComponent,
    TopmenuComponent,
    CtlImagePicker,
    TranslateComponent,
    CtlNotFoundComponent,
    CustomizerComponent,
  ],
  imports: [SharedModule, AngularSvgIconModule, MatSidenavModule, TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient],
    },
  })],
    exports: [
        CtlMapComponent,
        AccordionLinkDirective,
        AccordionLinkDirective,
        CtlNotFoundComponent
    ]
})
export class ThemeModule {
}
