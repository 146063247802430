import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { CustomizerComponent } from '../customizer/customizer.component';
import {SvrDataService} from "../../../projects/license-app/Class/svr-data.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserComponent {
  constructor(
    private route: Router,
    private svrDialog: MatDialog,
    public svrData : SvrDataService


  ) {
     this.svrData.objInit.objUser.username =localStorage.getItem("username")
  }

    theme() {
      this.svrDialog.open(CustomizerComponent, { panelClass: 'them-dialog' } as MatDialogConfig).afterClosed().toPromise().then(async result => {
      });
    }
  onLogout() {
    localStorage.clear();
    this.route.navigate(['/auth/login']);
  }
}
