import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

import {InitializeService} from './initialize.service';
import {SvrOperationService} from "../../projects/license-app/Class/svr-operation.service";
import {OperationService} from "@core/services/operation.service";
import {MenuService} from "@core/services/menu.service";

@Injectable()
export class StartupService {
  constructor(private initService: InitializeService,
              private http: HttpClient,
              private svrOpt : OperationService,
              public menuService: MenuService
  ) {

  }

  public async ongetMenu(){
    let datamenu = await this.svrOpt.GetLicenseMenu();
    this.menuService.set([...datamenu.menu])
  }

  load(): Promise<any> {
   return  new Promise((resolve, reject) => {
      this.http
        .get('assets/data/tosoftware.json?_t=' + Date.now())
        .pipe(
          catchError(res => {
            resolve();
            return res;
          })
        )
        .subscribe(
          (res: any) => {
            this.initService.setInitial(res);
          },
          () => {
          },
          () => {
            resolve();
          }
        );
    });

  }

}
