
import { Action } from '@ngrx/store';
import * as TOSoftwareStore from '../models/clsModels';
export enum  Collection_Action_Type {
    CollectionInitial = 'CollectionInitial',
    CollectionInitSuccess= 'CollectionInitSuccess',
    CollectionUpdate= 'CollectionUpdate',
    CollectionRemove= 'CollectionRemove',
    CollectionAdd= 'CollectionAdd',
}
export class CollectionInitial implements Action {
    readonly type = Collection_Action_Type.CollectionInitial;
    constructor() {
    }
}
export class CollectionUpdate implements Action {
    readonly type = Collection_Action_Type.CollectionUpdate;
    constructor(public payload: TOSoftwareStore.ClsNewCollection) {
    }
}
export class CollectionRemove implements Action {
    readonly type = Collection_Action_Type.CollectionRemove;
    constructor(public payload: TOSoftwareStore.ClsNewCollection) {
    }
}
export class CollectionAdd implements Action {
    readonly type = Collection_Action_Type.CollectionAdd;
    constructor(public payload: TOSoftwareStore.ClsNewCollection) {
    }
}
export class CollectionInitSuccess implements Action {
    readonly type = Collection_Action_Type.CollectionInitSuccess;
    constructor(public payload: TOSoftwareStore.ClsNewCollection[]) {
    }
}

export type ActionsCollection = CollectionInitial | CollectionInitSuccess |CollectionUpdate | CollectionRemove | CollectionAdd ;
