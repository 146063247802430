import {Component, OnInit, Inject} from '@angular/core';
import {clsMainMoudle, localSTKey} from "../../../form/pricing-layout/pricing.component";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {


  /*mainModule = [
    "Point Of Sale (POS)",
    "Product Type",
    "Inventory Control",
    "Promotion",
    "Account Receivable",
    "Sale Payment",
    "Order Management",
    "Employee",
    "Purchase Management",
    "Freight Management",
    "Integrate"
  ];

  addonAppinterga = ['Shipment App', 'ICount', 'Price Checking', 'Ecommerce',];
  checkBox = [1, 1, 1, 1,];*/

  constructor(public httpclient?: HttpClient) {
    this.ongetIcons();
  }

  moduleName = '';
  package_price: number;
  lstModule = [];

  ninprice: number;

  AddOnModule: string[] = [];
  PaymentIntegration: string[] = [];
  AppIntegration: string[] = [];

  getIcon:any;

  isSentStatus :string
  isOffDec :string='on';
  key =new localSTKey();
  emailUser :string
  async ngOnInit() {
    this.isOffDec = 'on';
    this.emailUser = localStorage.getItem(this.key.usersigninemail);
    this.ongetIcons();
    this.onIssendSuccess()
    this.onGetUserSelect();
    this.isOffDec=localStorage.getItem(this.key.offdec_status_frm_req )
    debugger
    if (this.isOffDec == 'off'){
      this.isOffDec = 'off';
    }else{
      this.isOffDec = 'on';
    }

  }

  onIssendSuccess(){
    this.isSentStatus=localStorage.getItem(this.key.emailtosale_status_frm_req )
  }



  ongetIcons(){
    let key = new localSTKey() ;
    this.getIcon=localStorage.getItem(key.dialogIconinRequest);
  }
  onGetUserSelect() {
    let key = new localSTKey();
    let addon = JSON.parse(localStorage.getItem(key.AddOnModule));
    let payInter = JSON.parse(localStorage.getItem(key.PaymentIntegration))
    let appInter = JSON.parse(localStorage.getItem(key.AppIntegration))
    this.AddOnModule = addon;
    this.PaymentIntegration = payInter;
    this.AppIntegration = appInter;
  }
}

class clsMainMoudles {
  name: string = "";
  available: number[] = [];
}

class clsSubmodule {
  name: string;
  moduleid: number = 0;
  std: boolean = false;
  avb: number[] = [];
}
