import { Component, OnInit } from '@angular/core';
import { clsCounter} from '../../../../../Store/models/clsModels';

@Component({
    selector: 'app-ctl-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss']
  })
  export class CtlCounterComponent implements OnInit {

    _counter:clsCounter[]=[
     {
       desc : 'Customer',qty : 10000 ,src:'assets/counter/customers.svg'
     },
     {

       desc : 'Follower',qty : 15000,src:'assets/counter/follower.svg'
    },
    {
      desc : 'Viewer',qty : 10000,src:'assets/counter/viewer.svg'
      // desc : 'Viewer',qty : 15000,src:'assets/Social Media/Viewer.svg',background : '#707070'
    },
    {
      desc : 'Staff',qty : 10000,src:'assets/counter/employee.svg'
      // desc : 'Staff',qty : 1000,src:'assets/Social Media/employees.svg',background : '#9E9E9E '
    }
    ];



    constructor() {

    }

    ngOnInit() {  }



  }

