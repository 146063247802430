import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private jwtHelper: JwtHelperService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem("tosoftwarejwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {

      return true;
    }
    this.router.navigate(['main']);
    return false;
  }
}
@Injectable()
export class LicenseGuard implements CanActivate {
  constructor(private jwtHelper: JwtHelperService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem("licenseToken");
    if (token && !this.jwtHelper.isTokenExpired(token)) {

      return true;
    }
    this.router.navigate(['auth']);
    return false;
  }
}
