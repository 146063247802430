import {Injectable, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Resolve,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import {environment} from '../environments/environment.hmr';
import {FrontLayoutComponent} from './theme/front-layout/front-layout.component';
import {FrontComponent} from './projects/website-app/form/main-layout/main.component';
import {WebsiteLayoutComponent} from './theme/admin-layout/website-app/website-layout.component';
import {CtlFreeTrialComponent} from './projects/register-app/component/free-trial/free-trial.component';
import {CtlAboutUsComponent} from './projects/website-app/component/about-us/about-us.component';
import {AuthGuard, LicenseGuard} from './core/services/authGaurd';
import {AuthLayoutComponent} from './theme/auth-layout/auth-layout.component';
import {LoginComponent} from './projects/website-app/form/login/login.component';
import {CtlRequestQuoteComponent} from './projects/website-app/component/request-quote/request-quote.component';
import {FrmPricingComponent} from './projects/website-app/form/pricing-layout/pricing.component';
import {CtlVerificationComponent} from './projects/register-app/component/verification/verification.component';
import {CtlNotFoundComponent} from './projects/website-app/component/ctl-not-found/ctl-not-found.component';
import {LicenseLayoutComponent} from './theme/admin-layout/license-app/license-layout.component';
import {FrmLoginComponent} from './projects/license-app/form/frm-login/frm-login.component';
import {SvrStartUpResolver} from "@shared/services/svr-initdata-resolver";
import {Observable} from "rxjs";
import {ClientUserProfileService} from "./projects/register-app/Class/clientUserProfile.service";
import {async} from "@angular/core/testing";
import {SvrDataServiceRegister} from "./projects/register-app/Class/svr-data.service";
import {LCParamStatus, LCPartner} from "./projects/register-app/Model/clsCMModel";
import {
  CtlResendEmailComponent,
} from "./projects/register-app/component/resend-email/resend-email.component";
import {resolve, svrSMcompany} from './projects/license-app/Controller/svrSMcompany';
import {CtlRegisterTesting} from "./projects/register-app/component/register-testing/register-testing.component";
import {FrmBuyNowComponent} from "./projects/website-app/form/frm-buy-now/frm-buy-now.component";
import {ProductComponent} from "./projects/website-app/component/request-quote/product/product.component";

@Injectable({
  providedIn: 'root'
})

export class AccessingGuard implements CanActivate {

  constructor(public svrRoute: Router,
              public svrCustOpt: ClientUserProfileService,
              public svrDataReg: SvrDataServiceRegister) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (state.url == '/admin') {
      await this.svrRoute.navigate(['/admin'], {skipLocationChange: true});
    } else {
      const id = route.queryParams.id as string;
      if (id) {
        let isstatus = await this.onCheckAccountStatus(id) as LCParamStatus
        if (isstatus.isExpirelink) {
          this.svrDataReg.resenddata = isstatus
          await this.svrRoute.navigate(['/resendemail', {skipLocationChange: true}]);
        } else {

          if (isstatus.domain && isstatus.domain.toString() !== "" && isstatus.isactivated==true) {
            const product_path = this.svrCustOpt.onGetProductRoute(isstatus.productid);
            const redirectUrl = `${"https"}://${isstatus.domain}${isstatus.hostdomain}/${product_path}`;
            window.location.replace(redirectUrl);
          }
          else {
            this.svrDataReg.onCustomerData = await this.onGetCustomer(id)
            this.svrDataReg.onCustomerData.hostdomain = isstatus.hostdomain;
            if (this.svrDataReg.onCustomerData) {
              return true
            } else {
              await this.svrRoute.navigate(['404'], {skipLocationChange: true});
              return false
            }
          }
        }
      } else {
        await this.svrRoute.navigate(['404'], {skipLocationChange: true});
        return false
      }
    }
  }
  async  onCheckAccountStatus(code) {
    let objparam = new LCParamStatus()
    objparam.isVerifyAcc = true
    objparam.code = code
    let isaccount = await this.svrCustOpt.onCheckAccountStatus(objparam)
    return isaccount as LCParamStatus;
  }
  async onGetCustomer(id) {
    let data = await this.svrCustOpt.onGetCustInfo(id) as LCPartner
    return data;
  }
}
export class PathResolveService implements Resolve<string | null> {
  constructor(private router: Router) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): string | null {
    if (state.url == '/admin') {
      return 'admin'
    } else {
      this.router.navigate([this.router.url], {skipLocationChange: true});
    }
    // const typoPath = state.url.replace('/', '');
    // const threshold = this.getThreshold(typoPath);
    // const dictionary = Object.values(paths)
    //   .filter(path => Math.abs(path.length - typoPath.length) < threshold);

    // if (!dictionary.length) return null;

    // this.sortByDistances(typoPath, dictionary);

    // return `/${dictionary[0]}`;
  }

  getThreshold(path: string): number {
    if (path.length < 5) return 3;

    return 5;
  }

  sortByDistances(typoPath: string, dictionary: string[]) {
    const pathsDistance = {} as { [name: string]: number };

    dictionary.sort((a, b) => {
      if (!(a in pathsDistance)) {
        pathsDistance[a] = this.levenshtein(a, typoPath);
      }
      if (!(b in pathsDistance)) {
        pathsDistance[b] = this.levenshtein(b, typoPath);
      }

      return pathsDistance[a] - pathsDistance[b];
    });
  }

  levenshtein(a: string, b: string): number {
    if (a.length == 0) {
      return b.length;
    }
    if (b.length == 0) {
      return a.length;
    }

    const matrix = [];

    // increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1, // deletion
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }
}

const routes: Routes = [

  {
    path: '',
    component: FrontLayoutComponent,
    children: [
      {path: '', redirectTo: '', pathMatch: 'full', component: FrontComponent},
      {
        path: 'home',
        component: FrontComponent,
        // resolve:{data:NavbarResolver},
        data: {title: 'home', titleI18n: 'home'},
      },
      {
        path: 'page/:menu',
        component: FrontComponent,
        data: {title: 'retail', titleI18n: 'retail'},
      },
      {
        path: 'page/:menu/:id',
        component: FrontComponent,
        data: {title: 'retail', titleI18n: 'retail'},
      },
      {
        path: 'pricing',
        component: FrmPricingComponent,
        data: {title: 'Pricing', titleI18n: 'Pricing'},
      },
      {
        path: 'pricing/buy-now/:packageid',
        component: FrmBuyNowComponent,
        data: {title: 'Buy now', titleI18n: 'Buy now'},
      },
      {
        path: 'pricing/request/:packageid',
        component: CtlRequestQuoteComponent,
        data: {title: 'Buy now', titleI18n: 'Buy now'},
      },
      {
        path: 'pricing/product',
        component: ProductComponent,
        data: {title: 'freetrial', titleI18n: 'freetrial'},
      },
      {
        path: 'aboutus',
        component: CtlAboutUsComponent,
        data: {title: 'About Us', titleI18n: 'About Us'},
      },
      {
        path: 'sign-up',
        component: CtlFreeTrialComponent,
        data: {title: 'sign-up', titleI18n: 'sign-up'},
      },
      {
        path: 'internal-testing',
        component: CtlRegisterTesting,
        data: {title: 'internal-testing', titleI18n: 'internal-testing'},
      },

      {
        path: 'resendemail',
        component: CtlResendEmailComponent,
        data: {title: 'resendemail', titleI18n: 'resendemail'},
      },
      {
        path: 'verification',
        component: CtlVerificationComponent,
        resolve: {objInit: resolve},
        canActivate: [AccessingGuard],
        data: {title: 'verification-up', titleI18n: 'verification'},
      }
    ],
    // runGuardsAndResolvers: 'always'
  },
  {
    path: 'freetrial',
    component: CtlFreeTrialComponent,
    data: {title: 'freetrial', titleI18n: 'freetrial'},
  },



  {
    path: 'requestquote',
    component: CtlRequestQuoteComponent,
    data: {title: 'Request Quote', titleI18n: 'Request Quote'},
  },
  {
    path: 'license',
    component: LicenseLayoutComponent,
    canActivate: [LicenseGuard],
    resolve: {objInit: resolve,objMenu: SvrStartUpResolver},
    // canActivate: [AuthGuard],

    children: [
      {
        path: '',
        data: {title: 'license-app', titleI18n: 'license-app'},
        loadChildren: () => import('./projects/license-app/license.module').then(e => e.LicenseModule),
      },
      {
        path: 'OrderLicenseDashboard',
        data: {title: 'Order-License-Dashboard', titleI18n: 'Order-License-Dashboard'},
        loadChildren: () => import('./projects/license-app/Modules/order-license/order-license.module').then(e => e.OrderLicenseModule)
      },
      {
        path: 'Setting',
        data: {title: 'Setting-Dashboard', titleI18n: 'Setting-Dashboard'},
        loadChildren: () => import('./projects/license-app/Modules/setting-license/setting-license.module').then(e => e.SettingLicenseModule)
      }
    ]

  },

  {
    path: 'console',
    component: WebsiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        data: {title: 'Main', titleI18n: 'Main'},
        loadChildren: () => import('./projects/website-app/console/console.module').then(e => e.ConsoleModule),
      },
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    data: {title: 'login', titleI18n: 'login'},
    children: [
      {
        path: '',
        component: FrmLoginComponent,
        data: {title: 'Login', titleI18n: 'Login'},
      },
      {
        path: 'login',
        component: FrmLoginComponent,
        data: {title: 'Login', titleI18n: 'Login'},
      },
    ]
  },
  {
    path: 'admin',
    component: AuthLayoutComponent,
    data: {title: 'admin', titleI18n: 'admin'},
    children: [
      {
        path: '',
        component: LoginComponent,
        data: {title: 'Login', titleI18n: 'Login'},
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {title: 'Login', titleI18n: 'Login'},
      },
    ]
  },
  {
    path: '404',
    // resolve: {
    //   path: PathResolveService
    // },
    component: CtlNotFoundComponent,
    data: {title: 'Page Not Found', titleI18n: 'Page Not Found'},
  },
  // { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {
}
