import {NgModule} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {RoutesRoutingModule} from './routes-routing.module';
import {FrontComponent} from './projects/website-app/form/main-layout/main.component';
import {ImageCropperModule} from 'ngx-image-cropper';

import {AccordionModule} from 'primeng/accordion';
import {DecimalPipe, DatePipe, CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CtlHeaderComponent} from './theme/front-layout/header/header.component';
import {CtlCounterComponent} from './projects/website-app/component/home/counter/counter.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {QuillModule} from 'ngx-quill';
import {AnimateComponent} from './animation/animate.component';
import {IconPickerModule} from 'ngx-icon-picker';
import {CtlFreeTrialComponent} from './projects/register-app/component/free-trial/free-trial.component';
import {CtlAboutUsComponent} from './projects/website-app/component/about-us/about-us.component';
import {NgxImageGalleryModule} from 'ngx-image-gallery';

import {SafeurlPipe} from './pipes/safeurl.pipe';
import {LoginComponent} from './projects/website-app/form/login/login.component';
import {TagInputModule} from 'ngx-chips';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgImageSliderModule} from 'ng-image-slider';
import {FacebookModule} from 'ngx-facebook';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {SWIPER_CONFIG} from 'ngx-swiper-wrapper';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import {SafeResourcePipe} from './pipes/saferesource';
import {CtlYoutubeVdoComponent} from './projects/website-app/component/home/youtube-video/youtube-video.component';
import {CtlRequestQuoteComponent} from './projects/website-app/component/request-quote/request-quote.component';
import {FrmPricingComponent} from './projects/website-app/form/pricing-layout/pricing.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ThemeModule} from './theme/theme.module';
import {CarouselModule} from "ngx-owl-carousel-o";
import {FrmAboutUsComponent} from "./projects/website-app/form/about-us-layout/about-us.component";
import {CtlSliderComponent} from "./projects/website-app/component/slider/slider.component";
import {CtlVerificationComponent} from "./projects/register-app/component/verification/verification.component";
import { ToastrModule } from 'ngx-toastr';
import {FrmLoginComponent} from './projects/license-app/form/frm-login/frm-login.component';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTreeModule} from '@angular/material/tree';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSortModule} from '@angular/material/sort';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSliderModule} from '@angular/material/slider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {CtlResendEmailComponent} from "./projects/register-app/component/resend-email/resend-email.component";
import {CtlRegisterTesting} from "./projects/register-app/component/register-testing/register-testing.component";
import {FrmBuyNowComponent} from "./projects/website-app/form/frm-buy-now/frm-buy-now.component";
import {ProductComponent} from "./projects/website-app/component/request-quote/product/product.component";
import {TooltipModule} from "primeng";
import {PpDatetimeFormatPipe} from "./pipes/pp-datetime-format.pipe";



const provider = [DecimalPipe, DatePipe];
const COMPONENTS = [
  CtlHeaderComponent,
  FrmPricingComponent,
  CtlSliderComponent,
  // CtlMapComponent,
  CtlCounterComponent,
  FrontComponent,
  FrmAboutUsComponent,
  CtlFreeTrialComponent,
  CtlRegisterTesting,
  CtlResendEmailComponent,
  CtlAboutUsComponent,
  LoginComponent,
  FrmLoginComponent,
  AnimateComponent,
  CtlYoutubeVdoComponent,
  CtlRequestQuoteComponent,
  // CtlMapComponent
  FrmBuyNowComponent,
  ProductComponent


];
const COMPONENTS_DYNAMIC = [CtlYoutubeVdoComponent , CtlVerificationComponent];
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
    imports: [
        SharedModule,
        RoutesRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        FormsModule,
        ReactiveFormsModule,


        // ModalModule.forRoot(),
        // ButtonsModule.forRoot(),
        // MDBBootstrapModule.forRoot(),
        QuillModule.forRoot(),
        FacebookModule.forRoot(),
        AngularSvgIconModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
        }),
        NgxYoutubePlayerModule.forRoot(),
        GoogleMapsModule,
        FlexLayoutModule,
        IconPickerModule,
        ImageCropperModule,
        NgxDatatableModule,
        TagInputModule,
        CommonModule,
        NgImageSliderModule,
        NgxImageGalleryModule,
        BrowserModule,
        BrowserAnimationsModule,
        AccordionModule,
        SwiperModule,
        CarouselModule,
        ThemeModule,
        NgxSpinnerModule,
        TooltipModule,


        //  CtlMapComponent

    ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, SafeurlPipe, SafeResourcePipe, CtlVerificationComponent, ],
  entryComponents: [
    COMPONENTS_DYNAMIC,

  ],
  exports: [
     // CtlHeaderComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    PpDatetimeFormatPipe
  ]
})
export class RoutesModule {
}

// "postinstall": "ngcc --properties es2015browser module main --first-only --create-ivy-entrypoints",
