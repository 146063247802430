import { InitSate} from '../models/clsModels';
import {ActionsInit, Init_Action_Type} from '../action/index';

export interface Init extends InitSate {
}

const initialStates: Init = {
  menu: [],
  header: null,
};

export function initReducer(
  state = initialStates,
  action: ActionsInit): InitSate {
  switch (action.type) {
    case Init_Action_Type.LoadInitial:
      return {...state};
    case Init_Action_Type.LoadSuccess:
      const obj = action.payload;
      state = obj;
      return {...state};

    default:
      return state;
  }
}

export const getInitData = (state: InitSate) => state;
