import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'ExportButtonEdit',
  templateUrl: './ctl-export-button.component.html',
  styleUrls: ['./ctl-export-button.component.scss']
})

export class CtlExportButtonEditComponent {
  @Input() xs: boolean;
  @Input() icon: any;
  @Input() label: string;
  @Input() buttonLabel: string;
  @Input() hideLabel: boolean = false;
  @Input() class: any;
  @Input() model: any;
  @Input() style: any;
  @Output() public exportClick: EventEmitter<any> = new EventEmitter();
  @Output() public exportChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('ExportXSPopup', { static: false }) ExportXSPopup: any;
  public dialogRef: MatDialogRef<unknown, any>;
  public togglePopup: boolean = false
  constructor(public dialog: MatDialog,) {

  }
  onExportClick($e) {
    this.exportClick.emit($e);


  }
  onExportXSClick($e) {
    this.dialogRef.close();
    this.exportClick.emit($e);

  }
  onExportChange(event) {
    this.exportChange.emit(event);
  }
  openDialog() {


    this.dialogRef = this.dialog.open(this.ExportXSPopup, { width: '100vh', panelClass: 'export-dialog' });

    this.dialogRef.afterOpened().subscribe(result => {
      this.togglePopup = true;
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.togglePopup = true;
    });
  }
}
