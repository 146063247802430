import { ClsChildrenItem } from "../models/clsModels";
import { ActionsMenuEditor, MenuEditor_Action_Type } from "../action/index";
export interface MenuEditor {
  payload: ClsChildrenItem;
  items: ClsChildrenItem[];
  menuId: number;
  loading: boolean;
}

export const initialState: MenuEditor = {
  payload: undefined,
  items: [],
  menuId: 0,
  loading: false
};

export function menuEditorReducer(
  state = initialState,
  action: ActionsMenuEditor
): MenuEditor {
  switch (action.type) {
    case MenuEditor_Action_Type.MenuEditorInitial: {
      return { ...state, loading: true, menuId: action.menuId };
    }
    case MenuEditor_Action_Type.MenuEditorAdd: {
      // const objState = Object.values(state);
      return { ...state, loading: false, menuId: action.menuId, payload: action.payload };

    }
    case MenuEditor_Action_Type.MenuEditorRemove: {
      // const objRemove = action.payload;
      // const objState = Object.values(state);
      // state = objState.filter(n => n.id !== objRemove.id);
      // return Object.assign({}, objState, {
      //   menuId: action.menuId,
      //   payload: action.payload
      // });
      return { ...state, loading: false, menuId: action.menuId, payload: action.payload };
    }
    case MenuEditor_Action_Type.MenuEditorUpdate: {
      const objState = Object.values(state);
      const objUpdate = objState.filter(n => n.id !== action.payload.id)[0];
      if (objUpdate !== undefined && objUpdate !== null) {
        const index = objState.indexOf(objUpdate);
        objState[index] = action.payload;
      }
      return { ...state, loading: false, menuId: action.menuId, payload: action.payload };
      // return Object.assign({}, objState, {
      //   menuId: action.menuId,
      //   payload: action.payload
      // });
    }
    case MenuEditor_Action_Type.MenuEditorInitSuccess:

      return { ...state, loading: true, items: action.items };
  }
}

export const getMenuEditorData = (state: MenuEditor) => state.items;
