import { Component, OnInit } from '@angular/core';
import { clsAboutUs } from '../../../../Store/models/clsModels';



@Component({
    selector: 'app-ctl-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
  })
  export class CtlAboutUsComponent implements OnInit {
    showShortDesciption = true;
    _ReadMore = 'Read More';
    _ReadLess = 'Read Less';
    _aboutUs: clsAboutUs[] = [
      {
        title: 'T.O Software',
        src: '../../../assets/President/Worker.jpg',
        content:'Established in 2004, T.O Computer Co., Ltd. is a major Total IT Solution and Computer Retail Company, offering a wide range of key vertical and horizontal customized solutions.',
        description: [
          'T.O Group has been established in 2005.',
          'T.O Group is one of the leading IT Solution Provider in Cambodia.',
          'Founded by Mr. Heng Chamroeun, who earned two master degrees from Hawaii Pacific University: MBA concentrating in International Business and Master of Science in Information System.',
          'Employees around 100 pax',
          'Address: No 70-72, St. Rainbow Bridge, Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia '
        ],
        buttonCaption: 'Read More',
        isLeft : true,
        readmore : true,
      },






    ];

    constructor() {

    }

    ngOnInit() {  }
    alterDescriptionText(id: number) {
      this._aboutUs[id].readmore =  !this._aboutUs[id].readmore;
    }


  }

