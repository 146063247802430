import { Component, Input, Output, EventEmitter } from '@angular/core';




@Component({
  selector: 'CreateNewXSButton',
  templateUrl: './ctl-create-new-xs-button.component.html',
  styleUrls: ['./ctl-create-new-xs-button.component.scss']
})

export class CtlCreateNewXSButtonComponent {

  @Input() label: string;
  @Input() class: any;
  @Input() style: any;
  @Input() disabled: boolean = false;
  @Input() icon: string;
  @Output() public xsClick: EventEmitter<any> = new EventEmitter();

  onAddClick($e) {
    if (this.disabled) {
      return;
    }
    this.xsClick.emit($e);
  }

}
