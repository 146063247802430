import {Injectable} from '@angular/core';
import {publish} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import { LCUser} from "../Model/clsSMModel";
import {Observable} from "rxjs";

import {OperationService} from "@core";
import {clsLogin, LCRecord, LCTotalField} from "../Model/clsGlobalData";


@Injectable({
  providedIn: 'root',
})
export class svrSMUser {
  constructor(
    public http: HttpClient,
    public operation: OperationService,
  ) {

  }




  public routeprefix = "license/sm_user";

  public async AddUpdateUser(_info: LCUser): Promise<any> {
    let isuser = await
      this.http
        .post(this.operation.accessPointUrl + this.routeprefix + "/AddUpdate",
          _info, {headers: this.operation.headers}).toPromise();
    return isuser;
  }

  public async onGetEditUser(userid:number): Promise<any> {
    let res = await
      this.http
        .get(this.operation.accessPointUrl + this.routeprefix + "/editUser", {
          params:{
            "_userid":userid.toString()
            }
        }).toPromise();
    return res;
  }

  // from, to, this.objFormData.searchText, this.objFormData.sortField, this.objFormData.sortBy,
  // this.objFormData.searchField, this.objFormData.statusValue.id,this.svrData.objInit.objUser.userid
  // public async onGetListUser(_record:LCRecord): Promise<any> {debugger
  //   const isuser = await
  //     this.http
  //       .post(this.operation.accessPointUrl + this.routeprefix + "/ListUser",_record, {headers: this.operation.headers}).toPromise();
  //   return isuser;
  // }

  public async onGetRecordUser(_record:LCRecord):Promise<any>{
    const isuser = await
      this.http
        .post(this.operation.accessPointUrl + this.routeprefix + "/GetRecordUser",_record, {headers: this.operation.headers}).toPromise();
    return isuser;
  }
  public async onGetTotalUser(_total:LCRecord): Promise<number> {
    const res = await this.http.post(this.operation.accessPointUrl + this.routeprefix + "/GetTotalUser",_total, {headers:this.operation.headers}).toPromise() as number;
    return res ?? 0;
  }
  public async onDeleteUser(userid:number,isCheck:boolean):Promise<number>{
    const res = await this.http.delete<number>(this.operation.accessPointUrl +this.routeprefix +'/deleteUser' ,{
      params:{
      'userid' : userid.toString(),
        'isCheck': String(isCheck)
      }
    }).toPromise();
    return res ?? 0;
  }
  public async Login(objLogin:clsLogin):Promise<LCUser>{
    const res = await this.http.post<LCUser>(this.operation.accessPointUrl +this.routeprefix +'/Login' ,
     objLogin).toPromise();
    return res;
  }
}


